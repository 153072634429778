import { formTagsNone, notifyError, notifySuccess } from "../../handlers/extensions";
import { QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { Button, Card, Form, Popover, Spin, Switch } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { ComponentFormInput } from "../components/forms/input";
import { SafetyOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { GetData, MutateData } from "../../handlers/axios";
import { useAtom } from "jotai";
import { BusinessId, IsBusinessCompleted, UserId } from "../../handlers/globals";

export function SectionBusinessTeam(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [isBusinessCompleted,] = useAtom(IsBusinessCompleted);

    const { t } = useTranslation();

    const response = GetData(QueryId.SelectBusinessUsers, { BusinessId: businessId, UserId: userId });

    const [freezeActions, setFreezeActions] = useState(false);

    const [form] = Form.useForm();

    const formActionInvite = MutateData(QueryId.InsertBusinessUser,
        () => { setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { form.resetFields(); response.refetch(); notifySuccess("toast_mutate_success_user_invite"); } },
        { case: QueryCase.Invalid, onCase: () => { notifyError("toast_mutate_warning_user_duplicate") } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinishInvite = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;

        setFreezeActions(true);
        formActionInvite.mutate(properties);
    };

    return (response.isLoading ? <Spin /> : response.isFalse || response.isEmpty ? <ComponentState error /> : <>
        <p className="font-semibold text-lg mb-2">{t(props.section)}</p>
        {!isBusinessCompleted ? <></> : <>
            <Form
                form={form}
                requiredMark={formTagsNone}
                colon={false}
                onFinish={onFinishInvite}
                className="mb-2"
            >
                <div className="flex">
                    <ComponentFormInput field={"form_field_email_teammember"} placeholder={"placeholder_email"} required email lower clear feedback />
                    <Form.Item>
                        <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full ml-2">{t("form_confirm_invite")}</Button>
                    </Form.Item>
                </div>
            </Form>
            <hr />
        </>
        }
        {response.data?.team?.map((user) => {
            return <User setFreezeActions={setFreezeActions} freezeActions={freezeActions} refetch={response.refetch} key={user.id} user={user} events={response.data?.events} />
        })}
    </>);
}

function User(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);

    const { t } = useTranslation();

    const formActionUpdatePassword = MutateData(QueryId.UpdateBusinessUserPassword,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { notifySuccess("toast_mutate_success_password_force"); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const formActionDelete = MutateData(QueryId.DeleteBusinessUser,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); notifySuccess(ToastId.ActionSuccessDelete); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinishUpdatePassword = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;

        props.setFreezeActions(true);
        formActionUpdatePassword.mutate(properties);
    };

    const onFinishDelete = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;

        props.setFreezeActions(true);
        formActionDelete.mutate(properties);
    };

    const [open, setOpen] = useState(false);

    const handleOpenChange = (state) => {
        setOpen(state);
    };

    const [openDelete, setOpenDelete] = useState(false);

    const handleOpenDeleteChange = (state) => {
        setOpenDelete(state);
    };

    return (<Card className="bg-sonar-white w-min mt-2">
        <div className="flex">
            <p className="mr-4 my-auto">{props.user.id === userId ? <b>({t("you")})</b> : props.user.isOwner ? <b>({t("admin")})</b> : <></>}&nbsp;{props.user.login}</p>
            {props.user.id === userId ? <></> :
                <div className="flex">
                    {props.user.id === userId ? <></> : <>
                        <Popover
                            content={<>
                                <p className="mb-4">{t("popover_force_password")}</p>
                                <div>
                                    <Button className="bg-sonar-green mr-2" onClick={(() => { onFinishUpdatePassword({ form_field_teamuserid: props.user.id }); handleOpenChange(false); })}>{t("popover_confirm")}</Button>
                                    <Button className="bg-red-400" onClick={(() => { handleOpenChange(false); })}>{t("popover_cancel")}</Button>
                                </div>
                            </>}
                            title={t("popover_title_delete")}
                            trigger="click"
                            open={open}
                            onOpenChange={handleOpenChange}
                        >
                            <Button disabled={props.freezeActions} loading={props.freezeActions} onClick={() => { handleOpenChange(true) }} className="bg-sonar-yellow ml-2" shape="circle" icon={<SafetyOutlined />} />
                        </Popover>
                        <Popover
                            content={<>
                                <p className="mb-4">{t("popover_user_delete")}</p>
                                <div>
                                    <Button className="bg-sonar-green mr-2" onClick={(() => { onFinishDelete({ form_field_teamuserid: props.user.id }); handleOpenDeleteChange(false); })}>{t("popover_confirm")}</Button>
                                    <Button className="bg-red-400" onClick={(() => { handleOpenDeleteChange(false); })}>{t("popover_cancel")}</Button>
                                </div>
                            </>}
                            title={t("popover_title_delete")}
                            trigger="click"
                            open={openDelete}
                            onOpenChange={handleOpenDeleteChange}
                        >
                            <Button disabled={props.freezeActions} loading={props.freezeActions} onClick={() => { handleOpenDeleteChange(true); }} className="bg-sonar-red ml-2" shape="circle" icon={<DeleteOutlined />} />
                        </Popover>
                    </>
                    }
                </div>
            }
        </div>
        {!props.events ? <></> :
            <div className="flex mt-2">
                <div className="mr-2 mt-2 whitespace-nowrap">{t("tooltip_team_access")}</div>
                <div className="border-l-2">
                    {props.events.map((event) => {
                        return <EventAccess key={event.id} user={props.user} event={event} freezeActions={props.freezeActions} setFreezeActions={props.setFreezeActions} />
                    })}
                </div>
            </div>
        }
    </Card>);
}

function EventAccess(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);

    const formActionUpdateAccess = MutateData(QueryId.UpdateBusinessUserAccess,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { notifySuccess("toast_mutate_success_user_access"); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinishUpdateAccess = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;

        props.setFreezeActions(true);
        formActionUpdateAccess.mutate(properties);
    };

    return (<div className="flex mt-2 ml-2">
        <p className="whitespace-nowrap mr-2">{props.event.name}</p>
        <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            className="ant-switch-override"
            defaultValue={props.user.events.find((element) => element.id === props.event.id)}
            onChange={(checked) => { onFinishUpdateAccess({ form_field_eventid: props.event.id, form_field_teamuserid: props.user.id, form_field_insert: checked }) }}
            disabled={props.freezeActions || (props.user.id === userId && props.user.events.find((element) => element.id === props.event.id))}
            loading={props.freezeActions}
        />
    </div>);
}