import { formTagsOptionals, notifyError, notifySuccess } from "../../handlers/extensions";
import { Color, QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { Button, Form, Spin } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { ComponentFormInput } from "../components/forms/input";
import { WarningTwoTone } from "@ant-design/icons";
import { useState } from "react";
import { GetData, MutateData } from "../../handlers/axios";
import { useAtom } from "jotai";
import { BusinessId, IsBusinessCompleted, UserId } from "../../handlers/globals";
import { GooglePlayButton } from "react-mobile-app-button";

export function SectionBusinessGeneral(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [, setIsBusinessCompleted] = useAtom(IsBusinessCompleted);

    const { t } = useTranslation();

    const response = GetData(QueryId.SelectBusinessGeneral, { BusinessId: businessId, UserId: userId });

    const formAction = MutateData(QueryId.UpdateBusinessGeneral,
        () => { setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { response.refetch(); setIsBusinessCompleted(true); notifySuccess(ToastId.ActionSuccessUpdate); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const [freezeActions, setFreezeActions] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;

        setFreezeActions(true);
        formAction.mutate(properties);
    };

    return (response.isLoading ? <Spin /> : response.isFalse || response.isEmpty ? <ComponentState error /> : <>
        <p className="font-semibold text-lg mb-2">{t(props.section)}</p>
        <p className={"text-sonar-yellow text-md " + (response.data.name || response.data.billingNumber || response.data.bic || response.data.cardHolder || response.data.email || response.data.phone ? "mb-5" : "mb-2")}><WarningTwoTone twoToneColor={Color.Yellow} className="mr-1" />{t("tooltip_business_data_update")}</p>
        {response.data.name || response.data.billingNumber || response.data.bic || response.data.cardHolder || response.data.email || response.data.phone ? <></> :
            <p className="text-sonar-yellow text-md mb-5"><WarningTwoTone twoToneColor={Color.Yellow} className="mr-1" />{t("tooltip_business_data_missing")}</p>
        }
        <Form
            form={form}
            requiredMark={formTagsOptionals}
            colon={false}
            onFinish={onFinish}
        >
            <ComponentFormInput field={"form_field_businessname"} placeholder={"placeholder_businessname"} value={response.data.name} disabled={response.data.name} feedback clear required />
            <ComponentFormInput field={"form_field_vat"} placeholder={"placeholder_vat"} value={response.data.vatNumber} disabled={response.data.vatNumber} feedback clear upper unspaced />
            <ComponentFormInput field={"form_field_iban"} placeholder={"placeholder_iban"} value={response.data.billingNumber} disabled={response.data.billingNumber} feedback clear upper required />
            <ComponentFormInput field={"form_field_swiftcode"} placeholder={"placeholder_swiftcode"} value={response.data.bic} disabled={response.data.bic} feedback clear upper required />
            <ComponentFormInput field={"form_field_cardholder"} placeholder={"placeholder_cardholder"} value={response.data.cardHolder} disabled={response.data.cardHolder} feedback clear upper required />
            <ComponentFormInput field={"form_field_email"} placeholder={"placeholder_email"} value={response.data.email} disabled={response.data.email} feedback clear required email lower />
            <ComponentFormInput field={"form_field_mobile"} placeholder={"placeholder_mobile"} value={response.data.phone} disabled={response.data.phone} feedback clear required phone />
            {response.data.name && response.data.billingNumber && response.data.bic && response.data.cardHolder && response.data.email && response.data.phone ? <></> :
                <Form.Item>
                    <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_confirm_update_business")}</Button>
                </Form.Item>
            }
        </Form>
        <hr className="mt-4" />
        <p className="font-bold my-3">{t("title_scanapp")}</p>
        <p className="mb-4">{t("description_scanapp")}</p>
        <GooglePlayButton height={60} width={200} url={"https://play.google.com/store/apps/details?id=com.SonarEvents.SonarEventsScanner"} theme={"dark"} />
    </>);
}