import { handleUploadPicture, notifyError, notifySuccess } from "../../handlers/extensions";
import { Color, Defaults, QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { Button, Image, Popover, Space, Spin, Tag, Tooltip, Upload } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { PlusOutlined, DeleteOutlined, QuestionCircleOutlined, WarningTwoTone } from "@ant-design/icons";
import { GetData, MutateData } from "../../handlers/axios";
import { useAtom } from "jotai";
import { BusinessEventProgress, BusinessId, EventId, UserId } from "../../handlers/globals";
import ImgCrop from "antd-img-crop";

export function SectionBusinessEventPictures(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);

    const { t } = useTranslation();

    const [freezeActions, setFreezeActions] = useState(false);

    const response = GetData(QueryId.SelectBusinessEventGeneral, { BusinessId: businessId, UserId: userId, EventId: eventId });

    const responseSponsers = GetData(QueryId.SelectBusinessEventSponsers, { BusinessId: businessId, UserId: userId, EventId: eventId });

    const formActionInsert = MutateData(QueryId.InsertBusinessEventSponser,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { responseSponsers.refetch(); notifySuccess(ToastId.ActionSuccessCreate); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinish = (file) => {
        let properties = {};

        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;
        properties.form_field_url = Defaults.StringEmpty;

        if (file) {
            handleUploadPicture(file, 3, 300, 300, "sonar/sponsers").then((result) => {
                if (!result) { return; }

                properties.form_field_url = result;

                setFreezeActions(true);
                formActionInsert.mutate(properties);
            }).catch(() => {
                notifyError(ToastId.ActionError);
            });
        }
        else {
            notifyError(ToastId.ActionError);
        }
    };

    return (response.isLoading || responseSponsers.isLoading || freezeActions ? <Spin /> : response.isFalse || responseSponsers.isFalse ? <ComponentState error /> : <>
        <p className="font-semibold mb-3 text-base">{t("title_pictures")}</p>
        <p className={"text-sonar-yellow text-md mb-3"}><WarningTwoTone twoToneColor={Color.Yellow} className="mr-1" />{t("tooltip_png_blacked")}</p>
        <div className="mt-6">
            <Tooltip className="my-auto" title={t("tooltip_picture_tile")}><div className="flex flex-nowrap mb-3 w-min"><span className="font-semibold text-nowrap">{t("picture_tile")}</span><QuestionCircleOutlined className="ml-1 mr-2" /></div></Tooltip>
            <Picture maxSize={4} maxWidth={600} maxHeight={250} aspect={2.4} classNameOverride={"ant-upload-size-override-tilepicture"} refetch={response.refetch} picture={response.data.tilePicture} setFreezeActions={setFreezeActions} queryUpdate={QueryId.UpdateBusinessEventPictureTile} queryDelete={QueryId.DeleteBusinessEventPictureTile} />
        </div>
        <div className="mt-6">
            <Tooltip className="my-auto" title={t("tooltip_picture_banner")}><div className="flex flex-nowrap mb-3 w-min"><span className="font-semibold text-nowrap">{t("picture_banner")}</span><QuestionCircleOutlined className="ml-1 mr-2" /></div></Tooltip>
            <Picture maxSize={3} maxWidth={750} maxHeight={75} aspect={10} classNameOverride={"ant-upload-size-override-bannerpicture"} refetch={response.refetch} picture={response.data.bannerPicture} setFreezeActions={setFreezeActions} queryUpdate={QueryId.UpdateBusinessEventPictureBanner} queryDelete={QueryId.DeleteBusinessEventPictureBanner} />
        </div>
        <p className="font-semibold mb-3 mt-4 text-base">{t("title_sponsers")}<Tag className="ml-2" color="warning">{t("tag_optional")}</Tag></p>
        <div className="flex">
            <Space>
                <ImgCrop quality={1} fillColor="transparant" modalTitle={<><p className="mb-2">{t("edit_picture")}</p><div className="flex"><WarningTwoTone className="text-lg mb-auto mr-2" twoToneColor={Color.Yellow} /><p className="font-normal text-sm text-sonar-yellow">{t("tooltip_edit_picture")}</p></div></>} resetText={t("reset")} modalOk={t("confirm")} modalCancel={t("cancel")} zoomSlider aspect={1} showReset showGrid>
                    <Upload className="ant-upload-size-override-sponser" fileList={[]} onPreview={() => { }} maxCount={1} accept="image/jpeg,image/png" beforeUpload={(file) => { onFinish(file); return false; }} listType="picture-card" showUploadList={false}>
                        <PlusOutlined />
                    </Upload>
                </ImgCrop>
                {responseSponsers.data?.map((sponser) => {
                    return <Sponser key={sponser.id} sponser={sponser} refetch={responseSponsers.refetch} setFreezeActions={setFreezeActions} freezeActions={freezeActions} />
                })}
            </Space>
        </div>
    </>);
}

function Sponser(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const handleOpenChange = (state) => {
        setOpen(state);
    };

    const formActionDelete = MutateData(QueryId.DeleteBusinessEventSponser,
        () => { props.setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); notifySuccess(ToastId.ActionSuccessDelete); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinishState = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;

        props.setFreezeActions(true);
        formActionDelete.mutate(properties);
    };

    return <div className="relative" key={props.sponser.id}>
        <Popover
            content={<>
                <p className="mb-4">{t("popover_sponser_delete")}</p>
                <div>
                    <Button className="bg-sonar-green mr-2" onClick={(() => { onFinishState({ form_field_sponserid: props.sponser.id }); handleOpenChange(false); })}>{t("popover_confirm")}</Button>
                    <Button className="bg-red-400" onClick={(() => { handleOpenChange(false); })}>{t("popover_cancel")}</Button>
                </div>
            </>}
            title={t("popover_title_delete")}
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
        >
            <Button disabled={props.freezeActions} loading={props.freezeActions} className="absolute z-50 top-1 right-1 bg-red-400" shape="circle" onClick={() => { handleOpenChange(true); }} icon={<DeleteOutlined className="text-2xl" />} />
        </Popover>
        <Image className="!w-[100px] !h-[100px] rounded-md" preview={false} src={props.sponser.url} />
    </div>
}

function Picture(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);

    const { t } = useTranslation();

    const [picture, setPicture] = useState(props.picture);

    const [businessEventProgress, setBusinessEventProgress] = useAtom(BusinessEventProgress);

    const formActionProgress = MutateData(QueryId.SelectBusinessEventProgress,
        (data) => { setBusinessEventProgress(data?.data ? data.data : businessEventProgress); });

    const onFinishProgress = (properties) => {
        formActionProgress.mutate(properties);
    };

    const formActionTile = MutateData(props.queryUpdate,
        () => { props.setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); onFinishProgress({ BusinessId: businessId, UserId: userId, EventId: eventId }); notifySuccess(ToastId.ActionSuccess); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinish = (file) => {
        let properties = {};

        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;
        properties.form_field_url = Defaults.StringEmpty;

        if (file) {
            handleUploadPicture(file, props.maxSize, props.maxWidth, props.maxHeight, "sonar/event/pictures").then((result) => {
                properties.form_field_url = result;
                setPicture(result);

                props.setFreezeActions(true);
                formActionTile.mutate(properties);
            }).catch(() => {
                notifyError(ToastId.ActionError);
                return;
            });
        }
        else {
            notifyError(ToastId.ActionError);
            return;
        }
    };

    return (<div className="flex">
        <ImgCrop quality={1} fillColor="transparant" modalTitle={<><p className="mb-2">{t("edit_picture")}</p><div className="flex"><WarningTwoTone className="text-lg mb-auto mr-2" twoToneColor={Color.Yellow} /><p className="font-normal text-sm text-sonar-yellow">{t("tooltip_edit_picture")}</p></div></>} resetText={t("reset")} modalOk={t("confirm")} modalCancel={t("cancel")} zoomSlider aspect={props.aspect} showReset showGrid>
            <Upload className={props.classNameOverride} fileList={[]} onPreview={() => { }} maxCount={1} accept="image/jpeg,image/png" beforeUpload={(file) => { onFinish(file); return false; }} listType="picture-card" showUploadList={false}>
                {picture ? <Image src={picture} /> : <PlusOutlined />}
            </Upload>
        </ImgCrop>
    </div>);
}