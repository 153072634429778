import { JsonToTableBody, formTagsNone, notifyError, notifySuccess } from "../../handlers/extensions";
import { QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { Button, Form, Popover, Spin } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { ComponentFormTextarea } from "../components/forms/input";
import { useState } from "react";
import { EditOutlined, CheckOutlined } from "@ant-design/icons";
import { GetData, MutateData } from "../../handlers/axios";
import moment from "moment";

export function SectionSupportUsers(props) {
    const response = GetData(QueryId.SelectSupportsUsers);
    const { t } = useTranslation();

    return (<>
        <p className="font-semibold text-lg mb-5">{t(props.section)}</p>
        <div className="mb-8 flex flex-col gap-5">
            {response.isLoading ? <Spin /> : response.isFalse ? <ComponentState error /> : response.isEmpty ? <ComponentState empty /> : response.data.map((item) => {
                return <SupportUserItem key={item.id} data={item} refetch={response.refetch} setFreezeActions={props.setFreezeActions} />
            })}
        </div>
    </>)
}
export function SupportUserItem(props) {
    const { i18n, t } = useTranslation();

    const formAction = MutateData(QueryId.ResolveSupport,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); notifySuccess(ToastId.ActionSuccess); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const [isAnswering, setIsAnswering] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (properties) => {
        properties.form_field_supportid = props.data.id;

        props.setFreezeActions(true);
        formAction.mutate(properties);
    }

    const [open, setOpen] = useState(false);

    const handleOpenChange = (state) => {
        setOpen(state);
    };

    return (<div className="relative">
        <table className="mr-20">
            {props.data ? JsonToTableBody({ date: moment(props.data.createdOn).locale(i18n.language).format("DD MMMM"), email: props.data.email, body: props.data.body }) : null}
        </table>
        <Form
            form={form}
            requiredMark={formTagsNone}
            colon={false}
            onFinish={onFinish}
            className="mb-6 mt-3"
        >
            {!isAnswering ? null : <ComponentFormTextarea field={"form_field_reply"} value={props.data.reply} required />}
            {!isAnswering ? null :
                <Form.Item>
                    <Button onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{props.data.reply ? t("form_support_reply_update") : t("form_support_reply")}</Button>
                </Form.Item>
            }
        </Form>
        <Popover
            content={<>
                <p className="mb-4">{t("popover_description_resolve")}</p>
                <div>
                    <Button className="bg-sonar-green mr-2" onClick={(() => { formAction.mutate({ form_field_supportid: props.data.id }); handleOpenChange(false); })}>{t("popover_confirm")}</Button>
                    <Button className="bg-red-400" onClick={(() => { handleOpenChange(false); })}>{t("popover_cancel")}</Button>
                </div>
            </>}
            title={t("popover_title_resolve")}
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
            placement="left"
        >
            <Button className="absolute right-10 top-0 bg-sonar-green" shape="circle" onClick={() => { handleOpenChange(true); }} icon={<CheckOutlined className="text-2xl" />} />
        </Popover>
        <Button className={"absolute right-0 top-0 bg-orange-400"} shape="circle" onClick={() => { setIsAnswering(!isAnswering); }} icon={<EditOutlined className="text-2xl" />} />
        <hr />
    </div>)
}