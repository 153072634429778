import { Button, Card, Image, Layout, Select, Spin, Steps, Input } from "antd";
import ComponentState from "./components/states";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { Defaults, EventCategoryOption, QueryId, StorageId, WebRoute } from "../handlers/enums";
import ComponentHeader from "./components/header";
import ComponentFooter from "./components/footer";
import { XFilled, ExportOutlined, ExpandOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import { GetData } from "../handlers/axios";
import BackupTilePicture from "../assets/sprites/BackupTilePicture.jpg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { SortOnText, ToLowerAlphanumeric } from "../handlers/extensions";
import { useAtom } from "jotai";
import { UserId } from "../handlers/globals";

export default function Home() {
  const [, setUserId] = useAtom(UserId);

  const { t } = useTranslation();

  useEffect(() => { setUserId(); window.scrollTo(0, 0); }, [setUserId]);

  const selectEvents = GetData(QueryId.SelectEvents);

  const [searchValue, setSearchValue] = useState(sessionStorage.getItem(StorageId.SearchValue) ?? Defaults.StringEmpty);
  const [eventCategory, setEventCategory] = useState(sessionStorage.getItem(StorageId.SearchCategory) ?? EventCategoryOption.All);
  const categoryOptions = [
    {
      value: EventCategoryOption.All,
      label: t(EventCategoryOption.All)
    },
    {
      value: EventCategoryOption.Available,
      label: t(EventCategoryOption.Available)
    },
    {
      value: EventCategoryOption.Demo,
      label: t(EventCategoryOption.Demo)
    },
  ]

  return (
    <>
      <Layout className="!bg-transparent">
        <ComponentHeader />
        <Layout hasSider={true} className="hidden md:flex !bg-transparent px-8 justify-between lg:justify-center">
          <Sider className="!bg-transparent !min-w-72 border-r pt-8 pr-8">
            <Input value={searchValue} className="block mb-4" onChange={(event) => { setSearchValue(event.target.value); sessionStorage.setItem(StorageId.SearchValue, event.target.value); }} type="text" allowClear={true} addonBefore={<SearchOutlined />} placeholder={t("placeholder_search_event")} />
            <div className="mb-3">
              <p className="mb-2 text-sm font-semibold">{t("subtitle_event_categories")}</p>
              <Button disabled={eventCategory === EventCategoryOption.All} onClick={() => { setEventCategory(EventCategoryOption.All); sessionStorage.setItem(StorageId.SearchCategory, EventCategoryOption.All); }} type="text">{t(EventCategoryOption.All)}</Button>
              <Button disabled={eventCategory === EventCategoryOption.Available} onClick={() => { setEventCategory(EventCategoryOption.Available); sessionStorage.setItem(StorageId.SearchCategory, EventCategoryOption.Available); }} type="text">{t(EventCategoryOption.Available)}</Button>
              <Button disabled={eventCategory === EventCategoryOption.Demo} onClick={() => { setEventCategory(EventCategoryOption.Demo); sessionStorage.setItem(StorageId.SearchCategory, EventCategoryOption.Demo); }} type="text">{t(EventCategoryOption.Demo)}</Button>
            </div>
            <GetBlogCards sider />
          </Sider>
          <Content className="min-h-[80vh] pl-8 py-8">
            <div>
              {selectEvents.isLoading
                ? <Spin />
                : selectEvents.isFalse
                  ? <ComponentState error />
                  : selectEvents.isEmpty
                    ? <ComponentState empty />
                    : <EventSteps events={selectEvents.data} searchValue={searchValue} eventCategory={eventCategory} />}
            </div>
          </Content>
        </Layout>
        <Layout className="md:hidden !bg-transparent px-8">
          <Content className="min-h-[80vh] pt-8">
            <div className="flex mb-4 justify-between gap-3 flex-wrap">
              <Input value={searchValue} className="min-w-80 w-80" onChange={(event) => { setSearchValue(event.target.value); sessionStorage.setItem(StorageId.SearchValue, event.target.value); }} type="text" allowClear={true} addonBefore={<SearchOutlined />} placeholder={t("placeholder_search_event")} />
              <Select
                defaultValue={t(eventCategory)}
                onChange={(option) => { setEventCategory(option); sessionStorage.setItem(StorageId.SearchCategory, option); }}
                options={categoryOptions}
                className="min-w-80 w-80"
              />
            </div>
            <div>
              {selectEvents.isLoading
                ? <Spin />
                : selectEvents.isFalse
                  ? <ComponentState error />
                  : selectEvents.isEmpty
                    ? <ComponentState empty />
                    : <EventSteps events={selectEvents.data} searchValue={searchValue} eventCategory={eventCategory} />}
            </div>
            <GetBlogCards />
          </Content>
        </Layout>
        <ComponentFooter apex />
      </Layout>
    </>
  );
}

function EventSteps(props) {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  let events = props.events.filter((x) => {
    return moment(x.eventEndDate).add(1, "d") >= moment() && (!props.searchValue || ToLowerAlphanumeric(x.name).includes(ToLowerAlphanumeric(props.searchValue))) && ((props.eventCategory === EventCategoryOption.All) || (props.eventCategory === EventCategoryOption.Demo && x.isDemo) || (props.eventCategory === EventCategoryOption.Available && !x.isUnstocked))
  }).sort((a, b) => { return SortOnText(a.eventStartDate, b.eventStartDate); });
  let months = events.map(x => moment(x.eventStartDate).locale(i18n.language).format("MMMM")).filter((value, index, self) => self.indexOf(value) === index).map((month) => {
    return {
      status: "finish",
      title: <div className="font-semibold text-2xl mb-4">{month}</div>,
      description: <div className="flex flex-wrap gap-5">
        {events.filter((x) => { return month === moment(x.eventStartDate).locale(i18n.language).format("MMMM") }).map((event) => {
          return <Card key={event.id} onClick={() => { navigate(WebRoute.Event + `?id=${event.id}`); }} cover={<Image preview={false} height={100} width={240} src={event.tilePicture ? event.tilePicture : BackupTilePicture} />} className="event-tile w-[240px] text-sonar-white bg-slate-800 hover:bg-slate-700 depth-shadow cursor-pointer border-transparent border-[1px] border-solid">
            <p className="font-semibold text-lg">{event.name}</p>
            <p className="text-ant-blue">{moment(event.eventStartDate).locale(i18n.language).format("DD MMMM") === moment(event.eventEndDate).locale(i18n.language).format("DD MMMM") ? moment(event.eventStartDate).locale(i18n.language).format("DD MMMM") : moment(event.eventStartDate).locale(i18n.language).format("DD MMMM") + " - " + moment(event.eventEndDate).locale(i18n.language).format("DD MMMM")}</p>
            <p>{event.city} {event.postalCode},<br />{event.address}</p>
          </Card>
        })}
      </div>
    }
  });

  months.push({ status: "finish" });

  return (!events || events.length <= 0 ? <ComponentState empty description={t("none_upcoming")} /> : <Steps direction="vertical" progressDot items={months} />);
}

function GetBlogCards(props) {
  const response = GetData(QueryId.SelectBlogsCardsLatest);

  const { t } = useTranslation();

  return (
    response.isFalse || response.isEmpty ? <></> :
      <div className={props.sider ? "mb-6" : "mb-8"}>
        <p className="mb-3 mt-1 text-sm font-semibold">{t("subtitle_sonar_more")}</p>
        <div className="flex flex-col gap-5">
          {response.data.map((card) => {
            let icon = <></>;
            switch (card.categoryIndex) {
              case 9000:
                icon = <ExportOutlined className="text-lg" />
                break;
              case 9001:
                icon = <ExpandOutlined className="text-lg" />
                break;
              default:
                icon = <XFilled className="text-lg" />
                break;
            }

            return card.url && card.url.startsWith(Defaults.UrlPrefix) ?
              <a key={card.id} href={card.url} rel="noreferrer noopener" target="_blank">
                <Card className="border-gray-300" hoverable={true} title={icon} size="small">
                  <p className="mb-2 font-semibold">{card.title}</p>
                  <span className="text-sm">{parse(card.body)}</span>
                </Card>
              </a>
              :
              <a key={card.id} href={WebRoute.Blog}>
                <Card className="border-gray-300" hoverable={true} title={icon} size="small">
                  <p className="mb-2 font-semibold">{card.title}</p>
                  <span className="text-sm">{parse(card.body)}</span>
                </Card>
              </a>
          })}
        </div>
      </div>
  )
}