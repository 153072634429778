import { Button, Input, Spin, Table, Tag } from "antd";
import { BusinessEventProgress, BusinessId, EventId, UserId } from "../../handlers/globals";
import { Color, Defaults, QueryCase, QueryId, Sections, StorageId, ToastId } from "../../handlers/enums";
import { WarningTwoTone, SearchOutlined } from "@ant-design/icons";
import { GetData, MutateData } from "../../handlers/axios";
import { useAtom } from "jotai";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import { SortOnDigits, notifyError, notifySuccess } from "../../handlers/extensions";

export function SectionBusinessEventSales(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);

    const response = GetData(QueryId.SelectBusinessEventSales, { BusinessId: businessId, UserId: userId, EventId: eventId });

    return (response.isLoading
        ? <Spin />
        : response.isFalse
            ? <ComponentState error />
            : response.isEmpty
                ? <ComponentState empty />
                : <TableData refetch={response.refetch} data={response.data} />
    );
}

function TableData(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);
    const [businessEventProgress,] = useAtom(BusinessEventProgress);
    const { t } = useTranslation();

    const response = GetData(QueryId.SelectBusinessEventStatistics, { BusinessId: businessId, UserId: userId, EventId: eventId });

    const actionColumns = [{
        title: <p className="whitespace-nowrap select-none px-1">{t("table_header_actions")}</p>,
        dataIndex: "table_header_actions",
        key: "table_header_actions",
        fixed: "left",
        render: (_, record) => { return <Button disabled={businessEventProgress.isEventEnded || (record?.ticketStatus && record?.ticketStatus?.toString().toLowerCase() === "scanned")} type="link px-0 py-auto w-full" onClick={() => { onFinish({ form_field_shortindex: record?.manualCode }); }}>{t("force_scan_ticket")}</Button>; }
    }];
    const columns = Object.keys(props.data[0])?.map(key => ({
        title: <p className="select-none px-1">{key.replace(/([A-Z])/g, " $1").replace(/^./, (item) => item.toUpperCase())}</p>,
        dataIndex: key,
        key: () => { return uuid(); },
        fixed: key === "manualCode" ? "left" : key === "ticketStatus" ? "right" : undefined,
        showSorterTooltip: false,
        sorter: (a, b) => { return a - b; },
        sortDirections: ["descend", "ascend"],
        render: (value) => { return <p key={() => { return uuid(); }}>{value}</p>; }
    }));
    const allColumns = [...actionColumns, ...columns];

    const [searchCode, setSearchCode] = useState(sessionStorage.getItem(StorageId.SearchCode) ?? Defaults.StringEmpty);

    const formAction = MutateData(QueryId.UpdateBusinessEventTicketScanStatus,
        () => { }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); notifySuccess(ToastId.ActionSuccessUpdate); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinish = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;

        formAction.mutate(properties);
    };

    return (<>
        {response.isLoading || response.isFalse ? <></> : <>
            <p className="font-semibold text-lg mb-2">{t("statistics")}</p>
            <div className="flex gap-5 justify-between font-semibold mb-2">
                <p className="flex-1">{t("ticket_type")}</p>
                <p className="flex-1">{t("tickets_amount")}</p>
                <p className="flex-1">{t("tickets_scanned")}</p>
            </div>
            {response.data.sort((a, b) => { return SortOnDigits(a.sortIndex, b.sortIndex); }).map((item) => {
                const key = uuid();
                return <div key={key} className="flex gap-5 justify-between border-b-[1px] border-dashed border-gray-300">
                    <p className="flex-1 whitespace-nowrap my-1">{item.ticketType} {item.isDisabled ? item.ticketType === "GIVEAWAY" ? <Tag color="processing" >{t("hidden")}</Tag> : <Tag color="error" >{t("disabled")}</Tag> : <></>}</p>
                    <p className="flex-1 my-auto">{item.amount}</p>
                    <p className="flex-1 my-auto">{item.amountActivated}</p>
                </div>
            })}
        </>}
        <p className="font-semibold text-lg mb-2 mt-5">{t(Sections.BusinessEventTicketHolders)}</p>
        <p className={"text-sonar-yellow text-md mb-3"}><WarningTwoTone twoToneColor={Color.Yellow} className="mr-1" />{t("tooltip_table_width_large")}</p>
        <div className="flex mb-3">
            <p className="my-auto font-semibold mr-2 whitespace-nowrap">{t("search_orders")}</p>
            <Input onInput={(event) => { event.target.value = event.target.value.toUpperCase().replaceAll(Defaults.Space, Defaults.StringEmpty); }} allowClear className="my-auto" placeholder={t("search_order")} addonAfter={<SearchOutlined />} onChange={(event) => { setSearchCode(event.target.value); }} />
        </div>
        <Table key={() => { return uuid(); }} rowKey={() => { return uuid(); }} className="custom_table_sales" scroll={{ x: 1200 }} pagination={false} columns={allColumns} dataSource={props.data.filter((x) => x.manualCode.toLowerCase().includes(searchCode) || (x.customerFirstName + Defaults.Space + x.customerLastName).toLowerCase().includes(searchCode))} size="small" bordered />
    </>
    );
}