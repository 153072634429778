import { JsonToTableBody, SortOnDigits, formTagsNone, notifyError, notifySuccess } from "../../handlers/extensions";
import { Defaults, QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { Button, Form, Select, Spin, Tag } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { ComponentFormTextarea } from "../components/forms/input";
import { useState } from "react";
import moment from "moment";
import { GetData, MutateData } from "../../handlers/axios";
import { BusinessId, UserId } from "../../handlers/globals";
import { useAtom } from "jotai";

export function SectionBusinessSupport(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const { i18n, t } = useTranslation();

    const response = GetData(QueryId.SelectBusinessSupports, { BusinessId: businessId, UserId: userId });
    const responseAccess = GetData(QueryId.SelectBusinessUserAccess, { BusinessId: businessId, UserId: userId });

    const [freezeActions, setFreezeActions] = useState(false);

    const formAction = MutateData(QueryId.InsertBusinessSupport,
        () => { setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { form.resetFields(); response.refetch(); notifySuccess(ToastId.ActionSuccessSubmit); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const [form] = Form.useForm();

    const onFinish = (properties) => {
        properties.form_field_language = i18n.language;
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;

        setFreezeActions(true);
        formAction.mutate(properties);
    };

    response.data?.sort((a, b) => { return SortOnDigits(a.createdOn, b.createdOn); });

    return (response.isLoading || responseAccess.isLoading ? <Spin /> : response.isFalse || responseAccess.isFalse ? <ComponentState error /> : <>
        <p className="font-semibold text-lg mb-5">{t(props.section)}</p>
        <p className="mt-1 mb-5 font-semibold">{t("form_title_request_support")}</p>
        <Form
            form={form}
            requiredMark={formTagsNone}
            colon={false}
            onFinish={onFinish}
        >
            <Form.Item name={"form_field_eventid"} label={<><Tag color="warning">{t("tag_optional")}</Tag><span>{t("form_field_eventid")}</span></>} initialValue={Defaults.GuidEmpty} required>
                <Select
                    options={[...responseAccess.data.map(x => { return { value: x.eventId, label: x.eventName }; }),
                    { value: Defaults.GuidEmpty, label: "-" },
                    ]}
                />
            </Form.Item>
            <ComponentFormTextarea field={"form_field_support"} placeholder={t("placeholder_support_business")} counter clear max={999} required />
            <Form.Item className="mb-1 mt-7">
                <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_confirm_request_support")}</Button>
            </Form.Item>
        </Form>
        <hr />
        <p className="mt-6 mb-5 font-semibold">{t("form_title_history_support")}</p>
        <div className="mb-8 flex flex-col gap-5">
            {response.isLoading ? <Spin /> : response.isFalse ? <ComponentState error /> : response.isEmpty ? <ComponentState empty /> : response.data.map((item) => {
                return <SupportBusinessItem key={item.id} data={item} refetch={response.refetch} setFreezeActions={props.setFreezeActions} />;
            })}
        </div>
    </>);
}

export function SupportBusinessItem(props) {
    const { i18n, t } = useTranslation();

    return (<>
        <table className="mr-20">
            {props.data ? JsonToTableBody({ date: moment(props.data.createdOn).locale(i18n.language).format("DD MMMM"), email: props.data.email, body: props.data.body, reply: "bold_" + props.data.reply ?? t("awaiting_sonar_reply") }) : null}
        </table>
        <hr />
    </>);
}