import { Image } from "antd";
import { /*WhatsAppOutlined,*/ /*TwitterSquareFilled,*/ TikTokFilled, MailFilled, InstagramFilled, FacebookFilled, LinkedinFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SonarLogoIcon from "../../assets/sprites/SonarLogoIcon.png";
import { Footer } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";
import { Links, Sections, WebRoute } from "../../handlers/enums";
import parse from "html-react-parser";
import { UserId } from "../../handlers/globals";
import { useAtom } from "jotai";

export default function ComponentFooter(props) {
  const [userId,] = useAtom(UserId);

  const { t } = useTranslation();

  return (
    <>
      <Footer className="border-t bg-sonar-white">
        <div className="flex justify-center gap-6 flex-wrap my-auto">
          {props.apex ?
            <a className="select-none" href={Links.SonarApex} rel="noreferrer noopener" target="_self">
              <Image rootClassName="ant-image-img-hover-blue" height={30} width={30} className="min-w-8 max-h-8" src={SonarLogoIcon} preview={false} />
            </a> :
            <Link to={WebRoute.Home}>
              <Image rootClassName="ant-image-img-hover-blue" height={30} width={30} className=" min-w-8 max-h-8" src={SonarLogoIcon} preview={false} />
            </Link>
          }
          {/* <a href={Links.PhoneTo}>
            <WhatsAppOutlined className="text-3xl" />
          </a> */}
          <a href={Links.MailTo}>
            <MailFilled className="text-3xl" />
          </a>
          {/* <a href={Links.Twitter} rel="noreferrer noopener" target="_blank">
            <TwitterSquareFilled className="text-3xl" />
          </a> */}
          <a href={Links.Tiktok} rel="noreferrer noopener" target="_blank">
            <TikTokFilled className="text-3xl" />
          </a>
          <a href={Links.Instagram} rel="noreferrer noopener" target="_blank">
            <InstagramFilled className="text-3xl" />
          </a>
          <a href={Links.Facebook} rel="noreferrer noopener" target="_blank">
            <FacebookFilled className="text-3xl" />
          </a>
          <a href={Links.Linkedin} rel="noreferrer noopener" target="_blank">
            <LinkedinFilled className="text-3xl" />
          </a>
        </div>
        {userId ? <></> :
          <div className="sm:flex sm:flex-wrap sm:gap-7 justify-center border-t border-gray-300 mt-5 pt-5">
            <div className="flex flex-col gap-1">
              <p className="font-semibold border-b border-gray-300 mb-2">{t("title_platform")}</p>
              <Link to={WebRoute.Blog}>
                <span>{t("tag_blog")}</span>
              </Link>
              <Link to={WebRoute.UserSupport}>
                <span>{t("tag_support")}</span>
              </Link>
              <Link className="hidden md:block" to={WebRoute.BusinessRegister}>
                <span>{t("tag_register_event")}</span>
              </Link>
              <Link className="hidden md:block" to={WebRoute.BusinessPricing}>
                <span>{t("tag_pricing")}</span>
              </Link>
            </div>
            <div className="flex flex-col gap-1 mt-5 sm:mt-0">
              <p className="font-semibold border-b border-gray-300 mb-2">{t("title_resources")}</p>
              <Link to={WebRoute.Assets}>
                <span>{t("tag_brand_assets")}</span>
              </Link>
              <Link to={WebRoute.Docs}>
                <span>{t(Sections.DocsTerms)}</span>
              </Link>
              <Link to={WebRoute.Docs}>
                <span>{t(Sections.DocsPrivacy)}</span>
              </Link>
              <Link to={WebRoute.Docs}>
                <span>{t(Sections.DocsSla)}</span>
              </Link>
              <Link to={WebRoute.Docs}>
                <span>{t(Sections.DocsSecurity)}</span>
              </Link>
            </div>
          </div>
        }
        <div className="flex justify-between border-t border-gray-300 pt-5 mt-5">
          <p className="text-xs">{parse(t("copyright"))}</p>
          <p className="text-xs ml-2">{t("custom_createdby")} <a href={Links.Vvoids} rel="noreferrer noopener" target="_blank"><span>{t("virtual_voids")}</span></a></p>
        </div>
      </Footer>
    </>
  );
}
