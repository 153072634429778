import { SortOnDigits, TransformTimePickerRangeResult, formTagsOptionals, handleUploadPicture, notifyError, notifySuccess } from "../../handlers/extensions";
import { Color, Defaults, QueryCase, QueryId, Sections, ToastId } from "../../handlers/enums";
import { Badge, Button, Form, Image, InputNumber, Popover, Spin, Switch, Tag, Tooltip, Upload } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { ComponentFormInput, ComponentFormTextarea } from "../components/forms/input";
import { QuestionCircleOutlined, EditOutlined, WarningTwoTone, PlusOutlined, DeleteOutlined, HolderOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { GetData, MutateData } from "../../handlers/axios";
import { useAtom } from "jotai";
import { BusinessEventProgress, BusinessId, EventId, UserId } from "../../handlers/globals";
import dayjs from "dayjs";
import moment from "moment";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { TimePicker } from "antd";
import ImgCrop from "antd-img-crop";

export default function SectionBusinessEventPodiaArtists(props) {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    const { t } = useTranslation();

    const [menuItem, setMenuItem] = useState(Sections.PodiaOverview);
    const [freezeActions, setFreezeActions] = useState(false);
    const [editPodium, setEditPodium] = useState(null);
    const [editArtist, setEditArtist] = useState(null);

    switch (menuItem) {
        case Sections.PodiaOverview:
            return <PodiaOverview section={menuItem} setMenuItem={setMenuItem} setFreezeActions={setFreezeActions} freezeActions={freezeActions} setEditPodium={setEditPodium} />;
        case Sections.PodiumNew:
            return <NewPodiaType section={menuItem} setMenuItem={setMenuItem} setFreezeActions={setFreezeActions} freezeActions={freezeActions} />;
        case Sections.PodiumEdit:
            return <EditPodiaType section={menuItem} setMenuItem={setMenuItem} setFreezeActions={setFreezeActions} freezeActions={freezeActions} setEditArtist={setEditArtist} editPodium={editPodium} />;
        case Sections.ArtistNew:
            return <NewArtistType section={menuItem} setMenuItem={setMenuItem} setFreezeActions={setFreezeActions} freezeActions={freezeActions} editPodium={editPodium} />;
        case Sections.ArtistEdit:
            return <EditArtistType section={menuItem} setMenuItem={setMenuItem} setFreezeActions={setFreezeActions} freezeActions={freezeActions} editPodium={editPodium} editArtist={editArtist} />;
        default:
            return <p className="font-semibold text-lg mb-2">{t("reselect_menu_item")}</p>
    }
}

function PodiaOverview(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);

    const { t } = useTranslation();

    const response = GetData(QueryId.SelectBusinessEventStages, { BusinessId: businessId, UserId: userId, EventId: eventId });

    const [podia, setPodia] = useState([]);

    const formAction = MutateData(QueryId.UpdateBusinessEventStagesOrder,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); } }
    ]);

    const onSortEnd = (oldIndex, newIndex) => {
        setPodia(arrayMoveImmutable(podia, oldIndex, newIndex));

        props.setFreezeActions(true);
        formAction.mutate({ form_field_userid: userId, form_field_eventid: eventId, form_field_businessid: businessId, form_field_stages: arrayMoveImmutable(podia, oldIndex, newIndex).map((item, index) => { return { form_field_sortindex: index + 1, form_field_id: item.id } }) });
    }

    useEffect(() => { setPodia(response?.data ? response.data.sort((a, b) => { return SortOnDigits(a.sortIndex, b.sortIndex); }) : []); }, [response?.data]);

    return (<>
        {response.isLoading ? <Spin /> : response.isFalse
            ? <ComponentState error />
            : <>
                <Tooltip className="flex w-min" title={t("tooltip_dragdrop")}><p className="font-semibold text-lg mb-2 whitespace-nowrap">{t("sequence_stages")}</p><QuestionCircleOutlined className="mt-[6px] h-full ml-1 text-lg" /></Tooltip>
                <div className="flex mb-2 select-none border-sonar-blue border-[1px] bg-sonar-blue hover:bg-sonar-white hover:text-sonar-blue cursor-pointer max-w-fit rounded-md" onClick={() => { props.setMenuItem(Sections.PodiumNew); }}>
                    <PlusOutlined className="ml-2 text-xl" />
                    <div className="flex m-2 font-semibold">
                        <p className="my-auto mr-3">{t("form_add_stage_type")}</p>
                    </div>
                </div>
                {response.isEmpty ? <></> :
                    <SortableList onSortEnd={onSortEnd} className="card-p-sm" draggedItemClassName="card-p-sm cursor-grabbing">
                        {podia.map((podium, index) => {
                            return <div key={podium.id}>
                                <SortableItem>
                                    <div className="flex mb-2 select-none cursor-grab bg-slate-800 hover:bg-slate-700 max-w-fit rounded-md">
                                        <HolderOutlined className="mx-1 text-white text-xl" />
                                        <Podium index={index + 1} podium={podium} refetch={response.refetch} setEditPodium={props.setEditPodium} setMenuItem={props.setMenuItem} freezeActions={props.freezeActions} setFreezeActions={props.setFreezeActions} />
                                        {podium.isValidStage
                                            ? <></>
                                            : <Badge className="absolute top-1 left-1 z-10" size="small" count={Defaults.Space} showZero />
                                        }
                                    </div>
                                </SortableItem>
                            </div>
                        })}
                    </SortableList>
                }
            </>
        }
    </>);
}

function Podium(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const handleOpenChange = (state) => {
        setOpen(state);
    };

    const [businessEventProgress, setBusinessEventProgress] = useAtom(BusinessEventProgress);

    const formActionProgress = MutateData(QueryId.SelectBusinessEventProgress,
        (data) => { setBusinessEventProgress(data?.data ? data.data : businessEventProgress); });

    const onFinishProgress = (properties) => {
        formActionProgress.mutate(properties);
    };

    const formActionState = MutateData(QueryId.DeleteBusinessEventPodium,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); onFinishProgress({ BusinessId: businessId, UserId: userId, EventId: eventId }); notifySuccess(ToastId.ActionSuccessDelete); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinishState = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventId = eventId;

        props.setFreezeActions(true);
        formActionState.mutate(properties);
    };

    return (
        <div className="flex m-2 text-white font-semibold">
            <p className="my-auto mr-3 text-sm">{props.index}. {props.podium.name}</p>
            <Button disabled={props.freezeActions} loading={props.freezeActions} onClick={() => { props.setEditPodium(props.podium); props.setMenuItem(Sections.PodiumEdit); }} className="bg-sonar-yellow ml-2" shape="circle" icon={<EditOutlined />} />
            <Popover
                content={<>
                    <p className="mb-4">{t("popover_podium_delete")}</p>
                    <div>
                        <Button className="bg-sonar-green mr-2" onClick={(() => { onFinishState({ form_field_stageId: props.podium.id }); handleOpenChange(false); })}>{t("popover_confirm")}</Button>
                        <Button className="bg-red-400" onClick={(() => { handleOpenChange(false); })}>{t("popover_cancel")}</Button>
                    </div>
                </>}
                title={t("popover_title_delete")}
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
            >
                <Button disabled={props.freezeActions} loading={props.freezeActions} onClick={() => { handleOpenChange(true); }} className="bg-sonar-red ml-2" shape="circle" icon={<DeleteOutlined />} />
            </Popover>
        </div>);
}

function EditPodiaType(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);
    const { t } = useTranslation();

    const response = GetData(QueryId.SelectBusinessEventStageArtists, { BusinessId: businessId, UserId: userId, EventId: eventId, StageId: props.editPodium.id });

    const [artists, setArtists] = useState([]);

    const formActionSortArtists = MutateData(QueryId.UpdateBusinessEventStageArtistsOrder,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); } }
    ]);

    const onSortEnd = (oldIndex, newIndex) => {
        setArtists(arrayMoveImmutable(artists, oldIndex, newIndex));

        props.setFreezeActions(true);
        formActionSortArtists.mutate({ form_field_userid: userId, form_field_eventid: eventId, form_field_businessid: businessId, form_field_stageid: props.editPodium.id, form_field_artists: arrayMoveImmutable(artists, oldIndex, newIndex).map((item, index) => { return { form_field_sortindex: index + 1, form_field_id: item.id } }) });
    }

    useEffect(() => { setArtists(response?.data ? response.data.sort((a, b) => { return SortOnDigits(a.sortIndex, b.sortIndex); }) : []); }, [response?.data]);

    const [form] = Form.useForm();

    const formAction = MutateData(QueryId.UpdateBusinessEventStage,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.setMenuItem(Sections.PodiaOverview); notifySuccess(ToastId.ActionSuccessUpdate); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinish = (properties) => {
        if (properties.form_field_stage_description === "<p><br></p>") {
            notifyError(ToastId.RequiredFields); return;
        };

        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventId = eventId;
        properties.form_field_stageId = props.editPodium.id;

        props.setFreezeActions(true);
        formAction.mutate(properties);
    };

    return (<>
        <Button disabled={props.freezeActions} loading={props.freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { props.setMenuItem(Sections.PodiaOverview); }} className="bg-sonar-blue mb-5">{t("back")}</Button>
        <p className="font-semibold text-lg mb-2">{t("edit_stage_type")}</p>
        <Form
            form={form}
            requiredMark={formTagsOptionals}
            colon={false}
            onFinish={onFinish}
        >
            <ComponentFormInput field={"form_field_stage_name"} placeholder={"placeholder_stagename"} value={props.editPodium.name} feedback clear required />
            <ComponentFormTextarea field={"form_field_stage_description"} placeholder={t("placeholder_stagedescription")} value={props.editPodium.description} counter clear max={999} />
            <Form.Item>
                <Button disabled={props.freezeActions} loading={props.freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-5">{t("form_update_stage_type")}</Button>
            </Form.Item>
        </Form>
        <hr className="my-3" />
        {response.isLoading ? <Spin /> : response.isFalse
            ? <ComponentState error />
            : <>
                <Tooltip className="flex w-min" title={t("tooltip_dragdrop")}><p className="font-semibold text-lg mb-2 whitespace-nowrap">{t("sequence_artists")}</p><QuestionCircleOutlined className="mt-[6px] h-full ml-1 text-lg" /></Tooltip>
                {response.isEmpty
                    ? <Badge size="small" count={Defaults.Space} showZero>
                        <div className="flex mb-2 select-none border-sonar-blue border-[1px] bg-sonar-blue hover:bg-sonar-white hover:text-sonar-blue cursor-pointer max-w-fit rounded-md" onClick={() => { props.setMenuItem(Sections.ArtistNew); }}>
                            <PlusOutlined className="ml-2 text-xl" />
                            <div className="flex m-2 font-semibold">
                                <p className="my-auto mr-3">{t("form_add_artist_type")}</p>
                            </div>
                        </div>
                    </Badge>
                    : <div className="flex mb-2 select-none border-sonar-blue border-[1px] bg-sonar-blue hover:bg-sonar-white hover:text-sonar-blue cursor-pointer max-w-fit rounded-md" onClick={() => { props.setMenuItem(Sections.ArtistNew); }}>
                        <PlusOutlined className="ml-2 text-xl" />
                        <div className="flex m-2 font-semibold">
                            <p className="my-auto mr-3">{t("form_add_artist_type")}</p>
                        </div>
                    </div>
                }
                {response.isEmpty ? <></> :
                    <SortableList onSortEnd={onSortEnd} className="card-p-sm" draggedItemClassName="card-p-sm cursor-grabbing">
                        {artists.map((artist, index) => {
                            return <div key={artist.id}>
                                <SortableItem>
                                    <div className="flex mb-2 select-none cursor-grab bg-slate-800 hover:bg-slate-700 max-w-fit rounded-md">
                                        <HolderOutlined className="mx-1 text-white text-xl" />
                                        <Artist index={index + 1} artist={artist} refetch={response.refetch} setEditArtist={props.setEditArtist} setMenuItem={props.setMenuItem} freezeActions={props.freezeActions} setFreezeActions={props.setFreezeActions} />
                                    </div>
                                </SortableItem>
                            </div>
                        })}
                    </SortableList>
                }
            </>
        }
    </>);
}

function Artist(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);

    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    const handleOpenChange = (state) => {
        setOpen(state);
    };

    const [businessEventProgress, setBusinessEventProgress] = useAtom(BusinessEventProgress);

    const formActionProgress = MutateData(QueryId.SelectBusinessEventProgress,
        (data) => { setBusinessEventProgress(data?.data ? data.data : businessEventProgress); });

    const onFinishProgress = (properties) => {
        formActionProgress.mutate(properties);
    };

    const formActionState = MutateData(QueryId.DeleteBusinessEventArtist,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); onFinishProgress({ BusinessId: businessId, UserId: userId, EventId: eventId }); notifySuccess(ToastId.ActionSuccessDelete); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinishState = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventId = eventId;

        props.setFreezeActions(true);
        formActionState.mutate(properties);
    };

    return (<div className="flex m-2 text-white font-semibold">
        <p className="my-auto mr-3 text-sm">{props.index}. {props.artist.name}</p>

        <Button disabled={props.freezeActions} loading={props.freezeActions} onClick={() => { props.setEditArtist(props.artist); props.setMenuItem(Sections.ArtistEdit); }} className="bg-sonar-yellow ml-2" shape="circle" icon={<EditOutlined />} />
        <Popover
            content={<>
                <p className="mb-4">{t("popover_artist_delete")}</p>
                <div>
                    <Button className="bg-sonar-green mr-2" onClick={(() => { onFinishState({ form_field_artistId: props.artist.id }); handleOpenChange(false); })}>{t("popover_confirm")}</Button>
                    <Button className="bg-red-400" onClick={(() => { handleOpenChange(false); })}>{t("popover_cancel")}</Button>
                </div>
            </>}
            title={t("popover_title_delete")}
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
        >
            <Button disabled={props.freezeActions} loading={props.freezeActions} onClick={() => { handleOpenChange(true); }} className="bg-sonar-red ml-2" shape="circle" icon={<DeleteOutlined />} />
        </Popover>
    </div>);
}

function NewPodiaType(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);

    const [form] = Form.useForm();

    const { t } = useTranslation();

    const [businessEventProgress, setBusinessEventProgress] = useAtom(BusinessEventProgress);

    const formActionProgress = MutateData(QueryId.SelectBusinessEventProgress,
        (data) => { setBusinessEventProgress(data?.data ? data.data : businessEventProgress); });

    const onFinishProgress = (properties) => {
        formActionProgress.mutate(properties);
    };

    const formAction = MutateData(QueryId.InsertBusinessEventStage,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { onFinishProgress({ BusinessId: businessId, UserId: userId, EventId: eventId }); props.setMenuItem(Sections.PodiaOverview); notifySuccess(ToastId.ActionSuccessCreate); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinish = (properties) => {
        if (properties.form_field_stage_description === "<p><br></p>") {
            notifyError(ToastId.RequiredFields); return;
        };

        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventId = eventId;

        props.setFreezeActions(true);
        formAction.mutate(properties);
    };

    return (<>
        <Button disabled={props.freezeActions} loading={props.freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { props.setMenuItem(Sections.PodiaOverview); }} className="bg-sonar-blue mb-5">{t("back")}</Button>
        <p className="font-semibold text-lg mb-2">{t("new_stage_type")}</p>
        <Form
            form={form}
            requiredMark={formTagsOptionals}
            colon={false}
            onFinish={onFinish}
        >
            <ComponentFormInput field={"form_field_stage_name"} placeholder={"placeholder_stagename"} feedback clear required />
            <ComponentFormTextarea field={"form_field_stage_description"} placeholder={t("placeholder_stagedescription")} counter clear max={999} />
            <Form.Item>
                <Button disabled={props.freezeActions} loading={props.freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-5">{t("form_add_stage_type")}</Button>
            </Form.Item>
        </Form>
    </>);
}


function NewArtistType(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const [businessEventProgress, setBusinessEventProgress] = useAtom(BusinessEventProgress);

    const formActionProgress = MutateData(QueryId.SelectBusinessEventProgress,
        (data) => { setBusinessEventProgress(data?.data ? data.data : businessEventProgress); });

    const onFinishProgress = (properties) => {
        formActionProgress.mutate(properties);
    };

    const formAction = MutateData(QueryId.InsertBusinessEventStageArtist,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { onFinishProgress({ BusinessId: businessId, UserId: userId, EventId: eventId }); props.setMenuItem(Sections.PodiumEdit); notifySuccess(ToastId.ActionSuccessCreate); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const [picture, setPicture] = useState(null);
    const [revealed, setRevealed] = useState(false);

    const onFinish = (properties) => {
        if (!properties.form_field_starttime) {
            notifyError(ToastId.RequiredFields); return;
        };

        properties.form_field_starttime = TransformTimePickerRangeResult(properties.form_field_starttime);

        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventId = eventId;
        properties.form_field_stageId = props.editPodium.id;
        properties.form_field_picture = picture;

        props.setFreezeActions(true);
        formAction.mutate(properties);
    };

    const onFinishPicture = (file) => {
        if (file) {
            handleUploadPicture(file, 3, 200, 200, "sonar/artists").then((result) => {
                if (!result) { notifyError(ToastId.ActionError); return; }
                setPicture(result);
            }).catch(() => {
                notifyError(ToastId.ActionError);
            });
        }
        else {
            notifyError(ToastId.ActionError);
        }
    };

    return (<>
        <Button disabled={props.freezeActions} loading={props.freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { props.setMenuItem(Sections.PodiumEdit); }} className="bg-sonar-blue mb-5">{t("back")}</Button>
        <p className="font-semibold text-lg mb-2">{t("new_artist_type")}</p>
        <Form
            form={form}
            requiredMark={formTagsOptionals}
            colon={false}
            onFinish={onFinish}
        >
            <ComponentFormInput field={"form_field_artist_name"} placeholder={"placeholder_artistname"} feedback clear required />
            <Form.Item name={"form_field_playday"} label={<Tooltip title={t("tooltip_playday")}><Tag color="error">{t("tag_onetimesave")}</Tag>{t("form_field_playday")}<QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip>} initialValue={1} required>
                <InputNumber required changeOnWheel={false} controls={false} min={1} max={99} />
            </Form.Item>
            <Form.Item name={"form_field_starttime"} label={(revealed ? <><Tag color="error">{t("tag_onetimesave")}</Tag>{t("form_field_starttime")}</> : t("form_field_starttime"))} initialValue={[dayjs(moment().startOf("hour").format(Defaults.TimeFormat), Defaults.TimeFormat), dayjs(moment().add(1, "hour").startOf("hour").format(Defaults.TimeFormat), Defaults.TimeFormat)]} required>
                <TimePicker.RangePicker readOnly order={false} inputReadOnly needConfirm={false} suffixIcon={null} minuteStep={5} allowClear={false} format={Defaults.TimeFormat} />
            </Form.Item>
            <Form.Item className="ant-switch-form-override" name={"form_field_revealed"} label={<Tooltip title={t("tooltip_tba")}>{revealed ? <Tag color="error">{t("tag_onetimesave")}</Tag> : <></>}{t("form_field_revealed")}<QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip>} initialValue={revealed} required>
                <Switch checkedChildren={t("revealed")} unCheckedChildren={t("tba")} onChange={(checked) => { setRevealed(checked); }} />
            </Form.Item>
            <ComponentFormInput field={"form_field_url_spotify"} placeholder={"placeholder_url"} feedback clear url />
            <ComponentFormInput field={"form_field_url_soundcloud"} placeholder={"placeholder_url"} feedback clear url />
            <ComponentFormInput field={"form_field_url_instagram"} placeholder={"placeholder_url"} feedback clear url />
            <ComponentFormInput field={"form_field_url_website"} placeholder={"placeholder_url"} feedback clear url />
            <div className="flex mt-2">
                <p className="mr-3 my-auto"><Tag color="warning">{t("tag_optional")}</Tag>{t("form_field_logo")}</p>
                {!picture ? <></> :
                    <Image className="rounded-full" rootClassName="mr-3" src={picture} preview={false} width={"100px"} height={"100px"} />
                }
                <ImgCrop quality={1} fillColor="transparant" modalTitle={<><p className="mb-2">{t("edit_picture")}</p><div className="flex"><WarningTwoTone className="text-lg mb-auto mr-2" twoToneColor={Color.Yellow} /><p className="font-normal text-sm text-sonar-yellow">{t("tooltip_edit_picture")}</p></div></>} resetText={t("reset")} modalOk={t("confirm")} modalCancel={t("cancel")} zoomSlider aspect={1} showReset showGrid>
                    <Upload className="ant-upload-size-override-artist" fileList={[]} onPreview={() => { }} maxCount={1} accept="image/jpeg,image/png" beforeUpload={(file) => { onFinishPicture(file); return false; }} listType="picture-circle" showUploadList={false}>
                        <PlusOutlined />
                    </Upload>
                </ImgCrop>
            </div>
            <Form.Item>
                <Button disabled={props.freezeActions} loading={props.freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_add_artist_type")}</Button>
            </Form.Item>
        </Form>
    </>);
}

function EditArtistType(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);

    const { t } = useTranslation();

    const [form] = Form.useForm();

    const formAction = MutateData(QueryId.UpdateBusinessEventStageArtist,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.setMenuItem(Sections.PodiumEdit); notifySuccess(ToastId.ActionSuccessUpdate); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const [picture, setPicture] = useState(null);

    const onFinish = (properties) => {
        if (!properties.form_field_starttime) {
            notifyError(ToastId.RequiredFields); return;
        };

        properties.form_field_starttime = TransformTimePickerRangeResult(properties.form_field_starttime);

        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventId = eventId;
        properties.form_field_stageId = props.editPodium.id;
        properties.form_field_revealed = props.editArtist.isRevealed ? true : properties.form_field_revealed;
        properties.form_field_artistId = props.editArtist.id;
        properties.form_field_picture = picture ? picture : props.editArtist.picture;

        props.setFreezeActions(true);
        formAction.mutate(properties);
    };

    const onFinishPicture = (file) => {
        if (file) {
            handleUploadPicture(file, 3, 200, 200, "sonar/artists").then((result) => {
                if (!result) { notifyError(ToastId.ActionError); return; }
                setPicture(result);
            }).catch(() => {
                notifyError(ToastId.ActionError);
            });
        }
        else {
            notifyError(ToastId.ActionError);
        }
    };


    return (<>
        <Button disabled={props.freezeActions} loading={props.freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { props.setMenuItem(Sections.PodiumEdit); }} className="bg-sonar-blue mb-5">{t("back")}</Button>
        <p className="font-semibold text-lg mb-2">{t("edit_artist_type")}</p>
        <Form
            form={form}
            requiredMark={formTagsOptionals}
            colon={false}
            onFinish={onFinish}
        >
            <ComponentFormInput field={"form_field_artist_name"} placeholder={"placeholder_artistname"} value={props.editArtist.name} feedback clear required />
            <Form.Item name={"form_field_playday"} label={<Tooltip title={t("tooltip_playday")}>{t("form_field_playday")}<QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip>} initialValue={props.editArtist.playDay} required>
                <InputNumber disabled={true} required changeOnWheel={false} controls={false} min={1} max={99} />
            </Form.Item>
            <Form.Item name={"form_field_starttime"} label={t("form_field_starttime")} initialValue={[dayjs(moment(props.editArtist.startsAt).format(Defaults.TimeFormat), Defaults.TimeFormat), dayjs(moment(props.editArtist.endsAt).format(Defaults.TimeFormat), Defaults.TimeFormat)]} required>
                <TimePicker.RangePicker disabled={props.editArtist.isRevealed} readOnly order={false} inputReadOnly needConfirm={false} suffixIcon={null} minuteStep={5} allowClear={false} format={Defaults.TimeFormat} />
            </Form.Item>
            <Form.Item className="ant-switch-form-override" name={"form_field_revealed"} label={<Tooltip title={t("tooltip_tba")}>{t("form_field_revealed")}<QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip>} initialValue={props.editArtist.isRevealed} required>
                <Switch disabled={props.editArtist.isRevealed} checkedChildren={t("revealed")} unCheckedChildren={t("tba")} />
            </Form.Item>
            <ComponentFormInput field={"form_field_url_spotify"} placeholder={"placeholder_url"} feedback clear url value={props.editArtist.soundcloudUrl} />
            <ComponentFormInput field={"form_field_url_soundcloud"} placeholder={"placeholder_url"} feedback clear url value={props.editArtist.spotifyUrl} />
            <ComponentFormInput field={"form_field_url_instagram"} placeholder={"placeholder_url"} feedback clear url value={props.editArtist.instagramUrl} />
            <ComponentFormInput field={"form_field_url_website"} placeholder={"placeholder_url"} feedback clear url value={props.editArtist.websiteUrl} />
            <div className="flex mt-2">
                <p className="mr-3 my-auto"><Tag color="warning">{t("tag_optional")}</Tag>{t("form_field_logo")}</p>
                {!props.editArtist.picture && !picture ? <></> :
                    <Image className="rounded-full" rootClassName="mr-3" src={picture ? picture : props.editArtist.picture} preview={false} width={"100px"} height={"100px"} />
                }
                <ImgCrop quality={1} fillColor="transparant" modalTitle={<><p className="mb-2">{t("edit_picture")}</p><div className="flex"><WarningTwoTone className="text-lg mb-auto mr-2" twoToneColor={Color.Yellow} /><p className="font-normal text-sm text-sonar-yellow">{t("tooltip_edit_picture")}</p></div></>} resetText={t("reset")} modalOk={t("confirm")} modalCancel={t("cancel")} zoomSlider aspect={1} showReset showGrid>
                    <Upload className="ant-upload-size-override-artist" fileList={[]} onPreview={() => { }} maxCount={1} accept="image/jpeg,image/png" beforeUpload={(file) => { onFinishPicture(file); return false; }} listType="picture-circle" showUploadList={false}>
                        <PlusOutlined />
                    </Upload>
                </ImgCrop>
            </div>
            <Form.Item>
                <Button disabled={props.freezeActions} loading={props.freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_update_artist_type")}</Button>
            </Form.Item>
        </Form>
    </>);
}