import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import ComponentHeader from "./components/header";
import ComponentFooter from "./components/footer";
import React, { useEffect, useState } from "react";
import { FormOutlined, AppstoreAddOutlined, AuditOutlined, NotificationOutlined, SolutionOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Sections, WebRoute } from "../handlers/enums";
import SideMenu from "./components/sideMenu";
import { SectionBusinessSupport } from "./sections/SectionBusinessSupport";
import { Navigate } from "react-router-dom";
import { SectionBusinessSecurity } from "./sections/SectionBusinessSecurity";
import { useAtom } from "jotai";
import { BusinessId, IsBusinessCompleted, IsBusinessOwner, UserId } from "../handlers/globals";
import { SectionBusinessGeneral } from "./sections/SectionBusinessGeneral";
import { SectionBusinessTeam } from "./sections/SectionBusinessTeam";
import { SectionBusinessEvents } from "./sections/SectionBusinessEvents";

export default function BusinessOverview() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  const { t } = useTranslation();

  const [userId,] = useAtom(UserId);
  const [businessId,] = useAtom(BusinessId);
  const [isBusinessCompleted,] = useAtom(IsBusinessCompleted);
  const [isBusinessOwner,] = useAtom(IsBusinessOwner);

  const [menuItem, setMenuItem] = useState(isBusinessCompleted ? Sections.BusinessEvents : Sections.BusinessGeneral);

  return (
    <>
      {userId && businessId ? null : <Navigate replace to={WebRoute.BusinessSignin} />}
      <Layout className="!bg-transparent">
        <div id={Sections.Top}></div>
        <ComponentHeader />
        <Layout hasSider className="hidden md:flex !bg-transparent pr-8 justify-between lg:justify-center">
          <Sider className="!bg-transparent !min-w-60 border-r pt-2">
            <SideMenu initialValue={menuItem} setMenu={setMenuItem} items={[
              { key: Sections.BusinessGeneral, icon: <FormOutlined />, disabled: !isBusinessOwner },
              { key: Sections.BusinessTeam, icon: <AppstoreAddOutlined />, disabled: !isBusinessOwner },
              { key: Sections.BusinessEvents, icon: <AuditOutlined />, disabled: !isBusinessCompleted },
              { key: Sections.BusinessSecurity, icon: <SolutionOutlined /> },
              { key: Sections.BusinessSupport, icon: <NotificationOutlined /> }
            ]} />
          </Sider>
          <Content className="min-h-[80vh] max-w-3xl pl-8 py-8">
            <SectionContent section={menuItem} />
          </Content>
        </Layout>
        <Layout className="md:hidden !bg-transparent px-8">
          <Content className="min-h-[80vh] pt-8">
            <p>{t("required_desktop")}</p>
          </Content>
        </Layout>
        <ComponentFooter />
      </Layout>
    </>
  );
}

function SectionContent(props) {
  const { t } = useTranslation();

  switch (props.section) {
    case Sections.BusinessGeneral:
      return <SectionBusinessGeneral section={props.section} />;
    case Sections.BusinessTeam:
      return <SectionBusinessTeam section={props.section} />;
    case Sections.BusinessEvents:
      return <SectionBusinessEvents section={props.section} />;
    case Sections.BusinessSecurity:
      return <SectionBusinessSecurity section={props.section} />;
    case Sections.BusinessSupport:
      return <SectionBusinessSupport section={props.section} />
    default:
      return <p className="font-semibold text-lg mb-2">{t("select_menu_item")}</p>
  }
}