import { Button, Layout, Spin } from "antd";
import ComponentState from "../components/states";
import { Content } from "antd/es/layout/layout";
import ComponentHeader from "../components/header";
import ComponentFooter from "../components/footer";
import React, { useEffect, useState } from "react";
import { Defaults, QueryCase, QueryId, WebRoute } from "../../handlers/enums";
import { GetData } from "../../handlers/axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Paragraph from "antd/es/typography/Paragraph";
import { useTranslation } from "react-i18next";

export default function EventPaymentCallback() {
  useEffect(() => { window.scrollTo(0, 0); }, []);

  const [params,] = useSearchParams();

  const { i18n, t } = useTranslation();

  const response = GetData(QueryId.SelectOrderState, { LanguageIso: i18n.language, OrderId: params.get("orderId") }, false, 0);

  const [polls, setPolls] = useState(1);

  const navigate = useNavigate();

  const maxPolls = 3;

  useEffect(() => {
    if (response.condition === QueryCase.MissingParameters) { navigate(WebRoute.Home); return; };
    if (polls > maxPolls || response.condition === QueryCase.Success) { return; }
    const interval = setInterval(() => { setPolls(polls + 1 + (params.get("orderId") === Defaults.GuidEmpty ? maxPolls : 0)); if (polls + 1 <= maxPolls) { response.refetch(); } }, 5000);
    return () => clearInterval(interval);
  }, [polls, response, params, navigate]);

  return (
    <>
      <Layout className="!bg-transparent">
        <ComponentHeader lite />
        <Layout className="flex !bg-transparent justify-between lg:justify-center">
          <Content className="min-h-[80vh] pb-8">
            {response.isLoading || (response.isFalse && polls <= maxPolls)
              ? <>
                <Spin className="mx-auto w-full mt-5" size="large" />
                <p className="text-center mt-7 text-gray-400">{t("fetching_order_status")}</p>
              </>
              : params.get("orderId") === Defaults.GuidEmpty
                ? <ComponentState
                  status={"error"}
                  title={t("order_cancelled")}
                  description={t("order_cancel")}
                  extra={[<Button key={"redirect"} onClick={() => { navigate(WebRoute.Home); }}>{t("redirect_homepage")}</Button>]} />
                : polls > maxPolls
                  ? <ComponentState error description={<span>{t("copy_report_orderid")}: <Paragraph copyable={{ tooltips: [t("copy"), t("copied")] }}>{params.get("orderId")}</Paragraph>{t("delay_confirmation")}<br />{t("refresh_repoll")}</span>} />
                  : <ComponentState
                    status={"success"}
                    title={t("order_success")}
                    description={<><span className="flex justify-center">{t("prefix_orderid")}: <Paragraph className="ml-1" copyable={{ tooltips: [t("copy"), t("copied")] }}>{params.get("orderId")}</Paragraph></span><span>{t("copy_report_orderid")}<br />{t("view_email_confirm")}</span></>}
                    extra={[<Button key={"redirect"} onClick={() => { navigate(WebRoute.Home); }}>{t("redirect_homepage")}</Button>]} />
            }
          </Content>
        </Layout>
        <ComponentFooter />
      </Layout>
    </>
  );
}