import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import ComponentHeader from "./components/header";
import ComponentFooter from "./components/footer";
import React, { useEffect, useState } from "react";
import { ApiOutlined, FileProtectOutlined, SafetyOutlined, FileTextOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Sections } from "../handlers/enums";
import SideMenu from "./components/sideMenu";

export default function Docs() {
  useEffect(() => { window.scrollTo(0, 0); }, []);

  const [menuItem, setMenuItem] = useState([Sections.DocsTerms]);

  return (
    <>
      <Layout className="!bg-transparent">
        <div id={Sections.Top}></div>
        <ComponentHeader />
        <Layout hasSider={true} className="hidden md:flex !bg-transparent pr-8 justify-between lg:justify-center">
          <Sider className="!bg-transparent !min-w-60 border-r pt-2">
            <SideMenu initialValue={menuItem} useTop setMenu={setMenuItem} items={[
              { key: Sections.DocsSla, icon: <ApiOutlined /> },
              { key: Sections.DocsSecurity, icon: <SafetyOutlined /> },
              { key: Sections.DocsTerms, icon: <FileTextOutlined /> },
              { key: Sections.DocsPrivacy, icon: <FileProtectOutlined /> }
            ]} />
          </Sider>
          <Content className="min-h-[80vh] pl-8 py-8 bg-red">
            <MainContent />
          </Content>
        </Layout>
        <Layout className="md:hidden !bg-transparent px-8">
          <Content className="min-h-[80vh] pt-8">
            <MainContent />
          </Content>
        </Layout>
        <ComponentFooter />
      </Layout>
    </>
  );
}

function MainContent() {
  const { t } = useTranslation();

  return (
    <>
      <p id={Sections.DocsSla} className="font-semibold text-lg mb-2">{t(Sections.DocsSla)}</p>
      <div className="mb-6">
        <Sla />
      </div>
      <p id={Sections.DocsSecurity} className="font-semibold text-lg mb-2">{t(Sections.DocsSecurity)}</p>
      <div className="mb-6">
        <Security />
      </div>
      <p id={Sections.DocsTerms} className="font-semibold text-lg mb-2">{t(Sections.DocsTerms)}</p>
      <div className="mb-6">
        <Terms />
      </div>
      <p id={Sections.DocsPrivacy} className="font-semibold text-lg mb-2">{t(Sections.DocsPrivacy)}</p>
      <div className="mb-6">
        <Privacy />
      </div>
    </>
  )
}

function Privacy() {
  return (
    <>
      <strong>Sectie ~ SONAR EVENT WEBSITE</strong>
      <p>Momenteel ENKEL in het Nederlands</p>
      <p>Privacy beleid</p>
      <p>SONAR EVENT Privacy beleid</p>
      <p>In de huidige maatschappij is het essentieel dat u als betrokkene in een overeenkomst met SONAR EVENT kan rekenen op uw privacy en daarom zal SONAR EVENT ook steeds uw persoonlijke gegevens met respect behandelen. Welke gegevens BV SONAR EVENT & STREET OF MARKETING (hierna: SONAR EVENT) bewaart, verzamelt en verwerkt, vindt u hieronder terug. Bij verdere vragen over uw privacy, aarzel niet om een mail te sturen via het contact formulier op de website: https://sonarevent.com.</p>
      <p>Het privacy beleid kan gewijzigd worden als er nieuwe ontwikkelingen plaatsvinden. Het meest actuele beleid vindt u steeds terug op de website van SONAR EVENT. De datum waarop het privacy beleid is geplaatst staat hier onderaan, dan ziet u ook wanneer het gewijzigd is.</p>

      <strong>Artikel 1: Algemene bepalingen</strong>
      <p>1.	SONAR EVENT zet steeds in op het verzekeren van de bescherming van de natuurlijke personen in verband met de verwerking van persoonsgegevens. Ze houdt hierbij rekening met de regels betreffende het vrij verkeer van persoonsgegevens. Deze regels beschermen de grondrechten van de natuurlijke personen en hun fundamentele rechten en vrijheden, in het bijzonder bescherming van persoonsgegevens. [1]</p>
      <p>2.	De gegevens van rechtspersonen kennen geen bescherming.</p>
      <p>3.	SONAR EVENT werkt met gedeeltelijke geautomatiseerde verwerking van persoonsgegevens, soms zal men ook manueel persoonsgegevens in een bestand opnemen. [2],[3]</p>
      <p>4.	SONAR EVENT bevindt zich binnen de Europese Unie.[4] Met name in de Stropkaai 14, 9000 Gent, België.</p>

      <strong>Artikel 2: Definities[5]</strong>
      <p>Volgende definities zijn volledig in overeenstemming met artikel 4 van de algemene verordening betreffende gegevensbescherming.</p>
      <p>1.	Persoonsgegevens</p>
      <p>Betreft alle informatie over een geïdentificeerde of identificeerbare natuurlijke persoon: als identificeerbaar wordt beschouwd een natuurlijke persoon die direct of indirect kan worden geïdentificeerd. Het zal dan gaan over informatie die de verwerker zal krijgen aan de hand van een identificatie zoals een naam, locatiegegevens of een online identificatie.</p>
      <p>2.	Verwerking:</p>
      <p>De verwerking zal gebeuren bij een bewerking of een geheel van bewerkingen met betrekking tot persoonsgegevens. Die verwerking kan volgende zaken betreffen: verzamelen, vastleggen, ordenen, structureren, opslaan, bijwerken of wijzigen, opvragen, raadplegen, gebruiken, verstrekken door middel van doorzending, verspreiden of op andere wijze ter beschikking stellen, aligneren of combineren, afschermen, wissen of vernietigen van gegevens.</p>
      <p>3.	Pseudonimisering:</p>
      <p>Het verwerken van persoonsgegevens op zodanige wijze dat de persoonsgegevens niet meer aan een specifieke betrokkene kunnen worden gelinkt zonder dat er bijkomende gegevens worden gebruikt.</p>
      <p>4.	Bestand:</p>
      <p>Elk gestructureerd geheel van persoonsgegevens die volgens bepaalde criteria toegankelijk zijn.</p>
      <p>5.	Verwerkingsverantwoordelijke:</p>
      <p>SONAR EVENT die het doel van en de middelen voor de verwerking van de persoonsgegevens vaststelt.</p>
      <p>6.	Verwerker:</p>
      <p>SONAR EVENT</p>
      <p>7.	Ontvanger:</p>
      <p>Een rechtspersoon of een overheidsinstantie waaraan de persoonsgegevens worden verstrekt.</p>
      <p>8.	Toestemming van de betrokkene:</p>
      <p>Elke vrije, specifieke, geïnformeerde en ondubbelzinnige wilsuiting waarmee de betrokkene door middel van het aanvinken van het vakje gelezen en goedgekeurd de verwerking van persoonsgegevens aanvaardt.</p>
      <p>9.	Inbreuk in verband met persoonsgegevens:</p>
      <p>Een inbreuk op de beveiliging die per ongeluk of op onrechtmatige wijze leidt tot de vernietiging, het verlies, de wijziging of de ongeoorloofde verstrekking van of de ongeoorloofde toegang tot doorgezonden, opgeslagen of anderszins verwerkte gegevens.</p>
      <p>10.	Hoofdvestiging:</p>
      <p>Gent</p>
      <p>11.	Toezichthoudende autoriteit:</p>
      <p>Een door een lidstaat ingevolge artikel 51 ingestelde onafhankelijke overheidsinstantie: hier de gegevensbeschermingsautoriteit.</p>

      <strong>Artikel 3: Beginselen inzake verwerking van persoonsgegevens</strong>
      <p>SONAR EVENT zal de persoonsgegevens:[6]</p>
      <p>1.	Verwerken op een manier die ten aanzien van de betrokkene rechtmatig, behoorlijk en transparant is. Via dit privacy beleid laat SONAR EVENT iedere betrokkene kennismaken met de manier van verwerken en de te bereiken doelstellingen. Elke betrokkene kan ook steeds bij vragen contact opnemen met SONAR EVENT.</p>
      <p>2.	Verzamelen, namelijk de volgende: het E-mailadres, de voornaam en achternaam, het aantal bestelde tickets per soort en de geboortedatum. Deze gegevens worden verzameld met het oog op het goed uitvoeren van de overeenkomt. Ze worden dus gebruikt om de bestelling en de betaling te bevestigen via mails. Verder worden de tickets op naam gemaakt. Ook worden deze gebruikt om latere klantenondersteuning te voorzien. Verder vraagt SONAR EVENT ook de geboortedatum om te controleren dat de betrokkene ouder is dan 13 jaar.[7],[8]</p>
      <p>3.	en dus ook de elektronische identificatiegegevens zoals het IP-adres van de gebruiker, het type toestel van de gebruiker en informatie over via welke bron de betrokkene de website heeft bereikt (via bv. Facebook, mail, Instagram). Het IP-adres wordt bijgehouden om misbruik tegen te gaan, de andere gegevens zijn puur voor statische doeleinden bestemd. Bij het bewaren zullen voor statistische doeleinden de gegevens worden gepseudonimiseerd.</p>
      <p>4.	Enkel vragen die noodzakelijk zijn om een goede dienst te kunnen verschaffen.</p>
      <p>5.	Bewaren voor een termijn van twee jaar, zodat de bestelling correct kunnen worden afgewerkt en eventueel herzien door SONAR EVENT. Verder worden de gegevens bewaard met het oog op statistische doeleinden zoals het analyseren van de verkoop en het ontdekken van trends.</p>
      <p>6.	op een integere en vertrouwelijke manier behandelen.</p>

      <strong>Artikel 4: Rechtmatigheid van verwerking</strong>
      <p>1.	SONAR EVENT zal steeds aan de betrokkene toestemming vragen voor de verwerking van de persoonsgegevens.</p>
      <p>2.	De verwerking is noodzakelijk voor de uitvoering van de overeenkomst.</p>

      <strong>Artikel 5: Voorwaarden voor toestemming</strong>
      <p>1.	SONAR EVENT zal een bestelling pas kunnen aanvaarden als de betrokkene zich expliciet akkoord heeft verklaard met het privacy beleid.</p>
      <p>2.	Wanneer de betrokkene zijn toestemming wenst in te trekken dan dient deze daarvoor een mail te sturen naar SONAR EVENT. Het is echter wel zo dat de intrekking van de toestemming de reeds eerder gebeurde verwerking onverlet laat.</p>
      <p>3.	Omtrent de toestemming van kinderen: SONAR EVENT zal de toestemming aanvaarden als de betrokkene ouder is dan 13 jaar. De ouders zullen een mail krijgen om de bestelling van het kind te plaatsen als ze akkoord zijn met de bestelling. Wanneer dit niet het geval is, zal er geen bestelling kunnen plaatsvinden zonder toestemming van de ouders.</p>

      <strong>Artikel 6: verstrekken van informatie aan de ontvanger</strong>
      <p>1.	SONAR EVENT zal de informatie die de betrokkene verschaft doorzenden naar de ontvanger, hier zal dat steeds de organisator zijn van het event en de payment provider Mollie.[9] SONAR EVENT zal de betrokkene steeds op de hoogte stellen wie de organisator is en de betrokkene kan op de website van Mollie zowel de algemene voorwaarden als het privacybeleid terugvinden. (https://www.mollie.com/be/privacy ).</p>
      <p>2.	De ontvanger zal beschikken over de gegevens die SONAR EVENT verkrijgt van de betrokkene.</p>
      <p>3.	De informatie bevindt zich op een Cloud, die SONAR EVENT huurt bij Google. SONAR EVENT verzendt al de gegevens met een beveiligde verbinding (SSL).</p>
      <p>4.	Ook zullen overheidsinstellingen of andere bevoegde instanties toegang hebben tot de persoonsgegevens van de klanten waar dit wettelijk is verplicht.</p>

      <strong>Artikel 7: de beveiliging van persoonsgegevens</strong>
      <p>1.	SONAR EVENT neemt als verwerkingsverantwoordelijke, rekening houdend met de waarschijnlijkheid en ernst van de risico's, passende technische en organisatorische maatregelen om de persoonsgegevens zo goed als mogelijk veilig te houden. Die maatregelen omvatten het volgende[10]:</p>
      <p>Het opladen van de gegevens op een beveiligde Cloud, die SONAR EVENT huurt bij Google. Alle gegevens worden via een beveiligde SSL-verbinding verstuurd.</p>
      <p>De site van SONAR EVENT wordt ook regelmatig aan test onderworpen om de veiligheid te kunnen blijven garanderen.</p>
      <p>2.	Als er toch een inbreuk is in het verband met de persoonsgegevens van de betrokkene dan zal SONAR EVENT dat aan deze melden.</p>

      <strong>Artikel 8: Rechten van de betrokkene</strong>
      <p>1.	Wanneer de betrokkene vragen heeft omtrent de rechten dan kan deze steeds een mail sturen naar SONAR EVENT of op de website het formulier invullen. De vragen zullen steeds worden beantwoord en wijzigingen aan de persoonsgegevens zullen worden medegedeeld aan de betrokkene:</p>
      <p>info@sonarevent.com</p>
      <p>https://sonarevent.com</p>
      <p>2.	SONAR EVENT leeft de hierop volgende rechten na en zal de betrokkene bij vragen eerst via mail en indien gewenst telefonisch kosteloos te hulp staan. [11]</p>
      <p>3.	SONAR EVENT zal echter niet kunnen voldoen aan haar plicht als zij niet in staat is als verwerkingsverantwoordelijke de identiteit van de betrokkene te identificeren.[12]</p>
      <p>4.	SONAR EVENT zal de vraag van de betrokkene steeds proberen te beantwoorden binnen de maand na ontvangst van het verzoek. Moest de vraag een zekere complexiteit inhouden dan zal SONAR EVENT de termijn met maximaal twee maanden verlengen. Van die verlenging wordt de betrokkene binnen een maand na het verzoek daarvan op de hoogte gesteld.[13]</p>
      <p>Recht op informatie[14]:</p>
      <p>1.	De betrokkene vindt alle gegevens van SONAR EVENT hierboven in het privacy beleid terug.</p>
      <p>2.	De betrokkene kan steeds inzage, wijziging of verwijdering vragen van de persoonsgegevens waarover SONAR EVENT beschikt.</p>
      <p>3.	Wanneer de betrokkene niet tevreden is over het privacy beleid van SONAR EVENT kan men steeds een mail sturen. De betrokkene kan ook een klacht indienen bij de gegevensbeschermingsautoriteit.</p>
      <p>Recht van inzage[15]:</p>
      <p>1.	De betrokkene vindt in het privacy beleid terug welke persoonsgegevens we verzamelen en voor welke doeleinden SONAR EVENT deze gebruikt.</p>
      <p>2.	Wanneer de betrokkene specifiek wil weten welke gegevens bij SONAR EVENT van hem/haar zijn verzameld dan kan deze een mail sturen naar SONAR EVENT.</p>
      <p>Recht op rectificatie[16]:</p>
      <p>1.	De betrokkene kan SONAR EVENT steeds wijzen op een onjuistheid of een onvolledigheid via mail. SONAR EVENT zal dan trachten dit binnen de maand recht te zetten.</p>
      <p>Recht op vergetelheid[17]:</p>
      <p>1.	SONAR EVENT zal op verzoek van de betrokkene al de verkregen persoonsgegevens wissen.</p>
      <p>2.	SONAR EVENT zal de persoonsgegevens echter niet wissen voor zover de verwerking nodig is:</p>
      <p>Voor het uitoefenen van het recht van vrije meningsuiting en informatie.</p>
      <p>Voor het nakomen van een wettelijke verwerkingsverplichting die is opgenomen in het Europees Unierecht of in Belgische wetgeving.</p>
      <p>Voor de instelling, uitoefening of onderbouwing van een rechtsvordering.</p>
      <p>Recht op beperking van verwerking[18]</p>
      <p>1.	SONAR EVENT zal de verwerking beperken in volgende gevallen:</p>
      <p>In de periode waar de juistheid van de gegevens wordt nagegaan.</p>
      <p>In de periode waar er bezwaar is aangetekend op de verwerking van de gegevens.</p>
      <p>Recht op overdraagbaarheid van gegevens[19]:</p>
      <p>1.	De betrokkene kan de persoonsgegevens verkrijgen in een machine-leesbaar formaat. SONAR EVENT bezorgt deze gegevens via mail.</p>
      <p>Recht van bezwaar[20]:</p>
      <p>1.	De betrokkene kan ten alle tijden bezwaar indienen tegen de manier waarop SONAR EVENT de gegevens verwerkt.</p>
      <p>Recht op beveiliging van de gegevens:</p>
      <p>1.	SONAR EVENT bewaart alle gegevens op een beveiligde Cloud, die wordt gehuurd bij Google, en werken met een beveiligde verbinding (SSL). Ook al het personeel van SONAR EVENT zal steeds zeer zorgvuldig en vertrouwelijk omgaan met de gegevens.</p>
      <p>Recht om klacht in te dienen[21]:</p>
      <p>1.	De betrokkene kan steeds bij SONAR EVENT een klacht indienen als die niet tevreden is met de manier waarop de gegevens worden verwerkt. De betrokkene kan ook een klacht indienen bij de gegevensbeschermingsautoriteit.</p>
      <p>Nog vragen of extra uitleg gewenst?</p>
      <p>Contacteer dan zeker SONAR EVENT wanneer U nog met vragen zou zitten. SONAR EVENT is steeds bereikbaar via de volgende communicatiemiddelen:</p>
      <p>° Ondernemingsnummer: 0795.336.256</p>
      <p>° Btw-nummer: BE0795.336.256</p>
      <p>° Maatschappelijke zetel: De Keyserlei 58-60 bus 19, 2018 Antwerpen</p>
      <p>° Per mail: info@sonarevent.com</p>
      <p>Algemene website: https://sonarevent.com</p>
      <p>Versie van 17 Januari 2023</p>

      <strong>Referenties:</strong>
      <p>[1] Artikel 1 van de verordening betreffende de bescherming van natuurlijke personen in verband met de verwerking van persoonsgegevens en betreffende het vrije verkeer van die gegevens en tot intrekking van Richtlijn 95/46/EG (hierna: algemene verordening gegevensbescherming).</p>
      <p>[2] Artikel 2 algemene verordening gegevensbescherming.</p>
      <p>[3] Artikel 2 wet betreffende de bescherming van natuurlijke personen met betrekking tot de verwerking van persoonsgegevens (hierna: Kaderwet).</p>
      <p>[4] Artikel 3 algemene verordening gegevensbescherming.</p>
      <p>[5] Artikel 4 algemene verordening gegevensbescherming.</p>
      <p>[6] Artikel 5 algemene verordening gegevensbescherming.</p>
      <p>[7] Artikel 8, tweede lid algemene verordening gegevensbescherming.</p>
      <p>[8] Artikel 7 Wet betreffende de bescherming van natuurlijke personen met betrekking tot de verwerking van persoonsgegevens.</p>
      <p>[9] Artikel 15, eerste lid algemene verordening gegevensbescherming.</p>
      <p>[10] Artikel 32 algemene verordening gegevensbescherming.</p>
      <p>[11] Artikel 12, eerste lid algemene verordening gegevensbescherming.</p>
      <p>[12] Artikel 12, tweede lid algemene verordening gegevensbescherming.</p>
      <p>[13] Artikel 12; derde lid algemene verordening gegevensbescherming.</p>
      <p>[14] Artikel 14 algemene verordening gegevensbescherming.</p>
      <p>[15] Artikel 15 algemene verordening gegevensbescherming.</p>
      <p>[16] Artikel 16 algemene verordening gegevensbescherming.</p>
      <p>[17] Artikel 17 algemene verordening gegevensbescherming.</p>
      <p>[18] Artikel 18 algemene verordening gegevensbescherming.</p>
      <p>[19] Artikel 20 algemene verordening gegevensbescherming.</p>
      <p>[20] Artikel 21 algemene verordening gegevensbescherming.</p>
      <p>[21] Artikel 77 algemene verordening gegevensbescherming.</p>

      <strong>Privacy Policy</strong> | (SONAR EVENT SCANNER APP)
      <p>Currently ONLY in English</p>
      <p>

        Sonar Events built the SonarEvents Scanner app as
        a Free app. This SERVICE is provided by
        Sonar Events at no cost and is intended for use as
        is.
      </p> <p>
        This page is used to inform visitors regarding our
        policies with the collection, use, and disclosure of Personal
        Information if anyone decided to use our Service.
      </p> <p>
        If you choose to use our Service, then you agree to
        the collection and use of information in relation to this
        policy. The Personal Information that we collect is
        used for providing and improving the Service. We will not use or share your information with
        anyone except as described in this Privacy Policy.
      </p> <p>
        The terms used in this Privacy Policy have the same meanings
        as in our Terms and Conditions, which are accessible at
        SonarEvents Scanner unless otherwise defined in this Privacy Policy.
      </p> <p><strong>Information Collection and Use</strong></p> <p>
        For a better experience, while using our Service, we
        may require you to provide us with certain personally
        identifiable information, including but not limited to Actions performed by predefined scan accounts,Retrievable data from ticket holders,Ticket holder name,Ticket holder age, Ticket holder ticket data. The information that
        we request will be retained by us and used as described in this privacy policy.
      </p> <div><p>
        The app does use third-party services that may collect
        information used to identify you.
      </p> <p>
          Link to the privacy policy of third-party service providers used
          by the app
        </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><li><a href="https://unity3d.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">Unity</a></li></ul></div> <p><strong>Log Data</strong></p> <p>
        We want to inform you that whenever you
        use our Service, in a case of an error in the app
        we collect data and information (through third-party
        products) on your phone called Log Data. This Log Data may
        include information such as your device Internet Protocol
        (“IP”) address, device name, operating system version, the
        configuration of the app when utilizing our Service,
        the time and date of your use of the Service, and other
        statistics.
      </p> <p><strong>Cookies</strong></p> <p>
        Cookies are files with a small amount of data that are
        commonly used as anonymous unique identifiers. These are sent
        to your browser from the websites that you visit and are
        stored on your device's internal memory.
      </p> <p>
        This Service does not use these “cookies” explicitly. However,
        the app may use third-party code and libraries that use
        “cookies” to collect information and improve their services.
        You have the option to either accept or refuse these cookies
        and know when a cookie is being sent to your device. If you
        choose to refuse our cookies, you may not be able to use some
        portions of this Service.
      </p> <p><strong>Service Providers</strong></p> <p>
        We may employ third-party companies and
        individuals due to the following reasons:
      </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
        We want to inform users of this Service
        that these third parties have access to their Personal
        Information. The reason is to perform the tasks assigned to
        them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.
      </p> <p><strong>Security</strong></p> <p>
        We value your trust in providing us your
        Personal Information, thus we are striving to use commercially
        acceptable means of protecting it. But remember that no method
        of transmission over the internet, or method of electronic
        storage is 100% secure and reliable, and we cannot
        guarantee its absolute security.
      </p> <p><strong>Links to Other Sites</strong></p> <p>
        This Service may contain links to other sites. If you click on
        a third-party link, you will be directed to that site. Note
        that these external sites are not operated by us.
        Therefore, we strongly advise you to review the
        Privacy Policy of these websites. We have
        no control over and assume no responsibility for the content,
        privacy policies, or practices of any third-party sites or
        services.
      </p> <p><strong>Children's Privacy</strong></p> <div><p>
        These Services do not address anyone under the age of 13.
        We do not knowingly collect personally
        identifiable information from children under 13 years of age. In the case
        we discover that a child under 13 has provided
        us with personal information, we immediately
        delete this from our servers. If you are a parent or guardian
        and you are aware that your child has provided us with
        personal information, please contact us so that
        we will be able to do the necessary actions.
      </p></div>  <p><strong>Changes to This Privacy Policy</strong></p> <p>
        We may update our Privacy Policy from
        time to time. Thus, you are advised to review this page
        periodically for any changes. We will
        notify you of any changes by posting the new Privacy Policy on
        this page.
      </p> <p>This policy is effective as of 2023-01-08</p> <p><strong>Contact Us</strong></p> <p>
        If you have any questions or suggestions about our
        Privacy Policy, do not hesitate to contact us at info@sonarevent.col.
      </p>
    </>
  )
}

function Terms() {
  return (
    <>
      <strong>Gebruiksvoorwaarden ~ SONAR EVENT &amp; EVENTORGANISATOR</strong>
      <p>Momenteel ENKEL in het Nederlands</p>
      <p>Algemene voorwaarden van de overeenkomst tot lastgeving en huur van diensten tussen SONAR EVENT en de EVENTORGANISATOR</p>

      <strong>Artikel 1: Definities en interpretatiebepalingen</strong>
      <p>De betiteling, de onderverdeling en de benaming van clausules uit deze overeenkomst zijn op geen manier bindend. Als er interpretatieve problemen opduiken met betrekking tot een clausule uit deze algemene voorwaarden, dan worden de titels, onderverdelingen en benamingen van clausules geacht niet te bestaan.</p>
      <p>In het geval van eventuele interpretatieve onduidelijkheden, zijn de gemeenrechtelijke interpretatieregels van toepassing (zie art. 1156 e.v. BW). Vanaf de inwerkingtreding van de Wet houdende wijziging van het Wetboek van Economisch Recht met betrekking tot misbruiken van economische afhankelijkheid, onrechtmatige bedingen en oneerlijke marktpraktijken tussen ondernemingen van 4 april 2019 zullen ook de marktpraktijken in rekening worden gebracht voor de interpretatie van de bedingen. Geen van de partijen kan eenzijdig een interpretatie geven aan een onduidelijke bepaling.</p>
      <p>Hiernavolgend zal onder de 'overeenkomst' begrepen worden: De lastgevingsovereenkomst, inclusief haar algemene voorwaarden, gesloten tussen de ORGANISATOR en SONAR EVENT.</p>
      <p>Hiernavolgend zal onder 'ORGANISATOR' begrepen worden: Iedere natuurlijke persoon of rechtspersoon die via het platform SONAR EVENT zijn/haar diensten aan aanbiedt en waarvoor SONAR EVENT instaat voor de verkoop van toegangstickets.</p>
      <p>Hiernavolgend zal onder 'kopers' begrepen worden: De personen die een ticket kopen via https://sonarevent.com voor een evenement dat de ORGANISATOR organiseert en waarvoor SONAR EVENT de ticketverkoop regelt.</p>
      <p>Hiernavolgend zal onder 'bezoekers' begrepen worden: De personen die effectief tot het terrein van het evenement toegang hebben gekregen door de afgifte van het ticket.</p>
      <p>Hiernavolgend zal onder 'het evenement' begrepen worden: De dienst of diensten die door of namens de ORGANISATOR aangeboden worden via het platform van SONAR EVENT en waar SONAR EVENT de ticketverkoop van regelt. Onder deze diensten kunnen niet limitatief begrepen worden: publieke of besloten gebeurtenissen zoals, fuiven, concerten, shows, sportevenementen of voorstellingen.</p>
      <p>Hiernavolgend zal onder 'de start van de verkoop' begrepen worden: Het moment nadat de ORGANISATOR zijn aangemaakt evenement heeft geactiveerd en zichtbaar heeft gemaakt in de store, en waarop SONAR EVENT de verkoop van de toegangskaarten tot het evenement op https://sonarevent.com geopend heeft.</p>
      <p>Hiernavolgend zal onder 'einde van de verkoop' begrepen worden: Het moment dat de ORGANISATOR via de website van SONAR EVENT de online- verkoop stopzet.</p>
      <p>Hiernavolgend zal onder 'mogelijk verkochte kaarten' begrepen worden: Het maximale aantal kaarten dat de ORGANISATOR ter beschikking wil stellen en zal verkopen via de online ticketverkoop van SONAR EVENT.</p>
      <p>Hiernavolgend zal onder 'artiesten' begrepen worden: De personen die door de ORGANISATOR op het evenement, al dan niet tegen vergoeding, uitgenodigd worden met als doel de bezoekers van het evenement te entertainen en wiens namen expliciet als uithangbord voor het evenement gebruikt worden.</p>
      <p>Hiernavolgend zal onder een 'aanzienlijke wijziging van het tijdstip van de start van het evenement' begrepen worden: Een verplaatsing van het evenement met minstens één uur.</p>
      <p>Hiernavolgend zal onder een 'aanzienlijke wijziging in de locatie van het evenement' begrepen worden: Iedere wijziging van het evenement naar een ander complex (gebouw) dan waarin het initieel zou plaatsvinden.</p>
      <p>Hiernavolgend zal onder een 'aanzienlijke wijziging van de artiesten' begrepen worden: Een wijziging van een derde van de artiesten of een wijziging van de hoofdartiest, indien het evenement steunt op de hoofdartiest om bezoekers te lokken.</p>
      <p>Hiernavolgend zal onder 'misbruik' begrepen worden: Ieder gebruik (o.a. via transacties) van het platform van SONAR EVENT waardoor de ORGANISATOR een misdrijf pleegt (o.a. inbreuken op de anti-witwas reglementering) of fiscaal misbruik beoogt te plegen in de zin van art. 334, §1 WIB.</p>
      <p>Hiernavolgend zal onder 'overmacht' begrepen worden: de ontoerekenbare en absolute onmogelijkheid tot uitvoering van de verbintenissen voortvloeiend uit de overeenkomst. Overmacht kan zich voordoen in geval van annulering, verplaatsing op vlak van locatie, bij een aanzienlijke wijziging van het tijdstip van de start van het evenement en een wijziging van het evenement t.a.v. de gegevens uit art. 2.1. Een niet-limitatieve lijst van wat onder overmacht kan worden begrepen is terrorisme(dreiging), oorlog, sabotage, niet aangekondigde of onverwachte stakingen, noodweer, overstromingen, politiële en/of administratieve bevelen. Loutere moeilijkheden in de uitvoering van de overeenkomst, zoals o.a. mindere weersomstandigheden; een niet-aanzienlijke wijziging van de artiesten; het ontbreken van een goede internetverbinding, worden niet beschouwd als overmacht.</p>

      <strong>Artikel 2: De toepasselijkheid van deze algemene voorwaarden en totstandkoming van de overeenkomst</strong>
      <p>Deze algemene voorwaarden zijn uitsluitend van toepassing op de lastgevingsovereenkomst gesloten tussen ORGANISATOR en SONAR EVENT. SONAR EVENT wordt door het sluiten van de overeenkomst lasthebber van de ORGANISATOR en zal dus in naam en voor rekening van de ORGANISATOR overgaan tot de verkoop van tickets voor het door de ORGANISATOR georganiseerde evenement. Vanaf dat moment is de ORGANISATOR ertoe gehouden de verbintenissen, die zijn aangegaan door SONAR EVENT, na te komen.</p>
      <p>SONAR EVENT treedt namens u als ORGANISATOR op als tussenpersoon bij de totstandkoming van eventuele koopovereenkomsten gesloten met de potentiële kopers van een ticket, maar is geenszins partij bij deze tot stand gekomen overeenkomsten.</p>

      <strong>Artikel 3: De informatieverplichtingen voor de start van de verkoop</strong>
      <p>De ORGANISATOR verbindt zich ertoe om alle belangrijke eventgegevens voor de start van de verkoop op de 'maak een evenement'-pagina' aan te brengen bij de aanmaak van een nieuw evenement. Deze van belang zijnde gegevens omvatten minstens: de plaats van het evenement, de datum van het evenement, de prijs voor een toegangsticket tot het evenement, de toegangsvoorwaarden tot het evenement (zoals een eventuele minimumleeftijdsgrens), de eventuele artiesten die komen optreden op het evenement en de officiële naam van het evenement.</p>
      <p>Als er een minimum leeftijdsvereiste is om toegang te krijgen tot het evenement dient de ORGANISATOR dit altijd in de beschrijving van haar evenement te plaatsen op de eventpagina van https://sonarevent.com. SONAR EVENT is in geen geval aansprakelijk voor het feit dat er aan minderjarigen al dan niet toegang werden verleend tot het evenement zonder dat dit meegedeeld was door de ORGANISATOR. De uiteindelijke controle van de leeftijd van de bezoekers ligt bij de ORGANISATOR.</p>
      <p>SONAR EVENT verbindt zich ertoe om de gegevens van art. 2.1 te vermelden op haar site en op de verkochte tickets. SONAR EVENT behoudt echter ook het recht om de vorm van het ticket te bepalen en de verkochte tickets gedeeltelijk op te vullen met informatie naar haar keuze. Een niet-limitatieve opsomming van mogelijke gegevens die SONAR EVENT zelf op het ticket kan zetten, houden in: sponsors, afbeeldingen en andere beschrijvingen van het evenement. SONAR EVENT verbindt zich ertoe om geen extra info op de verkochte tickets te plaatsen die de goede naam of eer van de ORGANISATOR of het evenement kunnen schaden.</p>
      <p>De ORGANISATOR kan daarnaast ook voor de start van de verkoop aan SONAR EVENT meedelen hoeveel tickets er aangeboden zullen worden. Als de ORGANISATOR nalaat een dergelijk maximum mee te delen wordt vermoed, dat SONAR EVENT zonder enige beperkingen in hoeveelheid, tickets mag verkopen tot aan het einde van de verkoop.</p>
      <p>De ORGANISATOR behoudt het recht om de toegangsprijs tot zijn evenement te bepalen. Deze prijs kan door de ORGANISATOR gewijzigd worden tijdens de verkoop. Deze wijziging mag echter geen onrechtmatige prijsdiscriminatie inhouden. Er zal een verschil naar tijdstip of naar type ticket dienen te zijn zoals in het geval van voorverkoop of het aanbieden van een andere formule. Deze wijziging van de prijs heeft geen effect op reeds verkochte tickets, enkel op de tickets die zullen verkocht worden na de prijswijziging. De prijswijziging zal wel effect hebben op de te betalen servicekost.</p>
      <p>De organisator is verplicht een aanzienlijke wijziging in de locatie van het evenement, het tijdstip, van de artiesten of een wijziging van de gegevens vermeld in art. 3.1, ten minste twee kalenderdagen voor de start van de verkoop SONAR EVENT op de hoogte te stellen van deze wijziging.</p>

      <strong>Artikel 4: De informatieverplichtingen na de start van de verkoop</strong>
      <p>De ORGANISATOR heeft de verplichting SONAR EVENT zo snel mogelijk te verwittigen bij annulering van het evenement in geval de ticketverkoop al gestart is.</p>
      <p>De ORGANISATOR verwittigt SONAR EVENT onverwijld in volgende gevallen: bij iedere wijziging op vlak van locatie, tijdstip van de start van het evenement, wijziging van de artiesten en bij wijziging van de gegevens van art. 3.1, indien de ticketverkoop al gestart is.</p>
      <p>De ORGANISATOR dient naar zijn vermogen ook het nodige te doen in de gevallen, zoals bepaald bij artikel 4.3., om de bezoekers te informeren over deze wijzigingen. Hij dient hiertoe samen te werken met SONAR EVENT.</p>
      <p>De ORGANISATOR verwittigt SONAR EVENT zo snel mogelijk, indien zij de beschikbaarheid van de plaatsen die zouden worden verkocht via SONAR EVENT niet kan garanderen. Dit wil zeggen indien de ORGANISATOR die uitvoering van de titels die recht geven op dienstverlening niet kan verzekeren.</p>
      <p>SONAR EVENT zal in geen geval betalingen in cash van potentiële kopers aanvaarden. Alle transacties gebeuren via bankcontact als betaalmethode of bankoverschrijving, dit om misbruiken te voorkomen. Indien SONAR EVENT een verdachte transactie heeft vastgesteld, stelt zij de ORGANISATOR en haar financiële instelling hiervan onverwijld in kennis.</p>

      <strong>Artikel 5: Betaling en prijs</strong>
      <p>De tarieven die op het moment van de totstandkoming van de overeenkomst op de website van SONAR EVENT werden weergegeven, zijn bindend t.a.v. de ORGANISATOR. De tarieven zijn vastgesteld per ticket & inclusief het wettelijk vastgestelde btw-tarief. Het is de ORGANISATOR helemaal vrij om de servicekosten door te rekenen aan de bezoeker of zelf te dragen.</p>
      <p>Na afloop van de ticketverkoop zal door SONAR EVENT zo spoedig mogelijk een verkoopbalans worden opgesteld. Hierin wordt het aantal verkochte tickets per type en de daarbij horende prijs weergegeven, net als de bijhorende servicekosten. Deze zal via een email zo spoedig mogelijk met de ORGANISATOR worden meegedeeld. De 'factuur' die SONAR EVENT aan de organisator doorstuurt is louter informatief. De ORGANISATOR hoeft dus niets meer te betalen.</p>
      <p>Na afloop van de ticketverkoop zal SONAR EVENT binnen de zeven werkdagen de ontvangen gelden doorstorten naar de ORGANISATOR, verminderd met het aan SONAR EVENT verschuldigde bedrag, mits alle facturatiegegevens voorhanden zijn. Indien de nodige facturatiegegevens niet ter beschikking zijn gesteld van SONAR EVENT, kan deze termijn overschreden worden.</p>
      <p>SONAR EVENT staat niet in voor eventuele fouten in de facturatiegegevens die via het beheerderspaneel worden verstrekt door de ORGANISATOR. De betaling aan het ingevoerde rekeningnummer gebeurt steeds bevrijdend in hoofde van SONAR EVENT.</p>
      <p>SONAR EVENT heeft het recht om promoties te organiseren op haar site. De berekening van de door te storten gelden en de servicevergoeding verandert echter niet t.g.v. deze promotie. SONAR EVENT doet dit geheel op eigen risico.</p>

      <strong>Artikel 6: Toegang en controle</strong>
      <p>De ORGANISATOR staat met haar eigen personeelsleden- en medewerkers in voor de validatie van de tickets bij toegang tot het evenement.</p>
      <p>De validatie van de tickets bij toegang tot het evenement gebeurt aan de hand van een gratis scantool, aangeboden door SONAR EVENT.be. De scantool is beschikbaar via het beheerderspaneel en toegankelijk via het account van de ORGANISATOR. Hiervoor is een werkende internetverbinding (via wifi of mobiele data) vereist.</p>
      <p>Als de internetverbinding gebrekkig mocht zijn, al dan niet door het toedoen van de organisator, kunnen zij bij SONAR EVENT een lijst opvragen van de kopers die hun toegangstickets al betaald hebben.</p>

      <strong>Artikel 7: Wijziging of annulering van het evenement</strong>
      <p>In geval van annulering van het evenement na de start van de verkoop, zal SONAR EVENT overleg plegen met de ORGANISATOR over de mogelijkheid tot terugbetaling van de tickets.</p>
      <p>In de gevallen van artikelen 4.2 en 4.3 zal de mogelijkheid gegeven worden aan de kopers om hun ticket terugbetaald te krijgen, indien deze hun ticket niet meer wensen. De aansprakelijkheid bij annulering voor de door SONAR EVENT geleden schade wordt hieronder verder geregeld.</p>
      <p>De ORGANISATOR zal in geval van annulering na de start van de verkoop gehouden zijn de verschuldigde servicekosten (ten belope van het aantal reeds verkochte tickets) en de kosten verbonden aan de restitutie aan SONAR EVENT te betalen. Bij overmacht kan de ORGANISATOR beslissen om de servicekosten en de kosten verbonden aan de restitutie ten laste te leggen van de bezoeker. De gekochte tickets zullen dan slechts gedeeltelijk worden terugbetaald (zie ook artikel 8 van de Algemene voorwaarden van de koopovereenkomst tussen SONAR EVENT en de KOPER van een toegangsticket: wijziging of annulering van het evenement en restitutie).</p>
      <p>In de gevallen van artikelen 4.2, 4.3 en 4.4 zal er een vergoeding moeten worden betaald voor de imagoschade die SONAR EVENT zou kunnen ondervinden. Deze vergoeding houdt 25% van de prijs van het ticket in vermenigvuldigd met het aantal klachten van kopers dat SONAR EVENT ontvangt. Indien een klant meerdere klachten stuurt, zal dit berekend worden als één klacht.</p>

      <strong>Artikel 8: De aansprakelijkheid</strong>
      <p>In het geval van overmacht kan de ORGANISATOR zich bevrijden van zijn verbintenissen maar zal deze wel gehouden zijn tot het betalen van een forfaitaire som van 200 euro.</p>
      <p>De ORGANISATOR is verantwoordelijk voor de beschikbaarheid van de plaatsen die zullen worden verkocht via SONAR EVENT. Wanneer de ORGANISATOR niet in staat is om aan SONAR EVENT de titels die recht geven op een dienstverlening te verschaffen, dan draagt de ORGANISATOR de volledige verantwoordelijkheid ten opzichte van de kopers en ten opzichte van alle andere derden. De ORGANISATOR zal eveneens verantwoordelijk zijn voor de vergoeding ten aanzien van SONAR EVENT. Deze vergoeding komt neer op de servicekosten van het aantal verkochte tickets waarvoor geen titel bestond.</p>
      <p>SONAR EVENT zal in geen geval aansprakelijk worden gesteld voor de annulering van het evenement, voor verplaatsing op vlak van locatie, voor een aanzienlijke wijziging van het tijdstip van de start van het evenement en voor wijziging van de gegevens van art. 3.1.</p>
      <p>SONAR EVENT treedt op als lasthebber en kan dus niet aangesproken worden door de kopers omwille van annulering of wijzigingen aan het evenement. De ORGANISATOR verzekert daarvoor borg te staan.</p>
      <p>SONAR EVENT heeft een middelenverbintenis t.a.v. de ORGANISATOR om de verkoop van de aan hen verleende toegangskaarten te faciliteren via hun website. SONAR EVENT kan in geen geval aansprakelijk gesteld worden voor het feit dat niet al de mogelijk te verkopen kaarten verkocht zijn.</p>
      <p>SONAR EVENT is niet aansprakelijk voor een tijdelijke storing van het online-platform. Naargelang de omstandigheden kan er eventueel een korting verleend worden op de door de ORGANISATOR verschuldigde servicekosten. Indien de technische storing van dien aard is dat de tickets van de bezoekers niet gevalideerd kunnen worden en SONAR EVENT op geen enkele andere manier in de validatie kan voorzien (bv; door het aanbieden van een lijst met kopers die hun tickets al dan niet betaald hebben), zullen geen servicekosten verschuldigd zijn.</p>
      <p>ORGANISATOREN hebben de mogelijkheid via de website van SONAR EVENT evenementen aan te bieden waartoe de kopers gratis toegang verkrijgen. Indien naderhand blijkt dat de ORGANISATOR misbruik heeft gemaakt van het aangeboden platform door bv. op bedrieglijke wijze een toegangsprijs te vragen bij de toegang van het evenement, dan is de ORGANISATOR een schadevergoeding verschuldigd ten belope van de door SONAR EVENT misgelopen omzet. Deze schadevergoeding bedraagt dan de service fee van de op onze website verkochte tickets waarvoor een toegangsprijs werd gevraagd.</p>
      <p>Als de ORGANISATOR op enige andere wijze misbruik maakt van het platform van SONAR EVENT zal SONAR EVENT dit onverwijld melden aan de bevoegde instanties. Naast de geldende wettelijke sancties zal de ORGANISATOR boven op de verschuldigde servicekosten aan SONAR EVENT een forfaitaire schadevergoeding verschuldigd zijn ten belope van €500. Tevens wordt ten gevolge van dit misbruik een schadevergoeding gelegd op de ORGANISATOR ten belope van de imagoschade zoals geregeld bij art. 7.4. De misbruiken vermeld in dit artikel omvatten, niet limitatief; het inkopen van de eigentickets, een fictief persoon tickets laten inkopen, en allerhande andere witwaspraktijken.</p>
      <p>Indien er verdachte transacties worden vastgesteld die kennelijk wijzen op het witwassen van gelden, dan blokkeert SONAR EVENT de verdere verkoop en worden alle toegangstickets die gekocht werden via deze verdachte transactie gedeactiveerd.</p>

      <strong>Artikel 9: Einde van de overeenkomst</strong>
      <p>De lastgevingsovereenkomst neemt een einde op het moment van de sluiting van de online-verkoop van de tickets voor het door de ORGANISATOR georganiseerde evenement. Hierna zal door SONAR EVENT een factuur worden opgesteld en verzonden naar de ORGANISATOR. De ORGANISATOR dient zijn verbintenis tot betaling na te komen.</p>
      <p>De ORGANISATOR behoudt het recht om te allen tijde voor de start van de verkoop de overeenkomst op te zeggen.</p>
      <p>De ORGANISATOR heeft in principe niet het recht deze overeenkomst op te zeggen na de start van de verkoop. Mits betaling van de vergoedingen zoals uiteengezet in de artikelen 7.3, 7.4 en 8.4.</p>

      <strong>Artikel 10: privacy en verwerking van persoonsgegevens</strong>
      <p>SONAR EVENT verwerkt de persoonsgegevens van de kopers in de zin van de Algemene Verordening Gegevensbescherming (AVG, ook wel GDPR genaamd). Dit in opdracht van de ORGANISATOR. SONAR EVENT verwerkt deze gegevens in overeenstemming met de geldende wet- en regelgeving en conform haar eigen privacyverklaring die voorafgaand ter beschikking is gesteld van de contractspartijen en die tevens online beschikbaar is via www.SONAR EVENT.be.</p>
      <p>De ORGANISATOR en SONAR EVENT kunnen de persoonsgegevens van de KOPER gebruiken en met derde partijen delen voor zover dit noodzakelijk is voor de uitvoering van de koopovereenkomst die gesloten wordt tussen de ORGANISATOR en SONAR EVENT (art. 6, 1., b) AVG). Tevens kunnen zij ook bewaard en meegedeeld worden wanneer dit noodzakelijk om de gerechtvaardigde belangen van SONAR EVENT en derden te vrijwaren, indien zwaarder wegen dan deze van de ORGANISATOR (art. 6, 1., f) AVG). Tot slot kunnen bepaalde gegevens ook bewaard worden voor statistische doeleinden. Persoonsgegevens worden nooit voor andere doeleinden gebruikt of gedeeld met derden.</p>
      <p>De persoonsgegevens die SONAR EVENT verwerkt om de ORGANISATOR te kunnen identificeren zijn de volgende: gebruikersnaam, e-mailadres, voornaam, achternaam, naam van de organisatie, adres, btw-nummer en rekeningnummer.</p>
      <p>Al de persoonsgegevens van de ORGANISATOR worden op een beveiligde wijze, via een beveiligde verbinding (SSL), opgeslagen op een 'cloud' waar enkel SONAR EVENT toegang tot heeft. De persoonsgegevens van de ORGANISATOR worden nooit met de potentiële kopers gedeeld. Overheidsinstellingen of andere bevoegde instanties zullen toegang krijgen tot de persoonsgegevens van de ORGANISATOR daar waar dit wettelijk verplicht is.</p>
      <p>De ORGANISATOR heeft recht op informatie, inzage, op rectificatie van onjuiste gegevens te vragen, recht op vergetelheid, recht op beperking van de verwerking, recht op overdraagbaarheid, recht van bezwaar en het recht tegen geautomatiseerde besluitvorming (waaronder 'profiling'). De rechten van de ORGANISATOR worden op de door de Algemene Verordening Gegevensbescherming (art. 12 t.e.m. 23 AVG, ook wel GDPR genaamd) bepaalde wijze geregeld.</p>
      <p>De gegevens van de rechtspersonen kennen geen bescherming.</p>
      <p>Eventuele vragen & klachten met betrekking tot de verwerking van de persoonsgegevens worden zoals de AVG (GDPR) vereist schriftelijk (bv; via E-mail) behandeld.</p>

      <strong>Artikel 11: intellectuele eigendomsrechten</strong>
      <p>SONAR EVENT is gerechtigd afbeeldingen en ander grafisch materiaal van het evenement met het oog op publiciteit of reclame weer te geven op haar online platform. Hierdoor wordt geen inbreuk gemaakt op de intellectuele eigendomsrechten in hoofde van de ORGANISATOR.</p>
      <p>De ORGANISATOR en SONAR EVENT zijn gerechtigd om (delen) van het evenement en de bezoekers op beeld- en geluidsdragers te doen vastleggen en dit materiaal na afloop van het evenement voor commerciële doeleinden te (doen) verveelvoudigen en (doen) openbaar maken via haar platform.</p>

      <strong>Artikel 12: exclusiviteit</strong>
      <p>De ORGANISATOR verbindt zich er niet toe om op vlak van haar ticketverkoop exclusief samen te werken met SONAR EVENT. Een combinatie van online tickets met fysieke tickets is toegestaan, alsook een gelijktijdige samenwerking met een andere provider van online ticket services.</p>

      <strong>Artikel 13: toepasselijk recht en bevoegdheid</strong>
      <p>Het contract is exclusief onderworpen aan het Belgisch recht; toepassing van het VN-Verdrag inzake internationale koopovereenkomsten betreffende roerende zaken is uitgesloten.</p>
      <p>Deze overeenkomst is opgesteld naar Belgisch recht. Alle geschillen ontstaande uit de interpretatie of uit de uitvoering van de overeenkomst, zullen onderworpen worden aan de exclusieve jurisdictie van de hoven en rechtbanken van het gerechtelijke arrondissement Antwerpen, afdeling Antwerpen.</p>

      <p>Contact ?</p>
      <p>SONAR EVENT is een onderneming die ressorteert onder de BV SONAR EVENT & STREET OF MARKETING</p>
      <p>° Ondernemingsnummer: 0795.336.256</p>
      <p>° Btw-nummer: BE0795.336.256</p>
      <p>° Maatschappelijke zetel: De Keyserlei 58-60 bus 19, 2018 Antwerpen</p>

      <p>Voor vragen of klachten kunnen KOPERS contact opnemen met SONAR EVENT</p>
      <p>° Per mail: info@sonarevent.com</p>
      <p>Algemene website: https://sonarevent.com</p>

      <strong>Gebruiksvoorwaarden</strong> | (SONAR EVENT &amp; KOPER)
      <p>Momenteel ENKEL in het Nederlands</p>
      <p>Algemene voorwaarden van de koopovereenkomst tussen SONAR EVENT en de KOPER van een toegangsticket</p>

      <strong>Artikel 1: Definities en interpretatiebepalingen</strong>
      <p>De betiteling, de onderverdeling en de benaming van clausules uit deze overeenkomst zijn op geen manier bindend. Als er interpretatieve problemen opduiken met betrekking tot een clausule uit deze algemene voorwaarden, dan worden de titels, onderverdelingen en benamingen van clausules geacht niet te bestaan.</p>
      <p>In het geval van eventuele interpretatieve onduidelijkheden, zijn de gemeenrechtelijke interpretatieregels in uw voordeel als KOPER van toepassing (art. 1156 e.v. BW).</p>
      <p>Hiernavolgend zal onder de 'overeenkomst' begrepen worden: De koopovereenkomst, inclusief haar algemene voorwaarden, gesloten tussen de organisator en de desbetreffende koper die bij tussenkomst van het SONAR EVENT-platform tot stand zijn gekomen.</p>
      <p>Hiernavolgend zal onder 'organisator' begrepen worden: Iedere natuurlijke persoon of rechtspersoon die via het platform SONAR EVENT zijn/haar diensten aan u aanbiedt en waarvoor SONAR EVENT instaat voor de verkoop van toegangstickets.</p>
      <p>Hiernavolgend zal onder 'Kopers' begrepen worden: De personen die een ticket kopen via https://sonarevent.com voor een evenement dat de ORGANISATOR organiseert en waarvoor SONAR EVENT de ticketverkoop regelt.</p>
      <p>Hiernavolgend zal onder 'bezoekers' begrepen worden: De personen die effectief tot het terrein van het evenement toegang hebben gekregen door de afgifte van het ticket.</p>
      <p>Hiernavolgend zal onder 'bestelling' begrepen worden: Een bestelling van één of meer tickets met gebruikmaking van het SONAR EVENT-platform.</p>
      <p>Hiernavolgend zal onder 'tickets' begrepen worden: Het toegangsbewijs die de bezoeker toegang tot het evenement verschaft en het onderwerp van de koopovereenkomst vormt.</p>
      <p>Hiernavolgend zal onder 'levering' begrepen worden: De wijze waarop het ticket ter beschikking wordt gesteld aan de KOPER. Dit gebeurt steeds op digitale wijze.</p>
      <p>Hiernavolgend zal onder 'het evenement' begrepen worden: De dienst of diensten die door of namens de organisator aangeboden worden via het platform van SONAR EVENT en waar SONAR EVENT de ticketverkoop van regelt. Onder deze diensten kunnen niet limitatief begrepen worden: publieke of besloten gebeurtenissen zoals, fuiven, concerten, shows, sportevenementen of voorstellingen.</p>
      <p>Hiernavolgend zal onder 'de start van de verkoop' begrepen worden: Het moment waarop u als potentiële KOPER tickets via https://sonarevent.com voor een bepaald evenement kunt bestellen.</p>
      <p>Hiernavolgend zal onder 'artiesten' begrepen worden: De personen die door de organisator op het evenement, al dan niet tegen vergoeding, uitgenodigd worden met als doel de bezoekers van het evenement te entertainen en wiens namen expliciet als uithangbord voor het evenement gebruikt worden.</p>
      <p>Hiernavolgend zal onder een 'aanzienlijke wijziging van het tijdstip van de start van het evenement' begrepen worden: Een verplaatsing van het evenement met minstens één uur.</p>
      <p>Hiernavolgend zal onder een 'aanzienlijke wijziging in de locatie van het evenement' begrepen worden: Iedere wijziging van het evenement naar een ander complex dan waarin het initieel zou plaatsvinden.</p>
      <p>Hiernavolgend zal onder een 'aanzienlijke wijziging van de artiesten' begrepen worden: Een wijziging van een derde van de artiesten of een wijziging van de hoofdartiest, indien het evenement steunt op de hoofdartiest om bezoekers te lokken.</p>
      <p>Hiernavolgend zal onder een 'onredelijke vertraging' begrepen worden: Maximaal 14 kalenderdagen na de ontvangst van de tickets of de annulering/wijziging van het evenement, behoudens overmacht in hoofde van de KOPER.</p>
      <p>Hiernavolgend zal onder 'overmacht' begrepen worden: de ontoerekenbare en absolute onmogelijkheid tot uitvoering van de verbintenissen voortvloeiend uit de overeenkomst. Overmacht kan zich voordoen in geval van annulering, verplaatsing op vlak van locatie, bij een aanzienlijke wijziging van het tijdstip van de start van het evenement. Een niet-limitatieve lijst van wat onder overmacht kan worden begrepen is terrorisme(dreiging), oorlog, sabotage, niet aangekondigde of onverwachte stakingen, noodweer, overstromingen, politiële en/of administratieve bevelen. Loutere moeilijkheden in de uitvoering van de overeenkomst, zoals o.a. mindere weersomstandigheden; een niet-aanzienlijke wijziging van de artiesten; het ontbreken van een goede internetverbinding, worden niet beschouwd als overmacht.</p>

      <strong>Artikel 2: Toepasselijkheid van deze algemene voorwaarden</strong>
      <p>Deze algemene voorwaarden zijn uitsluitend van toepassing op de onderhavige (ver)koop tussen organisator en KOPER. Door de bestelling af te ronden gaat de KOPER ten eerste een koopovereenkomst aan met de organisator en ten tweede gaat de KOPER onverkort akkoord met deze algemene voorwaarden, het privacy-beleid en eventuele aanvullende (toetredings-)voorwaarden van de organisator en/of locatie nadien. Het staat de organisator vrij om deze aanvullende toetredingsvoorwaarden pas kenbaar te maken op locatie voor zover deze voorwaarden in het belang zijn voor de openbare orde of veiligheid van bezoekers, artiesten en/of medewerkers ter plaatse.</p>
      <p>SONAR EVENT treedt namens de organisator op als tussenpersoon en is expliciet geen partij bij de koopovereenkomst die via het SONAR EVENT-systeem met de KOPER tot stand komt.</p>

      <strong>Artikel 3: Totstandkoming van de koop en uitsluiting van het herroepingsrecht</strong>
      <p>De KOPER gaat door het plaatsen van een bestelling onvoorwaardelijk akkoord met een betalingsverplichting aan SONAR EVENT, die als tussenpersoon van de verkopende partij optreedt, gelden collecteert en uitbetaalt aan de organisator.</p>
      <p>De overeenkomst tussen KOPER en organisator komt definitief tot stand van zodra SONAR EVENT de (autorisatie van) de betaling door de KOPER heeft ontvangen en verwerkt. Zie hiervoor artikel 3.4. van de overeenkomst.</p>
      <p>De te betalen prijs van het toegangsbewijs is inclusief het wettelijke btw-tarief, zoals vastgesteld op het moment van de verkoop.</p>
      <p>Het herroepingsrecht is op grond van artikel VI.53, 12° van het Wetboek Economisch Recht uitdrukkelijk uitgesloten. Dit betekent dat de KOPER geen bedenktijd van 14 dagen toekomt om de bestelling kosteloos te annuleren.</p>
      <p>De KOPER dient steeds rekening te houden met zijn onderzoek plicht, met name het controleren op de juistheid van de ontvangen tickets. Als er sprake is van een foutieve (non-conforme) levering, dan ontvangt de KOPER op eerste schriftelijk verzoek en zonder bijkomende kosten de aangepaste tickets, op voorwaarde dat de KOPER de reeds geleverde producten aan de organisator terugbezorgt en fouten in het product aan de organisator meldt.</p>

      <strong>Artikel 4: Betaling</strong>
      <p>Alle bestellingen worden betaald in Euro.</p>
      <p>Bij het plaatsen van een bestelling, verbindt de KOPER zich tot de betaling van het corresponderende totaalbedrag aan SONAR EVENT, die deze betalingen namens de organisator inzamelt. De KOPER ontvangt vervolgens een bevestiging van bestelling zodra de transactie correct heeft plaatsgevonden. De bevestiging van bestelling dient opzichzelfstaand uitdrukkelijk niet als een voltooide bestelling. Er is dus pas sprake van een voltooide bestelling of van een koop van zodra de KOPER de tickets heeft ontvangen. Tot dit moment van ontvangst kan de KOPER geen rechten ontlenen en/of levering afdwingen.</p>
      <p>Als er een volledige betaling heeft plaatsgevonden, maar de levering van het product niet mogelijk blijkt te zijn, heeft de KOPER een recht tot terugbetaling van het volledige aankoopbedrag.</p>
      <p>De KOPER verbindt zich ertoe om bij de bestelling van tickets diens correcte en volledige persoonsgegevens in het desbetreffende identificatieformulier op het SONAR EVENT-platform in te vullen. SONAR EVENT behoudt zich het recht voor om bij een vermoeden van fraude, de bestelling te weigeren of te annuleren.</p>

      <strong>Artikel 5: Levering</strong>
      <p>De levering vindt plaats op digitale wijze: de KOPER ontvangt via het opgegeven e-mailadres:</p>
      <p>Een bevestiging van de betaling</p>
      <p>Een bevestiging van de bestelling</p>
      <p>Eén of meer toegangsbewijzen - tickets</p>

      <strong>Artikel 6: Toegang en controle</strong>
      <p>De organisator kan de houder van een ticket (de met de bezoeker gelijkgestelde personen) verplichten aan bepaalde leeftijdsvoorwaarden te voldoen. Deze leeftijdsvoorwaarde wordt op het SONAR EVENT-platform steeds duidelijk vermeld vooraleer de KOPER een bestelling kan plaatsen.</p>

      <strong>Artikel 7: Aansprakelijkheid</strong>
      <p>De organisator van het evenement geldt als de verkopende partij. SONAR EVENT treedt slechts op als tussenpersoon van de organisator. Dit betekent dat de producten in opdracht en voor rekening van de organisator via het online platform van SONAR EVENT worden aangeboden. SONAR EVENT is als zodanig geen partij bij de overeenkomst die tot stand komt en is noch verantwoordelijk, noch aansprakelijk voor de (artistieke kwaliteit van) geleverde producten en/of diensten of de wijze waarop de organisator uitvoering geeft aan zijn verplichtingen jegens de KOPERS en bezoekers.</p>
      <p>SONAR EVENT is niet aansprakelijk voor het verlies of de beschadiging van tickets door de KOPER voor zover SONAR EVENT hiervoor niet verantwoordelijk kan worden gehouden. Vanaf het moment dat het ticket aan de klant op een duurzame drager ter beschikking is gesteld, rust het risico van verlies, diefstal, beschadiging of misbruik op de klant.</p>

      <strong>Artikel 8: Wijziging of annulering van het evenement en restitutie</strong>
      <p>Gekochte tickets worden in beginsel niet teruggenomen door SONAR EVENT, noch door de organisator en kunnen niet worden geruild. Evenmin vindt restitutie van het aankoopbedrag of reserveringskosten plaats, tenzij in dit artikel anders wordt bepaald.</p>
      <p>Door omstandigheden kan de organisator besluiten het evenement te annuleren of te wijzigen.</p>
      <p>In het geval dat het evenement geannuleerd wordt, beslist de organisator of en in welke mate de gekochte tickets zullen worden terugbetaald. Doorgaans zal het volledige aankoopbedrag aan de KOPER worden terugbetaald. Ook een gedeeltelijke terugbetaling is mogelijk, als de annulering het gevolg is van overmacht.</p>
      <p>In het geval dat het event aanzienlijk wordt gewijzigd, zal SONAR EVENT de KOPER hierover verwittigen en vermelden dat er een terugbetalingsmogelijkheid wordt ingesteld.</p>
      <p>Wanneer de organisator een nieuwe toegangsvoorwaarde tot het evenement doorvoert na de bestelling van de tickets, zal SONAR EVENT in samenwerking met de organisator overgaan tot:</p>
      <p>° het verwittigen van de KOPER, indien SONAR EVENT minstens 48 uur voor de aanvang van het evenement kennis had van de wijziging.</p>
      <p>° het terugbetalen van de aankoopprijs van de tickets die verkocht werden aan KOPERS die redelijkerwijze niet kunnen voldoen aan deze nieuwe voorwaarde(n). De KOPER dient SONAR EVENT hiervan binnen de veertien kalenderdagen na de verwittiging op de hoogte brengen met opgave van redenen.</p>

      <strong>Artikel 9 - Privacy en verwerking van persoonsgegevens</strong>
      <p>SONAR EVENT behoudt zich het recht voor om persoonsgegevens van de KOPER te gebruiken en met derde partijen te delen voor zover dit noodzakelijk is voor de uitvoering van de koopovereenkomst. Persoonsgegevens worden ook voor statistische doeleinden gebruikt.  Voor verdere informatie, zie het privacy beleid op https://sonarevent.com .</p>

      <strong>Artikel 10: Intellectuele eigendomsrechten</strong>
      <p>De bezoeker is niet gerechtigd (delen van) het programmaboekje, posters, flyers en andere door de organisator of SONAR EVENT geproduceerd materiaal over te nemen en (commercieel) te verspreiden dan wel op enige andere wijze inbreuk te maken op de rechten van intellectuele eigendom, zoals onder meer het merkenrecht en auteursrechten toebehorend aan de organisator of SONAR EVENT.</p>
      <p>SONAR EVENT is gerechtigd om na afloop van het evenement beeld- en geluidsdragers waarop de bezoekers staan voor commerciële doeleinden te (doen) verveelvoudigen en (doen) openbaar maken via haar platform. Door aanwezig te zijn op het evenement heeft de bezoeker beperkt afstand gedaan van zijn portretrecht.</p>

      <strong>Artikel 11: Plaats van uitvoering en toepasselijk recht</strong>
      <p>Het contract is exclusief onderworpen aan het Belgisch recht; toepassing van het VN-Verdrag over internationale koopovereenkomsten betreffende roerende zaken is uitgesloten.</p>
      <p>Deze overeenkomst is opgesteld naar Belgisch recht. Alle geschillen ontstaande uit de interpretatie of uit de uitvoering van de overeenkomst, zullen onderworpen worden aan de exclusieve jurisdictie van de hoven en rechtbanken van het gerechtelijke arrondissement Antwerpen, afdeling Antwerpen.</p>

      <p>Contact ?</p>
      <p>SONAR EVENT is een onderneming die ressorteert onder de BV SONAR EVENT & STREET OF MARKETING</p>
      <p>° Ondernemingsnummer: 0795.336.256</p>
      <p>° Btw-nummer: BE0795.336.256</p>
      <p>° Maatschappelijke zetel: De Keyserlei 58-60 bus 19, 2018 Antwerpen</p>

      <p>Voor vragen of klachten kunnen KOPERS contact opnemen met SONAR EVENT</p>
      <p>° Per mail: info@sonarevent.com</p>
      <p>Algemene website: https://sonarevent.com</p>

      <strong>Terms &amp; Conditions</strong> | (SONAR EVENT SCANNER APP)
      <p>Currently ONLY in English</p>
      <p>By downloading or using the app, these terms will automatically apply to you - you should make sure therefore that you read them carefully before using the app. You're not allowed to copy or modify the app, any part of the app, or our trademarks in any way. You're not allowed to attempt to extract the source code of the app, and you also shouldn't try to translate the app into other languages or make derivative versions. The app itself, and all the trademarks, copyright, database rights, and other intellectual property rights related to it, still belong to Sonar Events.</p>
      <p> Sonar Events is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you're paying for.</p>
      <p> The SonarEvents Scanner app stores and processes personal data that you have provided to us, to provide our Service. It's your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone's security features and it could mean that the SonarEvents Scanner app won't work properly or at all.</p>
      <div>
        <p> The app does use third-party services that declare their Terms and Conditions.</p>
        <p>     Link to Terms and Conditions of third-party service     providers used by the app </p>
        <ul>
          <li>
            <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a>
          </li>
          <li>
            <a href="https://unity3d.com/legal/terms-of-service" target="_blank" rel="noopener noreferrer">Unity</a>
          </li>
        </ul>
      </div>
      <p> You should be aware that there are certain things that Sonar Events will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi or provided by your mobile network provider, but Sonar Events cannot take responsibility for the app not working at full functionality if you don't have access to Wi-Fi, and you don't have any of your data allowance left.</p>
      <p> If you're using the app outside of an area with Wi-Fi, you should remember that the terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third-party charges. In using the app, you're accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you're using the app, please be aware that we assume that you have received permission from the bill payer for using the app.</p>
      <p> Along the same lines, Sonar Events cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged - if it runs out of battery and you can't turn it on to avail the Service, Sonar Events cannot accept responsibility.</p>
      <p> With respect to Sonar Events's responsibility for your use of the app, when you're using the app, it's important to bear in mind that although we endeavor to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. Sonar Events accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.</p>
      <p> At some point, we may wish to update the app. The app is currently available on Android - the requirements for the system (and for any additional systems we decide to extend the availability of the app to) may change, and you'll need to download the updates if you want to keep using the app. Sonar Events does not promise that it will always update the app so that it is relevant to you and/or works with the Android version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.</p>
      <strong>Changes to This Terms and Conditions</strong>
      <p> We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page.</p>
      <p> These terms and conditions are effective as of 2023-01-08</p>
      <strong>Contact Us</strong>
      <p> If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at info@sonarevent.com.</p>
    </>
  )
}

function Sla() {
  return (
    <>
      <p>Sonar Event & Vvoids try to set an SLA of 99.405%</p>
    </>
  )
}

function Security() {
  return (
    <>
      <p>Vvoids is the only service which has access to user and business data.</p>
      <p>Vvoids & Sonar Event are not accountable for any vulnerabilties of used services in their own services.</p>
      <p>User and business data download, recovery and deletion can be requested through <u><a href="mailto:info@sonarevent.com">info@sonarevent.com</a></u> when required.</p>
    </>
  )
}