import { Badge, Layout, Modal, Spin } from "antd";
import ComponentState from "./components/states";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import ComponentHeader from "./components/header";
import ComponentFooter from "./components/footer";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { HomeOutlined, FormOutlined, PictureOutlined, ShoppingCartOutlined, TeamOutlined, LockOutlined, QrcodeOutlined, TagOutlined, ApartmentOutlined, LockTwoTone, CloudUploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Defaults, QueryCase, QueryId, Sections, StorageId, ToastId, WebRoute } from "../handlers/enums";
import SideMenu from "./components/sideMenu";
import { Navigate } from "react-router-dom";
import { useAtom } from "jotai";
import { BusinessEventProgress, BusinessId, EventId, UserId } from "../handlers/globals";
import { SectionBusinessEventGeneral } from "./sections/SectionBusinessEventGeneral";
import { SectionBusinessEventTickets } from "./sections/SectionBusinessEventTickets";
import { GetData, MutateData } from "../handlers/axios";
import { SectionBusinessEventPictures } from "./sections/SectionBusinessEventPictures";
import { SectionBusinessEventPartners } from "./sections/SectionBusinessEventPartners";
import { SectionBusinessEventScanners } from "./sections/SectionBusinessEventScanners";
import SectionBusinessEventPodiaArtists from "./sections/SectionBusinessEventPodiaArtists";
import { SectionBusinessEventSales } from "./sections/SectionBusinessEventSales";
import { notifyError, notifySuccess } from "../handlers/extensions";

export default function BusinessEventOverview() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  useEffect(() => { sessionStorage.setItem(StorageId.SearchCode, Defaults.StringEmpty); }, []);
  const { t } = useTranslation();

  const [userId,] = useAtom(UserId);
  const [businessId,] = useAtom(BusinessId);
  const [eventId,] = useAtom(EventId);

  const [businessEventProgress, setBusinessEventProgress] = useAtom(BusinessEventProgress);
  const selectBusinessEventProgress = GetData(QueryId.SelectBusinessEventProgress, { BusinessId: businessId, UserId: userId, EventId: eventId });

  const [menuItem, setMenuItem] = useState(Defaults.StringEmpty);

  useEffect(() => { setMenuItem(businessEventProgress.isEventEnded ? Sections.BusinessEventTicketHolders : Sections.BusinessEventGeneral); }, [businessEventProgress.isEventEnded]);
  useEffect(() => { if (selectBusinessEventProgress.data) { setBusinessEventProgress(selectBusinessEventProgress.data); } }, [setBusinessEventProgress, selectBusinessEventProgress.data]);

  return (
    <>
      {userId && businessId && eventId ? null : <Navigate replace to={WebRoute.BusinessSignin} />}
      <Layout className="!bg-transparent">
        <div id={Sections.Top}></div>
        <ComponentHeader />
        <Layout hasSider className={`hidden md:flex !bg-transparent pr-8 ${menuItem === Sections.BusinessEventTicketHolders ? Defaults.StringEmpty : "justify-between lg:justify-center"}`}>
          <Sider className="!bg-transparent !min-w-60 border-r pt-2">
            <SideMenuComponent menuItem={menuItem} setMenuItem={setMenuItem} />
          </Sider>
          <Content className={`min-h-[80vh] pl-8 py-8 ${menuItem === Sections.BusinessEventTicketHolders ? Defaults.StringEmpty : "max-w-3xl"}`}>
            {selectBusinessEventProgress.isLoading ? <Spin /> : selectBusinessEventProgress.isFalse ? <ComponentState error /> : !businessEventProgress ? <Spin /> :
              <SectionContent refetchProgress={selectBusinessEventProgress.refetch} section={menuItem} setMenuItem={setMenuItem} />
            }
          </Content>
        </Layout>
        <Layout className="md:hidden !bg-transparent px-8">
          <Content className="min-h-[80vh] pt-8">
            <p>{t("required_desktop")}</p>
          </Content>
        </Layout>
        <ComponentFooter />
      </Layout>
    </>
  );
}

function SideMenuComponent(props) {
  const [businessEventProgress,] = useAtom(BusinessEventProgress);

  return (<SideMenu initialValue={props.menuItem} setMenu={props.setMenuItem} items={[
    { key: Sections.BusinessOverview, icon: <HomeOutlined /> },
    { key: Sections.BusinessEventGeneral, icon: businessEventProgress.isValidGeneral ? <FormOutlined /> : <Badge size="small" count={Defaults.Space} showZero><FormOutlined /></Badge>, disabled: businessEventProgress.isEventEnded },
    { key: Sections.BusinessEventImages, icon: businessEventProgress.isValidPictures ? <PictureOutlined /> : <Badge size="small" count={Defaults.Space} showZero><PictureOutlined /></Badge>, disabled: !businessEventProgress.isValidGeneral || businessEventProgress.isEventEnded },
    { key: Sections.BusinessEventTickets, icon: <ShoppingCartOutlined />, disabled: !businessEventProgress.isValidGeneral || (!businessEventProgress.hasTickets && businessEventProgress.isPublished) || businessEventProgress.isEventEnded },
    { key: Sections.BusinessEventPartners, icon: <TeamOutlined />, disabled: !businessEventProgress.isValidGeneral || !businessEventProgress.hasTickets || businessEventProgress.isEventEnded },
    { key: Sections.BusinessEventPodiaAndArtists, icon: businessEventProgress.isValidStages ? <ApartmentOutlined /> : <Badge size="small" count={Defaults.Space} showZero><ApartmentOutlined /></Badge>, disabled: !businessEventProgress.isValidGeneral || businessEventProgress.isEventEnded },
    { key: Sections.BusinessEventTicketHolders, icon: <TagOutlined />, disabled: !businessEventProgress.isPublished || !businessEventProgress.hasTickets },
    { key: Sections.BusinessEventScanners, icon: businessEventProgress.isValidScanners ? <QrcodeOutlined /> : <Badge size="small" count={Defaults.Space} showZero><QrcodeOutlined /></Badge>, disabled: !businessEventProgress.isValidGeneral || !businessEventProgress.hasTickets || businessEventProgress.isEventEnded },
    { key: Sections.BusinessEventPublish, icon: businessEventProgress.isValidStages && businessEventProgress.isValidScanners && businessEventProgress.isValidPictures && businessEventProgress.isValidGeneral && !businessEventProgress.isPublished ? <Badge size="small" count={Defaults.Space} showZero><CloudUploadOutlined /></Badge> : businessEventProgress.isEventEnded ? <LockOutlined /> : <LockTwoTone twoToneColor="red" />, disabled: businessEventProgress.isEventEnded }
  ]} />);
}

function SectionContent(props) {
  const [eventId,] = useAtom(EventId);
  const { t } = useTranslation();
  const [businessEventProgress,] = useAtom(BusinessEventProgress);

  const formAction = MutateData(QueryId.ValidateEvent,
    () => { }, [
    { case: QueryCase.Success, onCase: () => { props.setMenuItem(businessEventProgress.isValidStages && businessEventProgress.isValidScanners && businessEventProgress.isValidPictures && businessEventProgress.isValidGeneral ? Sections.BusinessOverview : Sections.BusinessEventGeneral); notifySuccess("toast_mutate_success_validate_event"); } },
    { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
  ]);

  const onFinish = (properties) => {
    properties.form_field_eventid = eventId;

    formAction.mutate(properties);
  };

  const handleCancel = () => {
    props.setMenuItem(Sections.BusinessEventGeneral);
  };

  switch (props.section) {
    case Sections.BusinessOverview:
      return <Navigate replace to={WebRoute.BusinessOverview} />;
    case Sections.BusinessEventGeneral:
      return <SectionBusinessEventGeneral section={props.section} />;
    case Sections.BusinessEventImages:
      return <SectionBusinessEventPictures section={props.section} />;
    case Sections.BusinessEventTickets:
      return <SectionBusinessEventTickets section={props.section} />;
    case Sections.BusinessEventPartners:
      return <SectionBusinessEventPartners section={props.section} />;
    case Sections.BusinessEventPodiaAndArtists:
      return <SectionBusinessEventPodiaArtists section={props.section} />;
    case Sections.BusinessEventTicketHolders:
      return <SectionBusinessEventSales section={props.section} />;
    case Sections.BusinessEventScanners:
      return <SectionBusinessEventScanners section={props.section} />;
    case Sections.BusinessEventPublish:
      return <Modal title={t(props.section)} open={true} okText={t("confirm")} cancelText={t("cancel")} okButtonProps={businessEventProgress.isValidStages && businessEventProgress.isValidScanners && businessEventProgress.isValidPictures && businessEventProgress.isValidGeneral && !businessEventProgress.isPublished ? null : { className: "hidden" }} onOk={() => onFinish({})} onCancel={() => handleCancel()}>
        <p>{(businessEventProgress.isValidStages && businessEventProgress.isValidScanners && businessEventProgress.isValidPictures && businessEventProgress.isValidGeneral ? businessEventProgress.isPublished ? t("description_publish_done") : parse(t("description_publish")) : t("description_publish_requirements"))}</p>
      </Modal>;
    default:
      return <p className="font-semibold text-lg mb-2">{t("select_menu_item")}</p>
  }
}