import { formTagsNone, notifyError, notifySuccess } from "../../handlers/extensions";
import { Color, QueryCase, QueryId, ToastId, WebRoute } from "../../handlers/enums";
import { Button, Card, Form, Image, Spin, Tag, Tooltip, Watermark } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { ComponentFormInput } from "../components/forms/input";
import { useState } from "react";
import { GetData, MutateData } from "../../handlers/axios";
import { useAtom } from "jotai";
import { BusinessId, EventId, IsBusinessOwner, UserId } from "../../handlers/globals";
import { useNavigate } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import BackupTilePicture from "../../assets/sprites/BackupTilePicture.jpg";
import moment from "moment";

export function SectionBusinessEvents(props) {
  const [userId,] = useAtom(UserId);
  const [businessId,] = useAtom(BusinessId);
  const [isBusinessOwner,] = useAtom(IsBusinessOwner);
  const [, setEventId] = useAtom(EventId);
  const { t } = useTranslation();

  const response = GetData(QueryId.SelectBusinessEvents, { BusinessId: businessId, UserId: userId });

  const navigate = useNavigate();

  const formAction = MutateData(QueryId.InsertBusinessEvent,
    () => { setFreezeActions(false) }, [
    { case: QueryCase.Success, onCase: (data) => { if (!data?.data) { notifyError(ToastId.ActionError); return; } setEventId(data.data.eventId); navigate(WebRoute.BusinessEventOverview); notifySuccess(ToastId.ActionSuccessCreate); } },
    { case: QueryCase.Invalid, onCase: () => { notifyError("toast_mutate_warning_maximum_events") } },
    { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
  ]);

  const [freezeActions, setFreezeActions] = useState(false);

  const [formInsert] = Form.useForm();

  const onFinish = (properties) => {
    properties.form_field_userid = userId;
    properties.form_field_businessid = businessId;

    setFreezeActions(true);
    formAction.mutate(properties);
  };

  return (response.isLoading ? <Spin /> : response.isFalse ? <ComponentState error /> : <>
    <p className="font-semibold text-lg mb-2">{t(props.section)}</p>
    <div className="flex gap-5">
      {!isBusinessOwner ? <></> :
        <Card cover={
          <Watermark font={{ color: "#ffffff" }} gap={[0, 250]} rotate={0} offset={[-220, 50]} content={t("preview_tile_picture")}>
            <Image preview={false} height={100} width={240} rootClassName="w-full" src={BackupTilePicture} />
          </Watermark>
        } className="event-tile relative w-[240px] depth-shadow border-none h-min">
          <Form
            form={formInsert}
            requiredMark={formTagsNone}
            colon={false}
            onFinish={onFinish}
            className="mt-2"
          >
            <ComponentFormInput label field={"form_field_eventname"} placeholder={"placeholder_eventname"} feedback clear required />
            <Form.Item>
              <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { formInsert.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_confirm_insert_event")}</Button>
            </Form.Item>
          </Form>
        </Card>
      }
      <div className="flex gap-5 flex-wrap">
        {response.data?.map((businessEvent) => {
          const state = !businessEvent.isValidated
            ? Color.Yellow
            : !businessEvent.isPublished
              ? Color.Blue
              : moment().add(-1, "d").isBefore(businessEvent.eventEndDate)
                ? Color.Green
                : Color.Red

          return <Card key={businessEvent.id} onClick={() => { setEventId(businessEvent.id); navigate(WebRoute.BusinessEventOverview); }} cover={
            businessEvent.tilePicture
              ? <Image src={businessEvent.tilePicture} rootClassName="w-full" preview={false} />
              : <Watermark font={{ color: "#ffffff" }} gap={[0, 250]} rotate={0} offset={[-220, 50]} content={t("preview_tile_picture")}>
                <Image preview={false} height={100} rootClassName="w-full" width={240} src={BackupTilePicture} />
              </Watermark>
          } className="event-tile relative w-[240px] depth-shadow cursor-pointer border-transparent border-[1px] border-solid hover:text-sonar-blue hover-custom-button-sonar-blue">
            <div className="flex justify-between mt-1">
              <p className="font-semibold text-lg">{businessEvent.name}</p>
              <Button disabled={freezeActions} loading={freezeActions} className="bg-sonar-yellow ml-2" shape="circle" icon={<EditOutlined />} />
            </div>
            <div className="absolute top-2 right-1">
              <Tooltip title={state === Color.Green
                ? t("tooltip_live")
                : state === Color.Yellow
                  ? t("tooltip_incomplete")
                  : state === Color.Red
                    ? t("tooltip_ended")
                    : t("tooltip_unpublished")
              }><Tag className="!text-sonar-black font-semibold" color={state}>{state === Color.Green
                ? t("tag_live")
                : state === Color.Yellow
                  ? t("tag_incomplete")
                  : state === Color.Red
                    ? t("tag_ended")
                    : t("tag_unpublished")
              }</Tag></Tooltip>
            </div>
          </Card>
        })}
      </div>
    </div>
  </>);
}