import { Button, DatePicker, Form, Layout, Select, Spin, Switch } from "antd";
import ComponentState from "../components/states";
import { Content } from "antd/es/layout/layout";
import ComponentHeader from "../components/header";
import ComponentFooter from "../components/footer";
import React, { useEffect, useState } from "react";
import { Currency, Defaults, QueryCase, QueryId, ToastId, WebRoute } from "../../handlers/enums";
import { CheckOutlined, CloseOutlined, CaretLeftOutlined } from "@ant-design/icons";
import { GetData, MutateData } from "../../handlers/axios";
import { useAtom } from "jotai";
import { EventId, TicketSelection } from "../../handlers/globals";
import { TransformDatePickerResult, formTagsOptionals, notifyError } from "../../handlers/extensions";
import { ComponentFormInput } from "../components/forms/input";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import moment from "moment";
import { Link, Navigate, useNavigate } from "react-router-dom";

export default function EventPayment() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  const { i18n, t } = useTranslation();

  const [eventId,] = useAtom(EventId);

  const response = GetData(QueryId.SelectEvent, { EventId: eventId });

  const responseRetailers = GetData(QueryId.SelectEventRetailers, { EventId: eventId });

  const [ticketSelection,] = useAtom(TicketSelection);

  const [freezeActions, setFreezeActions] = useState(false);

  const [consent, setConsent] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const formAction = MutateData(QueryId.ValidateCart,
    () => { setFreezeActions(false) }, [
    { case: QueryCase.Success, onCase: (data) => { window.location.href = data.data } },
    { case: QueryCase.Expired, onCase: () => { notifyError("toast_mutate_warning_ticket_sniped") } },
    { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
  ]);

  const onFinish = (properties) => {
    if (!properties.form_field_birthdate || !properties.form_field_consent) { notifyError(ToastId.RequiredFields); return; }

    properties.form_field_birthdate = TransformDatePickerResult(properties.form_field_birthdate);

    properties.form_field_language = i18n.language;
    properties.form_field_tickets = ticketSelection;

    setFreezeActions(true);
    formAction.mutate(properties);
  };

  return (
    <>
      <Layout className="!bg-transparent">
        <ComponentHeader lite />
        <Layout className="flex !bg-transparent justify-between lg:justify-center">
          <Content className="min-h-[80vh] pb-8">
            {response.isLoading || responseRetailers.isLoading
              ? <Spin className="mx-auto w-full mt-5" size="large" />
              : response.isFalse
                ? <ComponentState error />
                : response.isEmpty
                  ? <ComponentState empty />
                  : !ticketSelection || ticketSelection.length <= 0
                    ? <Navigate to={WebRoute.Home} replace={true} />
                    : <div className="mx-8 my-4">
                      <p className="text-2xl font-semibold mb-4"><CaretLeftOutlined className="cursor-pointer hover:text-sonar-blue" onClick={() => { navigate(WebRoute.Event + `?id=${eventId}`); }} /> {t("payment")}: {response.data?.event.name}</p>
                      {[...new Set(ticketSelection)].map((ticket) => {
                        const idTicketSelection = ticketSelection.filter(x => { return x.id === ticket.id });
                        return <div key={ticket.id} className="sm:flex sm:justify-between mb-1">
                          <div className="whitespace-nowrap mr-2">{ticket.name} | x{idTicketSelection.length}</div>
                          <div>{response.data?.event?.currency === Currency.EURO ? <span>&euro;</span> : <span>&pound;</span>} {ticket.price === 0 ? ticket.price : ((ticket.price - (ticket.price / 100 * ticket.salePercentage)) + response.data?.event?.fee).toFixed(2)} / {t("ticket")}</div>
                        </div>
                      })}
                      <hr className="my-2" />
                      <div className="sm:flex sm:justify-between text-lg">
                        <div className="flex">
                          <span className="font-semibold mr-1">{t("total")}:</span>
                          {ticketSelection.map(x => { return x.price === 0 ? x.price : ((x.price - (x.price / 100 * x.salePercentage)) + response.data?.event?.fee) }).reduce((a, b) => a + b, 0) === 0 ? <></> :
                            <span className="text-gray-400 text-xs my-auto">{t("custom_incl_sonar_fee_prefix")} {response.data?.event?.currency === Currency.EURO ? <span>&euro;</span> : <span>&pound;</span>} {response.data?.event?.fee.toFixed(2)} {t("custom_incl_sonar_fee_suffix")?.slice(0, -1)} + {t("custom_incl_btw_suffix")}</span>
                          }
                        </div>
                        <div>{response.data?.event?.currency === Currency.EURO ? <span>&euro;</span> : <span>&pound;</span>} {ticketSelection.map(x => { return x.price === 0 ? x.price : ((x.price - (x.price / 100 * x.salePercentage)) + response.data?.event?.fee) }).reduce((a, b) => a + b, 0).toFixed(2)}</div>
                      </div>
                      <div className="text-xl font-semibold mb-2 mt-5">{t("payment_information")}</div>
                      <div>{t("payment_description")}</div>
                      <div className="text-xl font-semibold mb-2 mt-5">{t("personal_information")}</div>
                      <Form
                        form={form}
                        requiredMark={formTagsOptionals}
                        colon={false}
                        onFinish={onFinish}
                      >
                        <ComponentFormInput field={"form_field_prename"} placeholder={"placeholder_prename"} feedback clear required />
                        <ComponentFormInput field={"form_field_lastname"} placeholder={"placeholder_lastname"} feedback clear required />
                        <Form.Item required name={"form_field_birthdate"} label={t("form_field_birthdate")}>
                          <DatePicker className="w-full" placeholder={t("placeholder_birthday")} showNow={false} inputReadOnly allowClear
                            minDate={dayjs(moment().subtract(100, "Y").format(Defaults.DateFormat), Defaults.DateFormat)}
                            maxDate={dayjs(moment().subtract(5, "Y").format(Defaults.DateFormat), Defaults.DateFormat)}
                          />
                        </Form.Item>
                        <ComponentFormInput field={"form_field_email"} placeholder={"placeholder_email"} feedback clear required email lower />
                        <ComponentFormInput field={"form_field_mobile"} placeholder={"placeholder_mobile"} feedback clear phone />
                        {responseRetailers.isFalse || responseRetailers.isEmpty ? <></> :
                          <Form.Item name={"form_field_retailer"} label={t("form_field_retailer")} initialValue={Defaults.GuidEmpty}>
                            <Select
                              options={[{ value: Defaults.GuidEmpty, label: "-" },
                              ...responseRetailers.data.map((retailer) => { return { label: retailer.name, value: retailer.id } })]}
                            />
                          </Form.Item>
                        }
                        <Form.Item className="ant-switch-form-override mt-7" name={"form_field_consent"} label={<span>{t("form_field_consent")} <Link className="underline" to={WebRoute.Docs} target="_blank" rel="noopener noreferrer">{t("docs-terms")}</Link></span>} initialValue={false} required>
                          <Switch onChange={(checked) => { setConsent(checked) }} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                        </Form.Item>
                        <Form.Item className="mb-1">
                          <Button disabled={freezeActions || !consent} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2 font-semibold">{t("form_confirm_order")} ({response.data?.event?.currency === Currency.EURO ? <span>&euro;</span> : <span>&pound;</span>} {ticketSelection.map(x => { return x.price === 0 ? x.price : ((x.price - (x.price / 100 * x.salePercentage)) + response.data?.event?.fee) }).reduce((a, b) => a + b, 0).toFixed(2)})</Button>
                        </Form.Item>
                      </Form>
                    </div>
            }
          </Content>
        </Layout>
        <ComponentFooter />
      </Layout>
    </>
  );
}