import { formTagsOptionals, notifyError, notifySuccess } from "../../handlers/extensions";
import { Defaults, QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { Button, Form, Popover, Spin } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { ComponentFormInput } from "../components/forms/input";
import { useState } from "react";
import { ExportOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import moment from "moment";
import { GetData, MutateData } from "../../handlers/axios";
import RichtTextArea from "../components/forms/textarea";

export function SectionBlogs(props) {
    const response = GetData(QueryId.SelectBlogs);

    const { t } = useTranslation();

    const [quill, setQuill] = useState(Defaults.StringEmpty);

    const formAction = MutateData(QueryId.UpdateBlog,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { response.refetch(); notifySuccess(ToastId.ActionSuccess); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const [form] = Form.useForm();
    const onFinish = (properties) => {
        properties.form_field_body = quill;

        if (!properties.form_field_body || properties.form_field_body === "<p><br></p>") {
            notifyError(ToastId.RequiredFields); return;
        };
        props.setFreezeActions(true);
        formAction.mutate(properties);
    }

    return (<>
        <p className="font-semibold text-lg mb-5">{t(props.section)}</p>
        {response.isLoading ? <Spin /> : response.isFalse ? <ComponentState error /> :
            <Form
                form={form}
                requiredMark={formTagsOptionals}
                colon={false}
                onFinish={onFinish}
                className="mb-6"
            >
                <ComponentFormInput field={"form_field_url"} placeholder={"placeholder_url"} feedback clear url />
                <ComponentFormInput field={"form_field_title"} placeholder={"placeholder_title"} feedback clear required />
                <Form.Item name={"form_field_body"} label={t("form_field_body")} required>
                    <RichtTextArea setValue={setQuill} />
                </Form.Item>
                <Form.Item>
                    <Button onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_confirm_blogpost")}</Button>
                </Form.Item>
            </Form>
        }
        <div className="mb-8">
            {response.isLoading || response.isFalse || response.isEmpty ? <></> : response.data.map((blog) => {
                return <BlogPostItem key={blog.id} data={blog} refetch={response.refetch} setFreezeActions={props.setFreezeActions} />
            })}
        </div>
    </>)
}
export function BlogPostItem(props) {
    const { i18n, t } = useTranslation();

    const [isEditing, setIsEditing] = useState(false);

    const [form] = Form.useForm();

    const [quill, setQuill] = useState(props.data.body);

    const formAction = MutateData(QueryId.UpdateBlog,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); notifySuccess(ToastId.ActionSuccess); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinish = (properties) => {
        properties.form_field_id = props.data.id;
        properties.form_field_body = quill;

        if (!properties.form_field_body || properties.form_field_body === "<p><br></p>") {
            notifyError(ToastId.RequiredFields); return;
        };
        props.setFreezeActions(true);
        formAction.mutate(properties);
    }

    const [open, setOpen] = useState(false);

    const handleOpenChange = (state) => {
        setOpen(state);
    };

    return <>
        <hr className="my-5" />
        <div className="relative min-h-20">
            <div className="flex">
                <div className="text-sonar-green font-semibold text-xl mr-4 mb-1">{moment(props.data.createdOn).locale(i18n.language).format("DD MMMM")}</div>
                {isEditing
                    ? <Form
                        form={form}
                        requiredMark={formTagsOptionals}
                        colon={false}
                        onFinish={onFinish}
                        className="mb-6 mr-12"
                    >
                        <ComponentFormInput field={"form_field_url"} placeholder={"placeholder_url"} value={props.data.url} feedback clear url />
                        <ComponentFormInput field={"form_field_title"} placeholder={"placeholder_title"} value={props.data.title} feedback clear required />
                        <Form.Item name={"form_field_body"} label={t("form_field_body")} initialValue={props.data.body} required>
                            <RichtTextArea setValue={setQuill} initialValue={props.data.body} />
                        </Form.Item>
                        <Form.Item>
                            <Button onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_save_blogpost")}</Button>
                        </Form.Item>
                    </Form>
                    : <div className="mb-3 mr-12">
                        {props.data.url
                            ? <div className="flex">
                                <p className="font-semibold text-lg mr-2">{props.data.title}</p>
                                <a href={props.data.url} rel="noreferrer noopener" target="_blank">
                                    <ExportOutlined className="text-lg text-sonar-blue my-auto h-full hover:text-sonar-green" />
                                </a>
                            </div>
                            : <p className="font-semibold text-lg mr-2">{props.data.title}</p>
                        }
                        <div className="mt-2 max-w-xl">{parse(props.data.body)}</div>
                    </div>
                }
            </div>
            <Button className="absolute right-0 top-0 bg-orange-400" shape="circle" onClick={() => { setIsEditing(!isEditing); }} icon={<EditOutlined className="text-2xl" />} />
            <Popover
                content={<>
                    <p className="mb-4">{t("popover_description_delete")}</p>
                    <div>
                        <Button className="bg-sonar-green mr-2" onClick={(() => { formAction.mutate({ form_field_id: props.data.id }); handleOpenChange(false); })}>{t("popover_confirm")}</Button>
                        <Button className="bg-red-400" onClick={(() => { handleOpenChange(false); })}>{t("popover_cancel")}</Button>
                    </div>
                </>}
                title={t("popover_title_delete")}
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
                placement="left"
            >
                <Button className="absolute right-0 top-12 bg-red-400" shape="circle" onClick={() => { handleOpenChange(true); }} icon={<DeleteOutlined className="text-2xl" />} />
            </Popover>
        </div>
    </>
}