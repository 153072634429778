export const QueryId = {
    DeleteBusinessUser: "v1/delete/business/user",
    DeleteSupportRegister: "v1/delete/support/register",
    InsertBusiness: "v1/insert/business",
    InsertBusinessEvent: "v1/insert/businesses/event",
    InsertBusinessEventTicket: "v1/insert/business/event/ticket",
    InsertBusinessSupport: "v1/insert/business/support",
    InsertBusinessUser: "v1/insert/business/user",
    InsertSupport: "v1/insert/support",
    RequestUserPassword: "v1/request/user/password",
    ResolveSupport: "v1/resolve/support",
    ResolveUserPassword: "v1/resolve/user/password",
    SelectAlertLatest: "v1/select/alert/latest",
    SelectAlerts: "v1/select/alerts",
    SelectBlogs: "v1/select/blogs",
    SelectSonarEvents: "v1/select/sonar/events",
    SelectOrders: "v1/select/orders",
    SelectBlogsCardsLatest: "v1/select/blogs/cards/latest",
    SelectBusinessEventGeneral: "v1/select/business/event/general",
    SelectBusinessEventTickets: "v1/select/business/event/tickets",
    SelectBusinessEvents: "v1/select/business/events",
    SelectBusinessGeneral: "v1/select/business/general",
    SelectBusinessSupports: "v1/select/business/supports",
    SelectBusinessUserAccess: "v1/select/business/user/access",
    SelectBusinessUsers: "v1/select/business/users",
    SelectSupportsBusinesses: "v1/select/supports/businesses",
    SelectSupportsEvents: "v1/select/supports/events",
    SelectSupportsRegisters: "v1/select/supports/registers",
    SelectSupportsUsers: "v1/select/supports/users",
    UpdateAlert: "v1/update/alert",
    UpdateBlog: "v1/update/blog",
    UpdateBusinessEventGeneral: "v1/update/business/event/general",
    UpdateBusinessEventTicket: "v1/update/business/event/ticket",
    UpdateBusinessEventTicketsOrder: "v1/update/business/event/tickets/order",
    UpdateBusinessGeneral: "v1/update/business/general",
    UpdateBusinessUserAccess: "v1/update/business/user/access",
    UpdateBusinessUserPassword: "v1/update/business/user/password",
    UpdateBusinessUserRole: "v1/update/business/user/role",
    UpdateUserPassword: "v1/update/user/password",
    ValidateSupportRegister: "v1/validate/support/register",
    UpdateEventGeneral: "v1/update/event/general",
    ValidateUser: "v1/validate/user",
    SelectSales: "v1/select/sales",
    SelectBusinessEvent: "v1/select/business/event",
    InsertBusinessEventTicketGiveawayCodes: "v1/insert/business/event/ticket/giveawaycodes",
    SelectBusinessEventTicketGiveawayCodes: "v1/select/business/event/ticket/giveawaycodes",
    ValidateBusinessEventTicketGiveawayCode: "v1/update/business/event/ticket/giveawaycode",
    UpdateBusinessEventUrl: "v1/update/business/event/url",
    DeleteBusinessEventPictureTile: "v1/delete/business/event/picture/tile",
    UpdateBusinessEventPictureTile: "v1/update/business/event/picture/tile",
    DeleteBusinessEventPictureBanner: "v1/delete/business/event/picture/banner",
    UpdateBusinessEventPictureBanner: "v1/update/business/event/picture/banner",
    DeleteBusinessEventSponser: "v1/delete/business/event/sponser",
    SelectBusinessEventSponsers: "v1/select/business/event/sponsers",
    InsertBusinessEventSponser: "v1/insert/business/event/sponser",
    InsertBusinessEventRetailer: "v1/insert/business/event/retailer",
    SelectBusinessEventRetailers: "v1/select/business/event/retailers",
    DeleteBusinessEventRetailer: "v1/delete/business/event/retailer",
    InsertBusinessEventScanner: "v1/insert/business/event/scanner",
    SelectBusinessEventScanners: "v1/select/business/event/scanners",
    SelectBusinessEventStages: "v1/select/business/event/stages",
    InsertBusinessEventStage: "v1/insert/business/event/stage",
    UpdateBusinessEventStage: "v1/update/business/event/stage",
    UpdateBusinessEventStagesOrder: "v1/update/business/event/stages/order",
    SelectBusinessEventStageArtists: "v1/select/business/event/stage/artists",
    UpdateBusinessEventStageArtistsOrder: "v1/select/business/event/stage/artists/order",
    InsertBusinessEventStageArtist: "v1/insert/business/event/stage/artist",
    UpdateBusinessEventStageArtist: "v1/update/business/event/stage/artist",
    DeleteBusinessEventScanner: "v1/delete/business/event/scanner",
    DeleteBusinessEventTicket: "v1/delete/business/event/ticket",
    DeleteBusinessEventPodium: "v1/delete/business/event/podium",
    DeleteBusinessEventArtist: "v1/delete/business/event/artist",
    SelectBusinessEventProgress: "v1/select/business/event/progress",
    SelectEvents: "v1/select/events",
    SelectEvent: "v1/select/event",
    SelectEventRetailers: "v1/select/event/retailers",
    ValidateCart: "v1/validate/cart",
    SelectOrderState: "v1/select/order/state",
    ValidateEvent: "v1/validate/event",
    SelectBusinessEventSales: "v1/select/business/event/sales",
    UpdateBusinessEventTicketScanStatus: "v1/update/business/event/ticket/scanstatus",
    SelectBusinessEventStatistics: "v1/select/business/event/statistics",
    ReattemptOrderMail: "v1/reattempt/order/mail",
}

export const Currency = {
    POUND: "GBP",
    EURO: "EUR"
}

export const QueryCase = {
    Unknown: 0,

    //Defaults
    Success: 200,
    Error: 400,

    //Customs
    Empty: 260,
    Invalid: 261,
    Expired: 262,
    Unverified: 263,
    Mismatch: 264,
    MissingParameters: 265,
    FailedEmail: 266,
    ContactUs: 267,
    Compressed: 268,
    Undefined: 269,
    WarningDatabase: 270,
}

export const Defaults = {
    GuidEmpty: "00000000-0000-0000-0000-000000000000",
    DateFormat: "YYYY-MM-DD",
    DateFormatDTB: "YYYY-MM-DDTHH:mm:ss",
    DateFormatFull: "DD MMMM YYYY",
    TimeFormat: "HH:mm",
    StringEmpty: "",
    UrlPrefix: "https://",
    Space: " "
}

export const Language = {
    Dutch: "nl",
    English: "en",
    EnglishAlt: "gb"
}

export const Color = {
    White: "#F9F9F9",
    Grey: "#39393A",
    Black: "#12130F",
    Blue: "#83BCFF",
    Green: "#7DAA92",
    Yellow: "#E8CB10",
    Red: "#F8719D"
}

export const WebRoute = {
    Home: "/",
    Event: "/event",
    EventPayment: "/event/payment",
    EventPaymentCallback: "/event/payment/callback",
    Assets: "/assets",
    Docs: "/docs",
    Blog: "/blog",
    Sonar: "/sonar",
    UserSupport: "/support",
    RedirectBusiness: "/business",
    BusinessOverview: "/business/overview",
    BusinessEventOverview: "/business/event/overview",
    BusinessPricing: "/business/pricing",
    BusinessRegister: "/business/register",
    BusinessSignin: "/business/signin",
    BusinessSigninReset: "/business/signin/reset",
    BusinessSigninResetCallback: "/business/signin/reset/callback"
}

export const ToastId = {
    ActionSuccess: "toast_mutate_success",
    ActionSuccessUpdate: "toast_mutate_success_update",
    ActionSuccessCreate: "toast_mutate_success_create",
    ActionSuccessDelete: "toast_mutate_success_delete",
    ActionSuccessSubmit: "toast_mutate_success_submit",
    ActionError: "toast_mutate_error",
    ActionWarning: "toast_mutate_warning",
    RequiredFields: "toast_required_fields",
    RequiredHttps: "toast_required_https"
}

export const StorageId = {
    SearchValue: "search_value",
    SearchCategory: "search_category",
    SearchSort: "search_sort",
    SonarAlert: "alert_cleared",
    SigninAlert: "alert_session",
    SearchCode: "search_code"
}

export const Regex = {
    Phone: /^\+\d{6,15}$/,
    AlphaNumeric: /^[a-zA-Z0-9]+$/,
    AlphaNumericSpaces: /^[a-zA-Z0-9\s]+$/,
    Numeric: /^[0-9]+$/,
    Alpha: /^[a-zA-Z]+$/,
    SpacedNumber: /\B(?=(\d{3})+(?!\d))/g,
    AnySpecial: /[^a-zA-Z0-9\s]/
}

export const Settings = {
    AxiosLogs: true,
    IsDevelopment: false,
    BaseSonarFee: 1.35,
    TrackMissingTranslationKeys: true
}

export const Links = {
    Vvoids: "https://vvoids.com",
    SonarApex: "https://sonarevent.com",
    Twitter: "https://twitter.com",
    Facebook: "https://www.facebook.com/Sonarevent",
    Linkedin: "https://www.linkedin.com/company/sonar-event/",
    Instagram: "https://www.instagram.com/sonarevent/",
    Tiktok: "https://www.tiktok.com/@sonarevent",
    MailTo: "mailto:info@sonarevent.com",
    PhoneTo: "tel:+32123456789"
}

export const Sections = {
    Top: "nav-top",
    Bottom: "nav-bottom",
    AssetsImages: "assets-images",
    AssetsColors: "assets-colors",
    DocsTerms: "docs-terms",
    DocsPrivacy: "docs-privacy",
    DocsSla: "docs-sla",
    DocsSecurity: "docs-security",
    SonarEventConfig: "sonar-event-config",
    SonarBusinessConfirm: "sonar-business-confirm",
    SonarSupportUserAnswer: "sonar-support-user-answer",
    SonarEventConfirm: "sonar-event-confirm",
    SonarSupportBusinessAnswer: "sonar-support-business-answer",
    SonarAlert: "sonar-alert",
    SonarBlog: "sonar-blog",
    SonarCheckEventSales: "sonar-check-event-sales",
    BusinessGeneral: "business-general",
    BusinessTeam: "business-team",
    BusinessEvents: "business-events",
    BusinessSecurity: "business-security",
    BusinessSupport: "business-support",
    BusinessOverview: "business-overview",
    BusinessEventGeneral: "business-event-general",
    BusinessEventImages: "business-event-images",
    BusinessEventLocation: "business-event-location",
    BusinessEventTickets: "business-event-tickets",
    BusinessEventPartners: "business-event-partners",
    BusinessEventPodiaAndArtists: "business-event-podiaandartists",
    BusinessEventTicketHolders: "business-event-ticketholders",
    BusinessEventScanners: "business-event-scanners",
    PodiaOverview: "business-event-podium-overview",
    PodiumNew: "business-event-podium-new",
    PodiumEdit: "business-event-podium-edit",
    ArtistNew: "business-event-podium-artist-new",
    ArtistEdit: "business-event-podium-artist-edit",
    BusinessEventPublish: "business-event-publish"
}

export const EventSortOption = {
    Alpha: "sort_option_alpha",
    Upcoming: "sort_option_upcoming",
    City: "sort_option_city"
}

export const EventCategoryOption = {
    All: "event_category_filter_all",
    Available: "event_category_filter_available",
    Demo: "event_category_filter_demo"
}