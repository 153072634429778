import { formTagsNone, notifyError, notifySuccess } from "../../handlers/extensions";
import { QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { Button, Form, Popover, Spin, Tag, Tooltip } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { ComponentFormInput } from "../components/forms/input";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { GetData, MutateData } from "../../handlers/axios";
import { useAtom } from "jotai";
import { BusinessId, EventId, UserId } from "../../handlers/globals";

export function SectionBusinessEventPartners(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);

    const { t } = useTranslation();

    const response = GetData(QueryId.SelectBusinessEventRetailers, { BusinessId: businessId, UserId: userId, EventId: eventId });

    const formActionInsert = MutateData(QueryId.InsertBusinessEventRetailer,
        () => { setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { form.resetFields(); response.refetch(); notifySuccess(ToastId.ActionSuccessCreate); } },
        { case: QueryCase.Invalid, onCase: () => { notifyError("toast_mutate_warning_retailer_duplicate") } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const [freezeActions, setFreezeActions] = useState(false);

    const [form] = Form.useForm();

    const onFinishInsert = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;

        setFreezeActions(true);
        formActionInsert.mutate(properties);
    };

    return (response.isLoading ? <Spin /> : response.isFalse ? <ComponentState error /> : response.isEmpty ? <>
        <Tooltip className="flex w-min" title={t("tooltip_partners")}><p className="font-semibold text-lg mb-2">{t(props.section)}</p><QuestionCircleOutlined className="mt-[6px] h-full text-lg ml-1" /></Tooltip>
        <Form
            form={form}
            requiredMark={formTagsNone}
            colon={false}
            onFinish={onFinishInsert}
            className="mb-2"
        >
            <div className="flex">
                <ComponentFormInput field={"form_field_partner"} placeholder={"placeholder_partner"} required clear feedback />
                <Form.Item>
                    <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full ml-2">{t("form_confirm_insert")}</Button>
                </Form.Item>
            </div>
        </Form>
    </> : <>
        <Tooltip className="flex w-min" title={t("tooltip_partners")}><p className="font-semibold text-lg mb-2">{t(props.section)}</p><QuestionCircleOutlined className="mt-[6px] h-full text-lg ml-1" /></Tooltip>
        <Form
            form={form}
            requiredMark={formTagsNone}
            colon={false}
            onFinish={onFinishInsert}
            className="mb-2"
        >
            <div className="flex">
                <ComponentFormInput field={"form_field_partner"} placeholder={"placeholder_partner"} required clear feedback />
                <Form.Item>
                    <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full ml-2">{t("form_confirm_insert")}</Button>
                </Form.Item>
            </div>
        </Form>
        <hr className="mb-3" />
        {response.data.map((tag) => {
            return <Partner key={tag.id} tag={tag} refetch={response.refetch} freezeActions={freezeActions} setFreezeActions={setFreezeActions} />
        })}
    </>);
}

function Partner(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);

    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    const handleOpenChange = (state) => {
        setOpen(state);
    };

    const formActionDelete = MutateData(QueryId.DeleteBusinessEventRetailer,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); notifySuccess(ToastId.ActionSuccessDelete); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinishDelete = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;

        props.setFreezeActions(true);
        formActionDelete.mutate(properties);
    };

    return <Popover
        content={<>
            <p className="mb-4">{t("popover_partner_delete")}</p>
            <div>
                <Button className="bg-sonar-green mr-2" onClick={(() => { onFinishDelete({ form_field_partnerid: props.tag.id }); handleOpenChange(false); })}>{t("popover_confirm")}</Button>
                <Button className="bg-red-400" onClick={(() => { handleOpenChange(false); })}>{t("popover_cancel")}</Button>
            </div>
        </>}
        title={t("popover_title_delete")}
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
    >
        <Tag color={props.tag.isDisabled ? "error" : "processing"} closeIcon={!props.tag.isDisabled} onClose={(event) => { event.preventDefault(); if (props.freezeActions) { return; }; handleOpenChange(true); }}>{props.tag.name}</Tag>
    </Popover>
}