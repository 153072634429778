import { Button, DatePicker, Form, InputNumber, Select, Spin, Tooltip } from "antd";
import { Currency, Defaults, QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { GetData, MutateData } from "../../handlers/axios";
import ComponentState from "../components/states";
import { Collapse } from 'antd';
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ToLowerAlphanumeric, TransformRangePickerResult, formTagsNone, formTagsOptionals, notifyError, notifySuccess } from "../../handlers/extensions";
import dayjs from "dayjs";
import moment from "moment";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ComponentFormInput } from "../components/forms/input";

export function SectionEventConfig(props) {
    const response = GetData(QueryId.SelectSonarEvents);
    const { t } = useTranslation();

    return (<>
        <p className="font-semibold text-lg mb-2">{t(props.section)}</p>
        {response.isLoading
            ? <Spin />
            : response.isFalse
                ? <ComponentState error />
                : response.isEmpty
                    ? <ComponentState empty />
                    : <Collapse accordion items={response.data?.map(x => { return { key: x.id, label: <span><b>{x.businessName}</b>: {x.name}</span>, children: <FormEventUpdate event={x} /> } })} />
        }
    </>);
}

function FormEventUpdate(props) {
    const { t } = useTranslation();

    const formAction = MutateData(QueryId.UpdateEventGeneral,
        () => { setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { notifySuccess(ToastId.ActionSuccessUpdate); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const [freezeActions, setFreezeActions] = useState(false);

    const [currency, setCurrency] = useState(props.event.currency);

    const [form] = Form.useForm();

    const onFinish = (properties) => {
        if (!properties.form_field_event_date_range) {
            notifyError(ToastId.RequiredFields); return;
        };

        properties.form_field_event_date_range = TransformRangePickerResult(properties.form_field_event_date_range);
        properties.form_field_eventid = props.event.id;

        setFreezeActions(true);
        formAction.mutate(properties);
    };

    const { RangePicker } = DatePicker;

    return (<>
        <Form
            form={form}
            requiredMark={formTagsNone}
            colon={false}
            onFinish={onFinish}
        >
            <ComponentFormInput field={"form_field_vat"} placeholder={"placeholder_vat"} value={props.event.vatNumber} disabled={true} upper />
            <ComponentFormInput field={"form_field_iban"} placeholder={"placeholder_iban"} value={props.event.billingNumber} disabled={true} upper />
            <ComponentFormInput field={"form_field_swiftcode"} placeholder={"placeholder_swiftcode"} value={props.event.bic} disabled={true} upper />
            <ComponentFormInput field={"form_field_cardholder"} placeholder={"placeholder_cardholder"} value={props.event.cardHolder} disabled={true} upper />
        </Form>
        <Form
            form={form}
            requiredMark={formTagsOptionals}
            colon={false}
            onFinish={onFinish}
        >
            <Form.Item name={"form_field_currency"} label={<span>{t("form_field_currency")}</span>} initialValue={currency ? currency : Currency.EURO} required>
                <Select
                    options={[
                        { value: Currency.EURO, label: t(Currency.EURO) },
                        { value: Currency.POUND, label: t(Currency.POUND), disabled: true }
                    ]}
                    onChange={(value) => { console.log(value); return setCurrency(value); }}
                />
            </Form.Item>
            <Form.Item name={"form_field_sonar_fee"} label={t("form_field_sonar_fee")} initialValue={props.event.fee} required>
                <InputNumber required addonAfter={currency && currency === Currency.POUND ? <span>&pound;</span> : <span>&euro;</span>} changeOnWheel={false} controls={false} min={1} max={3} decimalSeparator={"."} precision={2} />
            </Form.Item>
            <Form.Item name={"form_field_event_date_range"} label={<Tooltip title={t("form_field_date_range_tooltip")}>{t("form_field_event_date_range")}<QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip>} initialValue={[dayjs(props.event.eventStartDate ? moment(props.event.eventStartDate).format(Defaults.DateFormatDTB) : moment().format(Defaults.DateFormat), Defaults.DateFormat), dayjs(props.event.eventEndDate ? moment(props.event.eventEndDate).format(Defaults.DateFormatDTB) : moment().format(Defaults.DateFormat), Defaults.DateFormat)]} required>
                <RangePicker required allowClear needConfirm inputReadOnly={true} />
            </Form.Item>
            <ComponentFormInput field={"form_field_event_url"} placeholder={ToLowerAlphanumeric(props.event.name)} feedback clear lower />
            <Form.Item>
                <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_confirm_update_business")}</Button>
            </Form.Item>
        </Form>
    </>);
}