import { TransformRangePickerResult, formTagsNone, notifyError, notifySuccess } from "../../handlers/extensions";
import { Defaults, QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { Button, DatePicker, Form, Popover, Spin, Switch, Tooltip } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { EditOutlined, DeleteOutlined, InfoCircleTwoTone, WarningTwoTone, CalendarOutlined, CarryOutOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import parse from "html-react-parser";
import { GetData, MutateData } from "../../handlers/axios";
import RichtTextArea from "../components/forms/textarea";

export function SectionAlerts(props) {
    const response = GetData(QueryId.SelectAlerts);

    const { t } = useTranslation();

    const [quill, setQuill] = useState(Defaults.StringEmpty);

    const formAction = MutateData(QueryId.UpdateAlert,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { response.refetch(); notifySuccess(ToastId.ActionSuccess); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const [form] = Form.useForm();
    const onFinish = (properties) => {
        properties.form_field_body = quill;

        if (!properties.form_field_body || properties.form_field_body === "<p><br></p>" || !properties.form_field_date_range) {
            notifyError(ToastId.RequiredFields); return;
        };

        properties.form_field_date_range = TransformRangePickerResult(properties.form_field_date_range);

        props.setFreezeActions(true);
        formAction.mutate(properties);
    }

    const { RangePicker } = DatePicker;

    return (<>
        <p className="font-semibold text-lg mb-5">{t(props.section)}</p>
        {response.isLoading ? <Spin /> : response.isFalse ? <ComponentState error /> :
            <Form
                form={form}
                requiredMark={formTagsNone}
                colon={false}
                onFinish={onFinish}
                className="mb-6"
            >
                <Form.Item className="ant-switch-form-override" name={"form_field_state"} label={t("form_field_state")} initialValue={true} required>
                    <Switch checkedChildren={t("info")} unCheckedChildren={t("warning")} />
                </Form.Item>
                <Form.Item name={"form_field_date_range"} label={<Tooltip title={t("form_field_date_range_tooltip")}>{t("form_field_date_range")}<QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip>} initialValue={[dayjs(moment().format(Defaults.DateFormat), Defaults.DateFormat), dayjs(moment().add(7, "d").format(Defaults.DateFormat), Defaults.DateFormat)]} required>
                    <RangePicker required allowClear needConfirm inputReadOnly={true}
                        minDate={dayjs(moment().subtract(1, "d").format(Defaults.DateFormat), Defaults.DateFormat)}
                        maxDate={dayjs(moment().add(1, "M").format(Defaults.DateFormat), Defaults.DateFormat)}
                    />
                </Form.Item>
                <Form.Item name={"form_field_body"} label={t("form_field_body")} required>
                    <RichtTextArea setValue={setQuill} />
                </Form.Item>
                <Form.Item>
                    <Button onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_confirm_alert")}</Button>
                </Form.Item>
            </Form>
        }
        <div className="mb-8">
            {response.isLoading || response.isFalse || response.isEmpty ? <></> : response.data.map((alert) => {
                return <AlertItem key={alert.id} data={alert} refetch={response.refetch} setFreezeActions={props.setFreezeActions} />
            })}
        </div>
    </>)
}
export function AlertItem(props) {
    const { i18n, t } = useTranslation();

    const [isEditing, setIsEditing] = useState(false);

    const [quill, setQuill] = useState(props.data.body);

    const [form] = Form.useForm();

    const { RangePicker } = DatePicker;

    const formAction = MutateData(QueryId.UpdateAlert,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); notifySuccess(ToastId.ActionSuccess); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinish = (properties) => {
        properties.form_field_id = props.data.id;
        properties.form_field_body = quill;

        if (!properties.form_field_body || properties.form_field_body === "<p><br></p>" || !properties.form_field_date_range) {
            notifyError(ToastId.RequiredFields); return;
        };

        properties.form_field_date_range = TransformRangePickerResult(properties.form_field_date_range);

        props.setFreezeActions(true);
        formAction.mutate(properties);
    }

    const [open, setOpen] = useState(false);

    const handleOpenChange = (state) => {
        setOpen(state);
    };

    return <>
        <hr className="my-5" />
        <div className="relative min-h-20">
            <div className="flex">
                {isEditing ? null :
                    <div className="font-semibold text-xl mr-4">
                        <div className="flex text-sonar-green">
                            <CalendarOutlined />
                            <span className="ml-1 block">{moment(props.data.activeFrom).locale(i18n.language).format("DD MMMM")}</span>
                        </div>
                        <div className="flex text-sonar-blue">
                            <CarryOutOutlined />
                            <span className="ml-1 block">{moment(props.data.activeUntil).locale(i18n.language).format("DD MMMM")}</span>
                        </div>
                    </div>
                }
                {isEditing
                    ? <Form
                        form={form}
                        requiredMark={formTagsNone}
                        colon={false}
                        onFinish={onFinish}
                        className="mb-6 mr-12"
                    >
                        <Form.Item className="ant-switch-form-override" name={"form_field_state"} label={t("form_field_state")} initialValue={props.data.isInfo} required>
                            <Switch checkedChildren={t("info")} unCheckedChildren={t("warning")} />
                        </Form.Item>
                        <Form.Item name={"form_field_date_range"} label={t("form_field_date_range")} initialValue={[dayjs(moment(props.data.activeFrom).format(Defaults.DateFormat), Defaults.DateFormat), dayjs(moment(props.data.activeUntil).format(Defaults.DateFormat), Defaults.DateFormat)]} required>
                            <RangePicker required allowClear needConfirm
                                minDate={dayjs(moment(props.data.activeFrom).subtract(1, "d").format(Defaults.DateFormat), Defaults.DateFormat)}
                                maxDate={dayjs(moment().add(1, "M").format(Defaults.DateFormat), Defaults.DateFormat)}
                            />
                        </Form.Item>
                        <Form.Item name={"form_field_body"} label={t("form_field_body")} initialValue={props.data.body} required>
                            <RichtTextArea setValue={setQuill} initialValue={props.data.body} />
                        </Form.Item>
                        <Form.Item>
                            <Button onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_save_alert")}</Button>
                        </Form.Item>
                    </Form>
                    : <div className="flex justify-center relative p-3">
                        {props.data.isInfo ? <InfoCircleTwoTone className="text-2xl mb-auto" /> : <WarningTwoTone className="text-xl mb-auto" twoToneColor={"#f5dd42"} />}
                        <span className="ml-1">{parse(props.data.body)}</span>
                    </div>
                }
            </div>
            <Button className="absolute right-0 top-0 bg-orange-400" shape="circle" onClick={() => { setIsEditing(!isEditing); }} icon={<EditOutlined className="text-2xl" />} />
            <Popover
                content={<>
                    <p className="mb-4">{t("popover_description_delete")}</p>
                    <div>
                        <Button className="bg-sonar-green mr-2" onClick={(() => { formAction.mutate({ form_field_id: props.data.id }); handleOpenChange(false); })}>{t("popover_confirm")}</Button>
                        <Button className="bg-red-400" onClick={(() => { handleOpenChange(false); })}>{t("popover_cancel")}</Button>
                    </div>
                </>}
                title={t("popover_title_delete")}
                trigger="click"
                open={open}
                placement="left"
            >
                <Button className="absolute right-0 top-12 bg-red-400" shape="circle" onClick={() => { handleOpenChange(true); }} icon={<DeleteOutlined className="text-2xl" />} />
            </Popover>
        </div>
    </>
}