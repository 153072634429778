import { formTagsNone, notifyError, notifySuccess } from "../../handlers/extensions";
import { QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { Button, Form } from "antd";
import { useTranslation } from "react-i18next";
import { ComponentFormPassword } from "../components/forms/input";
import { useState } from "react";
import { MutateData } from "../../handlers/axios";
import { UserId } from "../../handlers/globals";
import { useAtom } from "jotai";

export function SectionBusinessSecurity(props) {
    const [userId,] = useAtom(UserId);
    const { i18n, t } = useTranslation();

    const formAction = MutateData(QueryId.UpdateUserPassword,
        () => { setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { form.resetFields(); notifySuccess(ToastId.ActionSuccessUpdate); } },
        { case: QueryCase.Empty, onCase: () => { notifyError(ToastId.ActionError) } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const [freezeActions, setFreezeActions] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (properties) => {
        properties.form_field_language = i18n.language;
        properties.form_field_userid = userId;

        setFreezeActions(true);
        formAction.mutate(properties);
    };

    return (<>
        <p className="font-semibold text-lg mb-5">{t(props.section)}</p>
        <Form
            form={form}
            requiredMark={formTagsNone}
            colon={false}
            onFinish={onFinish}
        >
            <ComponentFormPassword field={"form_field_password_old"} placeholder={"placeholder_password"} feedback clear required />
            <ComponentFormPassword field={"form_field_password_new"} placeholder={"placeholder_password"} feedback clear required requirements />
            <Form.Item className="mb-1 mt-7">
                <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_confirm_password_update")}</Button>
            </Form.Item>
        </Form>
    </>);
}