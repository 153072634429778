import { formTagsNone, notifyError, notifySuccess } from "../../handlers/extensions";
import { QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { Button, Card, Form, Spin, Tooltip, Space, Popover, Tag } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { ComponentFormInput } from "../components/forms/input";
import { QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import { GetData, MutateData } from "../../handlers/axios";
import { useAtom } from "jotai";
import { BusinessEventProgress, BusinessId, EventId, UserId } from "../../handlers/globals";

export function SectionBusinessEventScanners(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);
    const { t } = useTranslation();

    const [businessEventProgress, setBusinessEventProgress] = useAtom(BusinessEventProgress);

    const formActionProgress = MutateData(QueryId.SelectBusinessEventProgress,
        (data) => { setBusinessEventProgress(data?.data ? data.data : businessEventProgress); });

    const onFinishProgress = (properties) => {
        formActionProgress.mutate(properties);
    };

    const response = GetData(QueryId.SelectBusinessEventScanners, { BusinessId: businessId, UserId: userId, EventId: eventId });

    const formActionInsert = MutateData(QueryId.InsertBusinessEventScanner,
        () => { setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { form.resetFields(); response.refetch(); onFinishProgress({ BusinessId: businessId, UserId: userId, EventId: eventId }); notifySuccess(ToastId.ActionSuccessCreate); } },
        { case: QueryCase.Invalid, onCase: () => { notifyError("toast_mutate_warning_scanner_duplicate") } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const [freezeActions, setFreezeActions] = useState(false);

    const [form] = Form.useForm();

    const onFinishInsert = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;

        setFreezeActions(true);
        formActionInsert.mutate(properties);
    };

    return (<>
        {response.isLoading
            ? <Spin />
            : response.isFalse
                ? <ComponentState error />
                : <>
                    <p className="font-semibold text-lg mb-2">{t(props.section)}</p>
                    <Form
                        form={form}
                        requiredMark={formTagsNone}
                        colon={false}
                        onFinish={onFinishInsert}
                        className="mb-2"
                    >
                        <ComponentFormInput field={"form_field_login"} placeholder={"placeholder_login"} required asciilite clear feedback />
                        <ComponentFormInput field={"form_field_password"} label={<Tooltip title={t("tooltip_scanner_password")}>{t("form_field_password")}<QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip>} placeholder={"placeholder_password"} required asciilite clear feedback />
                        <Form.Item>
                            <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_confirm_add")}</Button>
                        </Form.Item>
                    </Form>
                </>
        }
        {response.isEmpty ? <></> : <>
            <hr />
            <Space>
                {response.data?.map((scanner) => {
                    return <Scanner key={scanner.id} scanner={scanner} setFreezeActions={setFreezeActions} freezeActions={freezeActions} />
                })}
            </Space>
        </>
        }
    </>);
}

function Scanner(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const handleOpenChange = (state) => {
        setOpen(state);
    };

    const formActionDelete = MutateData(QueryId.DeleteBusinessEventScanner,
        () => { props.setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { notifySuccess(ToastId.ActionSuccessDelete); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinishDelete = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;

        props.setFreezeActions(true);
        formActionDelete.mutate(properties);
    };

    return (<Card className={"w-min mt-2" + (props.scanner.isDisabled ? " bg-sonar-red" : " bg-gray-200")}>
        <div className="flex mr-7">
            <p className="font-bold mr-1">{t("login")}:</p>
            <p>{props.scanner.login}</p>
        </div>
        <div className="flex mr-7">
            <p className="font-bold mr-1">{t("password")}:</p>
            <p>{props.scanner.isDisabled ? <Tag color="error">{t("disabled")}</Tag> : props.scanner.password}</p>
        </div>
        {props.scanner.isDisabled ? <></> :
            <Popover
                content={<>
                    <p className="mb-4">{t("popover_scanner_delete")}</p>
                    <div>
                        <Button className="bg-sonar-green mr-2" onClick={(() => { onFinishDelete({ form_field_scannerid: props.scanner.id }); handleOpenChange(false); })}>{t("popover_confirm")}</Button>
                        <Button className="bg-red-400" onClick={(() => { handleOpenChange(false); })}>{t("popover_cancel")}</Button>
                    </div>
                </>}
                title={t("popover_title_delete")}
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
            >
                <Button disabled={props.freezeActions} loading={props.freezeActions} className="absolute top-2 right-2 bg-red-400" shape="circle" onClick={() => { handleOpenChange(true); }} icon={<DeleteOutlined className="text-2xl" />} />
            </Popover>
        }
    </Card>);
}