import { JsonToTableBody, SortOnText, formTagsNone, notifyError, notifySuccess } from "../../handlers/extensions";
import { QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { Button, Form, Spin } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { ComponentFormTextarea } from "../components/forms/input";
import { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { GetData, MutateData } from "../../handlers/axios";
import moment from "moment";

export function SectionSupportBusinesses(props) {
    const response = GetData(QueryId.SelectSupportsBusinesses);
    const { t } = useTranslation();

    return (<>
        <p className="font-semibold text-lg mb-5">{t(props.section)}</p>
        <div className="mb-8 flex flex-col gap-5">
            {response.isLoading ? <Spin /> : response.isFalse ? <ComponentState error /> : response.isEmpty ? <ComponentState empty /> : response.data.sort((a, b) => { return SortOnText(b.createdOn, a.createdOn); }).map((item) => {
                return <SupportBusinessItem key={item.id} data={item} refetch={response.refetch} setFreezeActions={props.setFreezeActions} />;
            })}
        </div>
    </>);
}

export function SupportBusinessItem(props) {
    const formAction = MutateData(QueryId.ResolveSupport,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); notifySuccess(ToastId.ActionSuccess); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const { i18n, t } = useTranslation();

    const [isAnswering, setIsAnswering] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (properties) => {
        properties.form_field_supportid = props.data.id;
        properties.form_field_email = props.data.email;

        props.setFreezeActions(true);
        formAction.mutate(properties);
    };

    return (<div className="relative">
        <table className="mr-20">
            {props.data ? JsonToTableBody({ date: moment(props.data.createdOn).locale(i18n.language).format("DD MMMM"), business: props.data.businessName, email: props.data.email, body: props.data.body }) : null}
        </table>
        <Form
            form={form}
            requiredMark={formTagsNone}
            colon={false}
            onFinish={onFinish}
            className="mb-6 mt-3"
        >
            {!isAnswering ? null : <>
                <ComponentFormTextarea field={"form_field_reply"} required />
                <Form.Item>
                    <Button onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{props.data.reply ? t("form_support_reply_update") : t("form_support_reply")}</Button>
                </Form.Item>
            </>}
        </Form>
        <Button className={"absolute right-0 top-0 bg-orange-400"} shape="circle" onClick={() => { setIsAnswering(!isAnswering); }} icon={<EditOutlined className="text-2xl" />} />
        <hr />
    </div>);
}