import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import ComponentHeader from "./components/header";
import ComponentFooter from "./components/footer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputNumber } from "antd";
import React from "react";
import { CloseOutlined, PieChartOutlined, CreditCardOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Defaults, Regex, Settings } from "../handlers/enums";

export default function BusinessPricing() {
  useEffect(() => { window.scrollTo(0, 0); }, []);

  const { t } = useTranslation();

  const [price, setPrice] = useState(0);
  const [tickets, setTickets] = useState(0);

  return (
    <>
      <Layout className="!bg-transparent">
        <ComponentHeader />
        <Layout className="hidden md:flex !bg-transparent justify-between lg:justify-center">
          <Content className="min-h-[70vh] pt-8">
            <div className="w-[700px] border shadow-md rounded-xl p-3 h-min mx-auto">
              <p className="text-center mt-1 mb-5 text-xl font-semibold">{t("title_pricing_calculator")}</p>
              <div className="grid gap-4 grid-cols-6 grid-rows-3 text-center px-5">
                <div className="flex flex-col text-left">
                  <p className="whitespace-nowrap mb-1">{t("field_ticket_price")}</p>
                  <p className="whitespace-nowrap text-2xs">{t("field_ticket_price_extra")}</p>
                </div>
                <div><span className="hidden">FILLER</span></div>
                <div>
                  <p className="whitespace-nowrap text-left">{t("field_ticket_amount")}</p>
                </div>
                <div className="col-span-3">
                  <p>{t("calculator_result")}</p>
                </div>
                <div>
                  <InputNumber controls={false} precision={0} min={5} max={2500} placeholder={0} size="large" onChange={(value) => { setPrice(value ? value : 0) }} />
                </div>
                <div>
                  <CloseOutlined className="h-full" />
                </div>
                <div>
                  <InputNumber controls={false} precision={0} min={1} max={25000} placeholder={0} size="large" onChange={(value) => { setTickets(value ? value : 0) }} />
                </div>
                <div className="col-span-3">
                  <p className="text-xl whitespace-nowrap text-sonar-green font-semibold">{(price * tickets).toFixed(2).toString().replace(Regex.SpacedNumber, Defaults.Space)} {t("abbreviated_euro")}</p>
                </div>
              </div>
              <div className="text-right mr-3 mb-1">
                <p>{t("calculator_result_customers")} {((price + Settings.BaseSonarFee) * tickets).toFixed(2).toString().replace(Regex.SpacedNumber, Defaults.Space)} &euro;</p>
                <p>{t("calculator_result_fee")} {(tickets * Settings.BaseSonarFee).toFixed(2).toString().replace(Regex.SpacedNumber, Defaults.Space)} &euro;</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-8 p-8 bg-gray-200">
              <div>
                <div className="flex">
                  <PieChartOutlined className="text-5xl" />
                  <p className="font-semibold my-auto ml-4">{t("title_promotion_costless")}</p>
                </div>
                <div className="mt-3">{t("description_promotion_costless")}</div>
              </div>
              <div>
                <div className="flex">
                  <CreditCardOutlined className="text-5xl" />
                  <p className="font-semibold my-auto ml-4">{t("title_promotion_freetool")}</p>
                </div>
                <div className="mt-3">{t("description_promotion_freetool")}</div>
              </div>
              <div>
                <div className="flex">
                  <EyeInvisibleOutlined className="text-5xl" />
                  <p className="font-semibold my-auto ml-4">{t("title_promotion_transparant")}</p>
                </div>
                <div className="mt-3">{t("description_promotion_transparant")}</div>
              </div>
            </div>
          </Content>
        </Layout>
        <Layout className="md:hidden !bg-transparent px-8">
          <Content className="min-h-[80vh] pt-8">
            <p>{t("required_desktop")}</p>
          </Content>
        </Layout>
        <ComponentFooter />
      </Layout>
    </>
  );
}