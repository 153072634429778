import { Form, Layout, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import ComponentHeader from "./components/header";
import React, { useEffect, useState } from "react";
import { ToolOutlined, FormOutlined, AuditOutlined, CoffeeOutlined, NotificationOutlined, SolutionOutlined, ShoppingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Defaults, Sections } from "../handlers/enums";
import { handleClickScroll } from "../handlers/extensions";
import { ComponentFormPassword } from "./components/forms/input";
import SideMenu from "./components/sideMenu";
import { SectionSupportBusinesses } from "./sections/SectionSupportBusinesses";
import { SectionSupportUsers } from "./sections/SectionSupportUsers";
import { SectionSupportRegisters } from "./sections/SectionSupportRegisters";
import { SectionBlogs } from "./sections/SectionBlogs";
import { SectionAlerts } from "./sections/SectionAlerts";
import { SectionEventSales } from "./sections/SectionEventSales";
import { SectionEventConfig } from "./sections/SectionEventConfig";

export default function Sonar() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  const { t } = useTranslation();

  const [menuItem, setMenuItem] = useState(Sections.Top);
  const [password, setPassword] = useState(Defaults.StringEmpty);
  const [freezeActions, setFreezeActions] = useState(false);

  useEffect(() => {
    if (password === process.env.REACT_APP_SONAR_PASSWORD) { setMenuItem(Sections.SonarCheckEventSales); }
  }, [password]);

  useEffect(() => {
    if (freezeActions) { handleClickScroll(Sections.Top); }
  }, [freezeActions]);

  return (
    <>
      <Layout className="!bg-transparent">
        <div id={Sections.Top}></div>
        <ComponentHeader />
        <Layout hasSider={password === process.env.REACT_APP_SONAR_PASSWORD} className="hidden md:flex !bg-transparent pr-8 justify-between lg:justify-center">
          {
            password !== process.env.REACT_APP_SONAR_PASSWORD ? null :
              <Sider className="!bg-transparent !min-w-60 border-r pt-2">
                <SideMenu initialValue={menuItem} setMenu={setMenuItem} items={[
                  { key: Sections.SonarBusinessConfirm, icon: <FormOutlined /> },
                  { key: Sections.SonarSupportUserAnswer, icon: <AuditOutlined /> },
                  { key: Sections.SonarSupportBusinessAnswer, icon: <SolutionOutlined /> },
                  { key: Sections.SonarAlert, icon: <NotificationOutlined /> },
                  { key: Sections.SonarBlog, icon: <CoffeeOutlined /> },
                  { key: Sections.SonarCheckEventSales, icon: <ShoppingOutlined /> },
                  { key: Sections.SonarEventConfig, icon: <ToolOutlined /> }
                ]} />
              </Sider>
          }
          <Content className={`min-h-[100vh] pl-8 py-8 ${menuItem === Sections.SonarCheckEventSales ? Defaults.StringEmpty : "max-w-3xl"}`}>
            {freezeActions ? <Spin /> :
              <SectionContent section={menuItem} setPassword={setPassword} setFreezeActions={setFreezeActions} />}
          </Content>
        </Layout>
        <Layout className="md:hidden !bg-transparent px-8">
          <Content className="min-h-[100vh] pt-8">
            <p>{t("required_desktop")}</p>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

function SectionContent(props) {
  const { t } = useTranslation();

  switch (props.section) {
    case Sections.SonarBusinessConfirm:
      return <SectionSupportRegisters section={props.section} setFreezeActions={props.setFreezeActions} />;
    case Sections.SonarBlog:
      return <SectionBlogs section={props.section} setFreezeActions={props.setFreezeActions} />;
    case Sections.SonarAlert:
      return <SectionAlerts section={props.section} setFreezeActions={props.setFreezeActions} />;
    case Sections.SonarSupportUserAnswer:
      return <SectionSupportUsers section={props.section} setFreezeActions={props.setFreezeActions} />;
    case Sections.SonarSupportBusinessAnswer:
      return <SectionSupportBusinesses section={props.section} setFreezeActions={props.setFreezeActions} />;
    case Sections.SonarCheckEventSales:
      return <SectionEventSales section={props.section} />;
    case Sections.SonarEventConfig:
      return <SectionEventConfig section={props.section} />;
    default:
      return <>
        <p className="font-semibold text-lg mb-2">{t("title_staff_only")}</p>
        <Form>
          <ComponentFormPassword field={"form_field_password"} placeholder={"placeholder_sonar_password"} required onChange={(event) => props.setPassword(event.target.value)} />
        </Form>
      </>
  }
}