import { JsonToTableBody, formTagsOptionals, notifyError, notifySuccess, notifyWarning } from "../../handlers/extensions";
import { Defaults, QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { Button, Form, Popover, Spin } from "antd";
import ComponentState from "../components/states";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetData, MutateData } from "../../handlers/axios";
import TextArea from "antd/es/input/TextArea";

export function SectionSupportRegisters(props) {
    const response = GetData(QueryId.SelectSupportsRegisters);
    const { t } = useTranslation();

    return (<>
        <p className="font-semibold text-lg mb-5">{t(props.section)}</p>
        <div className="mb-8 flex flex-col gap-5">
            {response.isLoading ? <Spin /> : response.isFalse ? <ComponentState error /> : response.isEmpty ? <ComponentState empty /> : response.data.map((item) => {
                return <SupportRegisterItem key={item.id} data={item} refetch={response.refetch} setFreezeActions={props.setFreezeActions} />
            })}
        </div>
    </>)
}
export function SupportRegisterItem(props) {
    const formActionConfirm = MutateData(QueryId.ValidateSupportRegister,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); notifySuccess(ToastId.ActionSuccess); } },
        { case: QueryCase.Invalid, onCase: () => { notifyWarning("toast_mutate_warning_action_duplicate"); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); } }
    ]);

    const formActionDecline = MutateData(QueryId.DeleteSupportRegister,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); notifySuccess(ToastId.ActionSuccess); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); } }
    ]);

    const { i18n, t } = useTranslation();

    const [openConfirm, setOpenConfirm] = useState(false);

    const handleOpenChangeConfirm = (state) => {
        setOpenConfirm(state);
    };

    const [openDecline, setOpenDecline] = useState(false);

    const handleOpenChangeDecline = (state) => {
        setOpenDecline(state);
    };

    const [reason, setReason] = useState(Defaults.StringEmpty);

    return (<>
        <table>
            {props.data.body ? JsonToTableBody(JSON.parse(props.data.body)) : null}
        </table>
        <div className="flex gap-5">
            <Popover
                content={<>
                    <p className="mb-4">{t("popover_description_confirm")}</p>
                    <div>
                        <Button className="bg-sonar-green mr-2" onClick={(() => { let data = JSON.parse(props.data.body); data.form_field_language = i18n.language; data.form_field_supportid = props.data.id; props.setFreezeActions(true); formActionConfirm.mutate(data); handleOpenChangeConfirm(false); })}>{t("popover_confirm")}</Button>
                        <Button className="bg-red-400" onClick={(() => { handleOpenChangeConfirm(false); })}>{t("popover_cancel")}</Button>
                    </div>
                </>}
                title={t("popover_title_confirm")}
                trigger="click"
                open={openConfirm}
                onOpenChange={handleOpenChangeConfirm}
            >
                <Button className="bg-sonar-green w-full" onClick={() => { handleOpenChangeConfirm(true); }}>{t("confirm")}</Button>
            </Popover>
            <Popover
                content={<>
                    <p className="mb-4">{t("popover_description_decline")}</p>
                    <div>
                        <Form
                            requiredMark={formTagsOptionals}
                            colon={false}
                            layout="vertical"
                        >
                            <Form.Item name={"form_field_reason"} label={t("form_field_reason")}>
                                <TextArea onChange={(event) => { setReason(event.target.value); }} className="!resize-none mb-5" rows={1} autoSize allowClear={true} showCount={true} placeholder={t("placeholder_reason")} maxLength={999} />
                            </Form.Item>
                        </Form>
                        <Button className="bg-sonar-green mr-2" onClick={(() => { let data = JSON.parse(props.data.body); data.form_field_message = reason; data.form_field_language = i18n.language; data.form_field_supportid = props.data.id; props.setFreezeActions(true); formActionDecline.mutate(data); handleOpenChangeDecline(false); })}>{t("popover_confirm")}</Button>
                        <Button className="bg-red-400" onClick={(() => { handleOpenChangeDecline(false); })}>{t("popover_cancel")}</Button>
                    </div>
                </>}
                title={t("popover_title_decline")}
                trigger="click"
                open={openDecline}
                onOpenChange={handleOpenChangeDecline}
            >
                <Button className="bg-red-400 w-full" onClick={() => { handleOpenChangeDecline(true); }}>{t("decline")}</Button>
            </Popover>
        </div>
        <hr />
    </>)
}