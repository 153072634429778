import { Layout, Spin } from "antd";
import ComponentState from "./components/states";
import { Content } from "antd/es/layout/layout";
import ComponentHeader from "./components/header";
import ComponentFooter from "./components/footer";
import React, { useEffect } from "react";
import parse from "html-react-parser";
import { QueryId } from "../handlers/enums";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ExportOutlined } from "@ant-design/icons";
import { GetData } from "../handlers/axios";

export default function Blog() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  const { i18n } = useTranslation();

  const response = GetData(QueryId.SelectBlogs);

  return (
    <>
      <Layout className="!bg-transparent">
        <ComponentHeader />
        <Layout className="flex !bg-transparent pr-8 justify-between lg:justify-center">
          <Content className="min-h-[80vh] pl-8 py-8">
            <div className="mb-8 flex flex-col gap-5">
              {response.isLoading ? <Spin /> : response.isFalse ? <ComponentState error /> : response.isEmpty ? <ComponentState empty /> : response.data.map((blog) => {
                return <div key={blog.id}>
                  <div className="sm:flex">
                    <div className="text-sonar-green font-semibold text-xl mr-4 mb-1">{moment(blog.createdOn).locale(i18n.language).format("DD MMMM")}</div>
                    <div className="mb-3">
                      {blog.url
                        ? <div className="flex">
                          <p className="font-semibold text-lg mr-2">{blog.title}</p>
                          <a href={blog.url} rel="noreferrer noopener" target="_blank">
                            <ExportOutlined className="text-lg text-sonar-blue my-auto h-full hover:text-sonar-green" />
                          </a>
                        </div>
                        : <p className="font-semibold text-lg mr-2">{blog.title}</p>
                      }
                      <div className="mt-2 max-w-xl">{parse(blog.body)}</div>
                    </div>
                  </div>
                  <hr />
                </div>
              })}
            </div>
          </Content>
        </Layout>
        <ComponentFooter />
      </Layout>
    </>
  );
}