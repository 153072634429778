import { atom } from "jotai";

export const UserId = atom(null);
export const EventId = atom(null);
export const BusinessId = atom(null);
export const IsBusinessCompleted = atom(false);
export const IsBusinessOwner = atom(false);
export const TicketSelection = atom([]);
export const BusinessEventProgress = atom({
    isValidGeneral: false,
    isValidPictures: false,
    isValidScanners: false,
    isValidStages: false,
    isPublished: false,
    isEventEnded: false,
    hasTickets: false
});