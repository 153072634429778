import { useTranslation } from "react-i18next";
import { Button, Dropdown, Image } from "antd";
import Flag from "react-world-flags";
import { Link, useNavigate } from "react-router-dom";
import SonarLogoIcon from "../../assets/sprites/SonarLogoIcon.png";
import SonarLogoName from "../../assets/sprites/SonarLogoName.png";
import { Header } from "antd/es/layout/layout";
import { useState } from "react";
import parse from "html-react-parser";
import { CloseOutlined, InfoCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { Color, Defaults, Language, QueryId, StorageId, WebRoute } from "../../handlers/enums";
import { GetData } from "../../handlers/axios";
import { UserId } from "../../handlers/globals";
import { useAtom } from "jotai";

export default function ComponentHeader(props) {
  const { i18n, t } = useTranslation();
  const languageOptions = [
    {
      label: <Flag className={`cursor-pointer${i18n.language === Language.English ? " hidden" : Defaults.StringEmpty}`} code={Language.EnglishAlt} width={40} onClick={() => { i18n.changeLanguage(Language.English); }} />
    },
    {
      label: <Flag className={`cursor-pointer${i18n.language === Language.Dutch ? " hidden" : Defaults.StringEmpty}`} code={Language.Dutch} width={40} onClick={() => { i18n.changeLanguage(Language.Dutch); }} />
    }
  ];
  const navigate = useNavigate();

  const [clearAlert, setClearAlert] = useState(sessionStorage.getItem(StorageId.SonarAlert));

  const response = GetData(QueryId.SelectAlertLatest, null, !clearAlert);

  const [userId,] = useAtom(UserId);

  return (
    <>
      {clearAlert || response.isFalse || response.isEmpty ? <></> :
        <div className="flex justify-center relative border-b p-3">
          {response.data.isInfo ? <InfoCircleTwoTone className="text-2xl mb-auto" /> : <WarningTwoTone className="text-xl mb-auto" twoToneColor={Color.Yellow} />}
          <span className="ml-1">{parse(response.data.body)}</span>
          <CloseOutlined className="absolute right-0 top-0 h-full flex justify-center p-4" onClick={() => { sessionStorage.setItem(StorageId.SonarAlert, true); setClearAlert(true); }} />
        </div>
      }
      <Header className="sticky lg:justify-around flex justify-between py-2 px-4 top-0 z-50 border-b backdrop-blur-sm shadow-sm bg-[#dddddd35] p-0 h-auto leading-normal">
        <Link className="flex focus-visible:outline-none" to={WebRoute.Home}>
          <Image rootClassName="rotate" height={50} width={50} className="min-w-[50px] max-h-[50px]" src={SonarLogoIcon} preview={false} />
          <Image rootClassName="hidden sm:block my-auto mx-3" height={30} width={190} className="min-w-[200px] max-h-[200px]" src={SonarLogoName} preview={false} />
        </Link>
        <div className="flex gap-2 items-center ml-3">
          <Dropdown
            menu={{ items: languageOptions }}
            rootClassName="dropdown_language"
            trigger={["click"]}
            className={"min-w-[30px] " + (userId ? "mr-4" : Defaults.StringEmpty)}
          >
            <div>
              <Flag className="cursor-pointer" width={30} code={
                i18n.language === Language.English
                  ? Language.EnglishAlt
                  : i18n.language === Language.Dutch
                    ? Language.Dutch
                    : Language.Dutch
              } />
            </div>
          </Dropdown>
          {userId || props.lite ? <></> : <>
            <Button className="text-xs px-2 py-1" type="text" onClick={() => { navigate(WebRoute.BusinessSignin); }}>{t("button_signin")}</Button>
            <div>|</div>
            <Button className="text-xs px-2 py-1" type="text" onClick={() => { navigate(WebRoute.BusinessRegister); }}>{t("button_register_event")}</Button>
          </>
          }
        </div>
      </Header>
    </>
  );
}
