import { Button, Form, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import ComponentHeader from "./components/header";
import ComponentFooter from "./components/footer";
import React, { useEffect, useState } from "react";
import { QueryCase, QueryId, ToastId } from "../handlers/enums";
import { useTranslation } from "react-i18next";
import { formTagsNone, notifyError, notifySuccess } from "../handlers/extensions";
import { ComponentFormInput, ComponentFormTextarea } from "./components/forms/input";
import { MutateData } from "../handlers/axios";

export default function Support() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  const { i18n, t } = useTranslation();

  const [form] = Form.useForm();

  const [freezeActions, setFreezeActions] = useState(false);

  const formAction = MutateData(QueryId.InsertSupport,
    () => { setFreezeActions(false); }, [
    { case: QueryCase.Success, onCase: () => { notifySuccess("toast_mutate_success_insert_support"); form.resetFields(); } },
    { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); } }
  ]);

  const onFinish = (properties) => {
    properties.form_field_language = i18n.language;

    setFreezeActions(true);
    formAction.mutate(properties);
  };

  return (
    <>
      <Layout className="!bg-transparent">
        <ComponentHeader />
        <Layout className="hidden md:flex !bg-transparent px-8 justify-between lg:justify-center">
          <Content className="min-h-[80vh] py-8 flex justify-center">
            <div className="w-[700px] border shadow-sm rounded-xl p-3 h-min">
              <p className="text-center mt-1 mb-5 font-semibold">{t("form_title_request_support")}</p>
              <Form
                form={form}
                requiredMark={formTagsNone}
                colon={false}
                onFinish={onFinish}
              >
                <ComponentFormInput field={"form_field_email"} placeholder={"placeholder_email"} feedback clear required email lower />
                <ComponentFormTextarea field={"form_field_support"} placeholder={t("placeholder_support")} counter clear max={999} required />
                <Form.Item className="mb-1 mt-7">
                  <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_confirm_request_support")}</Button>
                </Form.Item>
              </Form>
            </div>
          </Content>
        </Layout>
        <Layout className="md:hidden !bg-transparent px-8">
          <Content className="min-h-[80vh] pt-8">
            <p>{t("required_desktop")}</p>
          </Content>
        </Layout>
        <ComponentFooter />
      </Layout>
    </>
  );
}