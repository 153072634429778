import { useTranslation } from "react-i18next";
import { Sections } from "../../handlers/enums";
import { handleClickScroll } from "../../handlers/extensions";
import { ArrowUpOutlined } from "@ant-design/icons";
import { Menu } from "antd";

export default function SideMenu(props) {
    // initialValue key
    // useTop boolean
    // items [{key,icon,disabled,tag}]
    // setMenu useState

    const { t } = useTranslation();

    let menuItems = props.items.map((x) => {
        return {
            key: x.key, icon: x.icon, disabled: x.disabled, label: <div className="relative">{t(x.key)}{x.tag}</div>, onClick: () => { handleClickScroll(x.key); props.setMenu(x.key); window.scrollBy(0, -75); }
        };
    });

    if (props.useTop) {
        menuItems.push({
            key: Sections.Top,
            icon: <ArrowUpOutlined />,
            label: t(Sections.Top),
            style: { marginTop: "auto", position: "absolute", bottom: "0px" },
            onClick: () => { handleClickScroll(Sections.Top); props.setMenu(menuItems[0].key); window.scrollBy(0, -75); }
        });
    }

    return (
        <Menu
            mode="inline"
            className="text-left !border-none"
            selectedKeys={[props.initialValue]}
            items={menuItems}
        />
    )
}