import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n.jsx";
import "./web.config";
import "./assets/styling/overwrite.css";
import "./assets/styling/tailwind.css";
import "react-toastify/dist/ReactToastify.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-icons/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-richtexteditor/styles/material.css";
import Home from "./pages/home.jsx";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import Config from "./variables.json";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import moment from "moment";
import { JwtKey } from "./handlers/extensions.jsx";
import Support from "./pages/support.jsx";
import Docs from "./pages/docs.jsx";
import Blog from "./pages/blog.jsx";
import Assets from "./pages/assets.jsx";
import Sonar from "./pages/sonar.jsx";
import BusinessRegister from "./pages/register.jsx";
import BusinessSignin from "./pages/signin.jsx";
import BusinessOverview from "./pages/overview.jsx";
import UserReset from "./pages/reset.jsx";
import UserResetCallback from "./pages/resetCallback.jsx";
import BusinessPricing from "./pages/pricing.jsx";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { WebRoute } from "./handlers/enums.jsx";
import BusinessEventOverview from "./pages/overviewEvent.jsx";
import Event from "./pages/event.jsx";
import EventPayment from "./pages/sections/SectionEventPayment.jsx";
import EventPaymentCallback from "./pages/sections/SectionEventPaymentCallback.jsx";
import { registerLicense } from "@syncfusion/ej2-base";
import { Provider as JotaiProvider } from 'jotai';

const queryClient = new QueryClient();

axios.defaults.baseURL = Config.Variables.ApiUrl;
axios.defaults.headers.common["x-functions-key"] = process.env.REACT_APP_API_KEY;
axios.defaults.headers.common["Authorization"] = `Bearer ${await JwtKey()}`;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

moment.updateLocale("en", { months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"] });
moment.updateLocale("nl", { months: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"] });

dayjs.extend(customParseFormat);

registerLicense("ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Wd0NjUX5YcnBTQ2Bb");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContainer autoClose={6000} limit={4} theme="colored" position="top-center" pauseOnHover={true} newestOnTop={true} pauseOnFocusLoss={true} closeOnClick={true} />
    <QueryClientProvider client={queryClient}>
      <JotaiProvider>
        <BrowserRouter>
          <Routes>
            <Route path={WebRoute.Home} element={<Home />} />
            <Route path={WebRoute.Assets} element={<Assets />} />
            <Route path={WebRoute.Docs} element={<Docs />} />
            <Route path={WebRoute.Blog} element={<Blog />} />
            <Route path={WebRoute.Sonar} element={<Sonar />} />
            <Route path={WebRoute.UserSupport} element={<Support />} />
            <Route path={WebRoute.Event} element={<Event />} />
            <Route path={WebRoute.EventPayment} element={<EventPayment />} />
            <Route path={WebRoute.EventPaymentCallback} element={<EventPaymentCallback />} />
            <Route path={WebRoute.RedirectBusiness} element={<Navigate replace to={WebRoute.BusinessSignin} />} />
            <Route path={WebRoute.BusinessOverview} element={<BusinessOverview />} />
            <Route path={WebRoute.BusinessEventOverview} element={<BusinessEventOverview />} />
            <Route path={WebRoute.BusinessPricing} element={<BusinessPricing />} />
            <Route path={WebRoute.BusinessRegister} element={<BusinessRegister />} />
            <Route path={WebRoute.BusinessSignin} element={<BusinessSignin />} />
            <Route path={WebRoute.BusinessSigninReset} element={<UserReset />} />
            <Route path={WebRoute.BusinessSigninResetCallback} element={<UserResetCallback />} />
            <Route path={"*"} element={<Navigate replace to={WebRoute.Home} />} />
          </Routes>
        </BrowserRouter>
      </JotaiProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </React.StrictMode>
);