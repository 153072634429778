import { Button, Form, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import ComponentHeader from "./components/header";
import ComponentFooter from "./components/footer";
import React, { useEffect, useState } from "react";
import { QueryCase, QueryId, StorageId, ToastId, WebRoute } from "../handlers/enums";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ComponentFormInput, ComponentFormPassword } from "./components/forms/input";
import { formTagsNone, notifyError, notifySuccess, notifyWarning } from "../handlers/extensions";
import { MutateData } from "../handlers/axios";
import { BusinessId, IsBusinessCompleted, IsBusinessOwner, UserId } from "../handlers/globals";
import { useAtom } from "jotai";

export default function BusinessSignin() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [, setUserId] = useAtom(UserId);
  const [, setBusinessId] = useAtom(BusinessId);
  const [, setIsBusinessCompleted] = useAtom(IsBusinessCompleted);
  const [, setIsBusinessOwner] = useAtom(IsBusinessOwner);

  const [freezeActions, setFreezeActions] = useState(false);

  const formAction = MutateData(QueryId.ValidateUser,
    () => { setFreezeActions(false) }, [
    { case: QueryCase.Success, onCase: (data) => { if (!data?.data) { notifyError(ToastId.ActionError); return; } console.log(data?.data); setIsBusinessOwner(data.data.isBusinessOwner); setIsBusinessCompleted(data.data.isBusinessComplete); setBusinessId(data.data.businessId); setUserId(data.data.userId); if (!localStorage.getItem(StorageId.SigninAlert)) { notifySuccess("toast_mutate_success_session_login", () => { localStorage.setItem(StorageId.SigninAlert, "disabled"); }); } navigate(WebRoute.BusinessOverview); } },
    { case: QueryCase.Invalid, onCase: () => { notifyWarning("toast_mutate_warning_validate_user"); } },
    { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); } }
  ]);

  const onFinish = (properties) => {
    setFreezeActions(true);
    formAction.mutate(properties);
  };

  return (
    <>
      <Layout className="!bg-transparent">
        <ComponentHeader />
        <Layout className="hidden md:flex !bg-transparent px-8 justify-between lg:justify-center">
          <Content className="min-h-[80vh] py-8 flex justify-center">
            <div className="w-[700px] border shadow-sm rounded-xl p-3 h-min">
              <p className="text-center mt-1 mb-2 font-semibold">{t("form_title_signin_business")}</p>
              <Form
                form={form}
                requiredMark={formTagsNone}
                colon={false}
                onFinish={onFinish}
              >
                <ComponentFormInput field={"form_field_email"} placeholder={"placeholder_email"} feedback clear required email lower />
                <ComponentFormPassword field={"form_field_password"} placeholder={"placeholder_password"} feedback clear required />
                <Form.Item>
                  <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_confirm_request_signin")}</Button>
                </Form.Item>
              </Form>
              <Button className="w-full mb-3 mt-4" onClick={() => { navigate(WebRoute.BusinessSigninReset); }}>{t("button_request_password")}</Button>
              <Button className="w-full mb-3" onClick={() => { navigate(WebRoute.BusinessRegister); }}>{t("button_register_event")}</Button>
              <Button className="w-full bg-sonar-green" onClick={() => { navigate(WebRoute.BusinessPricing); }}>{t("button_pricing")}</Button>
            </div>
          </Content>
        </Layout>
        <Layout className="md:hidden !bg-transparent px-8">
          <Content className="min-h-[80vh] pt-8">
            <p>{t("required_desktop")}</p>
          </Content>
        </Layout>
        <ComponentFooter />
      </Layout>
    </>
  );
}