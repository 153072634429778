import { Image, Layout, ColorPicker } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import ComponentHeader from "./components/header";
import ComponentFooter from "./components/footer";
import React, { useEffect, useState } from "react";
import { ExperimentOutlined, CopyrightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Color, Sections } from "../handlers/enums";
import SonarLogoIcon from "../assets/sprites/SonarLogoIcon.png";
import SonarLogoName from "../assets/sprites/SonarLogoName.png";
import SideMenu from "./components/sideMenu";

export default function Assets() {
  useEffect(() => { window.scrollTo(0, 0); }, []);

  const [menuItem, setMenuItem] = useState(Sections.AssetsImages);

  return (
    <>
      <Layout className="!bg-transparent">
        <div id={Sections.Top}></div>
        <ComponentHeader />
        <Layout hasSider={true} className="hidden md:flex !bg-transparent pr-8 justify-between lg:justify-center">
          <Sider className="!bg-transparent !min-w-60 border-r pt-2">
            <SideMenu initialValue={menuItem} useTop setMenu={setMenuItem} items={[
              { key: Sections.AssetsImages, icon: <CopyrightOutlined /> },
              { key: Sections.AssetsColors, icon: <ExperimentOutlined /> }
            ]} />
          </Sider>
          <Content className="min-h-[80vh] pl-8 py-8">
            <MainContent />
          </Content>
        </Layout>
        <Layout className="md:hidden !bg-transparent px-8">
          <Content className="min-h-[80vh] pt-8">
            <MainContent />
          </Content>
        </Layout>
        <ComponentFooter />
      </Layout>
    </>
  );
}

function MainContent() {
  const { t } = useTranslation();

  return (
    <>
      <p id={Sections.AssetsImages} className="font-semibold text-lg mb-2">{t(Sections.AssetsImages)}</p>
      <div className="mb-6 flex gap-4">
        <Image height={30} className="min-w-8" src={SonarLogoIcon} preview={false} />
        <Image height={30} className="min-w-8" src={SonarLogoName} preview={false} />
      </div>
      <p id={Sections.AssetsColors} className="font-semibold text-lg mb-2">{t(Sections.AssetsColors)}</p>
      <div className="mb-6">
        <div className="flex">
          <ColorPicker defaultValue={Color.White} open={false} />
          <p className="flex flex-col justify-center ml-2">{t("color_white")} {Color.White}</p>
        </div>
        <div className="flex">
          <ColorPicker defaultValue={Color.Grey} open={false} />
          <p className="flex flex-col justify-center ml-2">{t("color_grey")} {Color.Grey}</p>
        </div>
        <div className="flex">
          <ColorPicker defaultValue={Color.Black} open={false} />
          <p className="flex flex-col justify-center ml-2">{t("color_black")} {Color.Black}</p>
        </div>
        <div className="flex">
          <ColorPicker defaultValue={Color.Blue} open={false} />
          <p className="flex flex-col justify-center ml-2">{t("color_blue")} {Color.Blue}</p>
        </div>
        <div className="flex">
          <ColorPicker defaultValue={Color.Green} open={false} />
          <p className="flex flex-col justify-center ml-2">{t("color_green")} {Color.Green}</p>
        </div>
      </div>
    </>
  )
}