import { Button, Input, Popover, Select, Spin, Table } from "antd";
import { Color, Defaults, QueryCase, QueryId, Sections, ToastId } from "../../handlers/enums";
import { WarningTwoTone, SearchOutlined } from "@ant-design/icons";
import { GetData, MutateData } from "../../handlers/axios";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import { SortOnText, notifyError, notifySuccess } from "../../handlers/extensions";
import { BusinessEventProgress } from "../../handlers/globals";
import { useAtom } from "jotai";

export function SectionEventSales() {
    const response = GetData(QueryId.SelectOrders);

    return (response.isLoading
        ? <Spin />
        : response.isFalse
            ? <ComponentState error />
            : response.isEmpty
                ? <ComponentState empty />
                : <TableData refetch={response.refetch} data={response.data} />
    );
}

function TableActionResent(props) {
    const { i18n, t } = useTranslation();
    const [businessEventProgress,] = useAtom(BusinessEventProgress);

    const [open, setOpen] = useState(false);

    const handleOpenChange = (state) => {
        setOpen(state);
    };

    const formAction = MutateData(QueryId.ReattemptOrderMail,
        () => { }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); notifySuccess(ToastId.ActionSuccessSubmit); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinish = (properties) => {
        properties.form_field_language = i18n.language;
        properties.form_field_orderid = props.record?.orderId;

        formAction.mutate(properties);
    };

    const [freezeActions, setFreezeActions] = useState(false);

    return (
        <Popover
            content={<>
                <p className="mb-4">{t("popover_mail_reattempt")}</p>
                <div>
                    <Button className="bg-sonar-green mr-2" onClick={(() => { setFreezeActions(true); onFinish({}); handleOpenChange(false); })}>{t("popover_confirm")}</Button>
                    <Button className="bg-red-400" onClick={(() => { handleOpenChange(false); })}>{t("popover_cancel")}</Button>
                </div>
            </>}
            title={t("popover_title_reattempt")}
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
        >
            <Button disabled={businessEventProgress.isEventEnded || freezeActions || props.freezeActions} loading={freezeActions || props.freezeActions} type="link px-0 py-auto w-full" onClick={() => { handleOpenChange(true); }}>{t("resent_mail")}</Button>
        </Popover>
    );
}

function TableData(props) {
    const { t } = useTranslation();

    const [freezeActions, setFreezeActions] = useState(false);

    const actionColumns = [{
        title: <p className="whitespace-nowrap select-none px-1">{t("table_header_actions")}</p>,
        dataIndex: "table_header_actions",
        key: "table_header_actions",
        fixed: "left",
        render: (_, record) => { return <TableActionResent setFreezeActions={setFreezeActions} freezeActions={freezeActions} refetch={props.refetch} record={record} /> }
    }];

    const columns = Object.keys(props.data[0])?.map(key => ({
        title: <p className="select-none px-1">{key.replace(/([A-Z])/g, " $1").replace(/^./, (item) => item.toUpperCase())}</p>,
        dataIndex: key,
        className: key === "isMailed" ? "table_background_with_ismailed" : Defaults.StringEmpty,
        key: () => { return uuid(); },
        fixed: key === "orderId" ? "left" : key === "customerEmail" ? "right" : undefined,
        showSorterTooltip: false,
        sorter: (a, b) => { return SortOnText(a[key], b[key]); },
        sortDirections: ["descend", "ascend"],
        render: (value) => { return <p className={key === "isMailed" && value ? Defaults.StringEmpty : "ismailed"} key={() => { return uuid(); }}>{key === "isMailed" ? value ? "Yes" : "No" : value}</p>; }
    }));
    const allColumns = [...actionColumns, ...columns];

    const [searchCode, setSearchCode] = useState(Defaults.StringEmpty);

    const [eventName, setEventName] = useState(Defaults.StringEmpty);

    return (<>
        <p className="font-semibold text-lg mb-2">{t(Sections.SonarCheckEventSales)}</p>
        <p className={"text-sonar-yellow text-md mb-3"}><WarningTwoTone twoToneColor={Color.Yellow} className="mr-1" />{t("tooltip_table_width_large")}</p>
        <p className={"text-sonar-red text-md mb-3"}><WarningTwoTone twoToneColor={Color.Red} className="mr-1" />{t("tooltip_table_heavy")}</p>
        <div className="flex mb-3">
            <p className="my-auto font-semibold mr-2 whitespace-nowrap">{t("form_field_eventname")}</p>
            <Select className="w-full" placeholder={t("placeholder_eventname")} onChange={(event) => { setEventName(event); }} options={[...new Set(props.data.map(x => { return x.eventName }))].map(x => { return { value: x, label: x }; })} />
        </div>
        <div className="flex mb-3">
            <p className="my-auto font-semibold mr-2 whitespace-nowrap">{t("search_orders")}</p>
            <Input onInput={(event) => { event.target.value = event.target.value.toLowerCase().replaceAll(Defaults.Space, Defaults.StringEmpty); }} allowClear className="my-auto" placeholder={t("search_order")} addonAfter={<SearchOutlined />} onChange={(event) => { setSearchCode(event.target.value); }} />
        </div>
        <Table key={() => { return uuid(); }} rowKey={() => { return uuid(); }} className="custom_table_sales" scroll={{ x: 1200 }} pagination={false} columns={allColumns} dataSource={!eventName && !searchCode ? [] : props.data.filter((x) => x.eventName.toLowerCase() === eventName.toLowerCase() && (x.orderId.toLowerCase().includes(searchCode) || (x.customerFirstName + Defaults.Space + x.customerLastName).toLowerCase().includes(searchCode)))} size="small" bordered />
    </>
    );
}