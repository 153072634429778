import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from "@syncfusion/ej2-react-richtexteditor";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Defaults } from "../../../handlers/enums";

export default function RichtTextArea(props) {
    const { t } = useTranslation();

    const toolbarSettings = {
        items: ["Bold", "Italic", "Underline", "StrikeThrough", "FontColor", "BackgroundColor", "|", "Undo", "Redo"],
        type: "MultiRow"
    };

    return (<RichTextEditorComponent change={(event) => { return props.setValue ? props.setValue(event.value) : null; }} value={props.initialValue ? props.initialValue : Defaults.StringEmpty} placeholder={t("placeholder_content")} toolbarSettings={toolbarSettings}>
        <></>
        <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
    </RichTextEditorComponent>);
}