import { ToLowerAlphanumeric, TransformRangePickerResult, formTagsNone, formTagsOptionals, notifyError, notifySuccess } from "../../handlers/extensions";
import { Currency, Defaults, QueryCase, QueryId, Settings, ToastId, WebRoute } from "../../handlers/enums";
import { Button, DatePicker, Form, Input, Select, Spin, Tag, Tooltip } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { ComponentFormInput, ComponentFormTextarea } from "../components/forms/input";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { GetData, MutateData } from "../../handlers/axios";
import { useAtom } from "jotai";
import { BusinessEventProgress, BusinessId, EventId, UserId } from "../../handlers/globals";
import dayjs from "dayjs";
import moment from "moment";
import Paragraph from "antd/es/typography/Paragraph";

export function SectionBusinessEventGeneral(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);
    const { i18n, t } = useTranslation();

    const [businessEventProgress, setBusinessEventProgress] = useAtom(BusinessEventProgress);

    const formActionProgress = MutateData(QueryId.SelectBusinessEventProgress, null, [
        { case: QueryCase.Success, onCase: (data) => { setBusinessEventProgress(data.data); } },
    ]);

    const onFinishProgress = (properties) => {
        formActionProgress.mutate(properties);
    };

    const response = GetData(QueryId.SelectBusinessEventGeneral, { BusinessId: businessId, UserId: userId, EventId: eventId });

    const formAction = MutateData(QueryId.UpdateBusinessEventGeneral,
        () => { setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { response.refetch(); onFinishProgress({ BusinessId: businessId, UserId: userId, EventId: eventId }); notifySuccess(ToastId.ActionSuccessUpdate); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const formActionUrl = MutateData(QueryId.UpdateBusinessEventUrl,
        () => { setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { response.refetch(); notifySuccess("toast_mutate_success_request_url"); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const [freezeActions, setFreezeActions] = useState(false);

    const [customUrl, setCustomUrl] = useState(response?.data?.customurl ? response.data.customurl : Defaults.StringEmpty);

    const [form] = Form.useForm();

    const [formUrl] = Form.useForm();

    const onFinish = (properties) => {
        if (!properties.form_field_description || properties.form_field_description === "<p><br></p>" || !properties.form_field_event_date_range) {
            notifyError(ToastId.RequiredFields); return;
        };
        if ((properties.form_field_facebook && !properties.form_field_facebook.startsWith(Defaults.UrlPrefix))
            || (properties.form_field_instagram && !properties.form_field_instagram.startsWith(Defaults.UrlPrefix))
            || (properties.form_field_tiktok && !properties.form_field_tiktok.startsWith(Defaults.UrlPrefix))
            || (properties.form_field_linkedin && !properties.form_field_linkedin.startsWith(Defaults.UrlPrefix))
            || (properties.form_field_snapchat && !properties.form_field_snapchat.startsWith(Defaults.UrlPrefix))) {
            notifyError(ToastId.RequiredHttps); return;
        }

        properties.form_field_event_date_range = TransformRangePickerResult(properties.form_field_event_date_range);

        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;

        setFreezeActions(true);
        formAction.mutate(properties);
    };

    const onFinishUrl = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;
        properties.form_field_language = i18n.language;

        setFreezeActions(true);
        formActionUrl.mutate(properties);
    };

    const { RangePicker } = DatePicker;

    return (response.isLoading ? <Spin /> : response.isFalse || response.isEmpty ? <ComponentState error /> : <>
        <Form
            form={form}
            requiredMark={formTagsOptionals}
            colon={false}
            onFinish={onFinish}
        >
            <p className="font-semibold mb-3 text-base">{t("event_details")}</p>
            <ComponentFormInput disabled={businessEventProgress.isPublished} field={"form_field_eventname"} placeholder={"placeholder_eventname"} value={response.data.name} feedback clear required />
            <ComponentFormInput disabled={businessEventProgress.isPublished} field={"form_field_eventemail"} placeholder={"placeholder_email"} value={response.data.email} feedback clear required email lower />
            <ComponentFormInput disabled={businessEventProgress.isPublished} field={"form_field_eventmobile"} placeholder={"placeholder_mobile"} value={response.data.phone} feedback clear phone />
            <Form.Item name={"form_field_currency"} label={<Tooltip title={t("tooltip_currency")}><Tag color="error">{t("tag_onetimesave")}</Tag><span>{t("form_field_currency")}</span><QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip>} initialValue={response.data.currency ? response.data.currency : Currency.EURO} required>
                <Select
                    disabled={response.data.currency}
                    options={[
                        { value: Currency.EURO, label: t(Currency.EURO) },
                        { value: Currency.POUND, label: t(Currency.POUND), disabled: true },
                    ]}
                />
            </Form.Item>
            <ComponentFormTextarea field={"form_field_description"} placeholder={t("placeholder_description")} value={response.data.description} counter clear max={999} required />
            <p className="font-semibold mb-3 mt-5 text-base">{t("event_location")}</p>
            <ComponentFormInput field={"form_field_country"} placeholder={"placeholder_country"} value={response.data.country} feedback clear required upper />
            <ComponentFormInput field={"form_field_city"} placeholder={"placeholder_city"} value={response.data.city} feedback clear required upper />
            <ComponentFormInput field={"form_field_postalcode"} placeholder={"placeholder_postalcode"} value={response.data.postalCode} feedback clear required upper />
            <ComponentFormInput field={"form_field_address"} placeholder={"placeholder_address"} value={response.data.address} feedback clear required upper />
            <p className="mb-3 mt-5 text-base"><Tooltip title={t("tooltip_event_dates")}><Tag color="error">{t("tag_onetimesave")}</Tag><span className="font-semibold">{t("event_dates")}</span><QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip></p>
            <Form.Item name={"form_field_event_date_range"} label={<Tooltip title={t("form_field_date_range_tooltip")}>{t("form_field_event_date_range")}<QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip>} initialValue={[dayjs(response.data.eventStartDate ? moment(response.data.eventStartDate).format(Defaults.DateFormatDTB) : moment().format(Defaults.DateFormat), Defaults.DateFormat), dayjs(response.data.eventEndDate ? moment(response.data.eventEndDate).format(Defaults.DateFormatDTB) : moment().format(Defaults.DateFormat), Defaults.DateFormat)]} required>
                <RangePicker disabled={response.data.eventStartDate || response.data.eventEndDate} required allowClear needConfirm inputReadOnly={true}
                    minDate={dayjs(moment().subtract(7, "d").format(Defaults.DateFormat), Defaults.DateFormat)}
                    maxDate={dayjs(moment().add(6, "M").format(Defaults.DateFormat), Defaults.DateFormat)}
                />
            </Form.Item>
            <p className="font-semibold mb-3 mt-5 text-base">{t("event_socials")}</p>
            <ComponentFormInput field={"form_field_facebook"} placeholder={"placeholder_facebook"} value={response.data.facebookUrl} feedback clear url />
            <ComponentFormInput field={"form_field_instagram"} placeholder={"placeholder_instagram"} value={response.data.instagramUrl} feedback clear url />
            <ComponentFormInput field={"form_field_tiktok"} placeholder={"placeholder_tiktok"} value={response.data.tiktokUrl} feedback clear url />
            <ComponentFormInput field={"form_field_linkedin"} placeholder={"placeholder_linkedin"} value={response.data.linkedInUrl} feedback clear url />
            <ComponentFormInput field={"form_field_snapchat"} placeholder={"placeholder_snapchat"} value={response.data.snapchatUrl} feedback clear url />
            <Form.Item>
                <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_confirm_update_business")}</Button>
            </Form.Item>
        </Form>
        <hr />
        <Form
            form={formUrl}
            requiredMark={formTagsNone}
            colon={false}
            onFinish={onFinishUrl}
        >
            <p className="mb-3 mt-5 text-base">
                <Tooltip title={t("tooltip_customurl")}>
                    <Tag color="warning">{t("tag_optional")}</Tag>
                    <span className="font-semibold">{t("business-event-url")}</span>
                    <QuestionCircleOutlined className="my-auto h-full ml-1" />
                </Tooltip>
            </p>
            <div className="flex">
                <p className="font-semibold flex flex-nowrap whitespace-nowrap">{t("event_url")} <Tooltip title={t("tooltip_event_url_preview")}><a rel="noreferrer noopener" href={`${Settings.IsDevelopment ? "http://localhost:3000" : "https://tickets.sonarevent.com"}${WebRoute.Event + "?id=" + response.data.id}`} target="_blank"><Tag className="ml-2" color="processing">{t("preview")}<QuestionCircleOutlined className="ml-1" /></Tag></a></Tooltip>&nbsp;</p>
                <Paragraph copyable={{ tooltips: [t("copy"), t("copied")] }}>{`${Settings.IsDevelopment ? "http://localhost:3000" : "https://tickets.sonarevent.com"}${WebRoute.Event + "?id=" + response.data.id}`}</Paragraph>
            </div>
            {!customUrl ? <></> : <div className="flex mb-4">
                <p className="font-semibold flex flex-nowrap whitespace-nowrap">{t("event_url")} <Tooltip title={t("tooltip_event_url_inactive")}><Tag className="ml-2 h-min" color="warning">{t("inactive")}<QuestionCircleOutlined className="ml-1" /></Tag></Tooltip>&nbsp;</p>{`${Settings.IsDevelopment ? "http://localhost:3000" : "https://tickets.sonarevent.com"}${WebRoute.Event + "?id=" + customUrl}`}
            </div>}
            {response.data.customUrl
                ? <div className="flex">
                    <p className="font-semibold">{t("event_url")}<Tooltip title={t("tooltip_event_url_preview")}><a rel="noreferrer noopener" href={`${Settings.IsDevelopment ? "http://localhost:3000" : "https://tickets.sonarevent.com"}${WebRoute.Event + "?id=" + response.data.customUrl}`} target="_blank"><Tag className="ml-2" color="processing">{t("preview")}<QuestionCircleOutlined className="ml-1" /></Tag></a></Tooltip>&nbsp;</p><Paragraph copyable={{ tooltips: [t("copy"), t("copied")] }}>{`${Settings.IsDevelopment ? "http://localhost:3000" : "https://tickets.sonarevent.com"}${WebRoute.Event + "?id=" + response.data.customUrl}`}</Paragraph>
                </div>
                : businessEventProgress.isPublished || response.data.isUrlPending ? <></> : <div className="flex flex-wrap">
                    <Form.Item name={"form_field_customurl"} label={t("form_field_customurl")} hasFeedback className="min-w-max"
                        rules={[{ required: true, whitespace: true, message: <span className="block mt-1 mb-3">{t("form_field_required")}</span> }]}>
                        <Input placeholder={ToLowerAlphanumeric(response.data.name)} onChange={(event) => { setCustomUrl(event.target.value); }} allowClear onInput={(event) => { event.target.value = event.target.value.toLowerCase().replaceAll(Defaults.Space, Defaults.StringEmpty); }} />
                    </Form.Item>
                    <Form.Item>
                        <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { formUrl.submit(); }} className="bg-sonar-blue w-full ml-2">{t("form_confirm_request_url")}</Button>
                    </Form.Item>
                </div>
            }
        </Form>
    </>);
}